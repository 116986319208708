import { RootState } from "../store";
import { IInventorySheetState } from "../types/inventorySheet";
import { Status } from "../types/state";

export const getInventorySheet = (state: RootState): IInventorySheetState => state.inventorySheet;
export const getInventorySheetListStatus = (state: RootState): IInventorySheetState["status"] =>
  state.inventorySheet.status;
export const getIsInventorySheetListStatusLoading = (state: RootState): boolean =>
  state.inventorySheet.status !== Status.success;

export const getInventorySheetListErrorMsg = (state: RootState): IInventorySheetState["errorMsg"] =>
  state.inventorySheet.errorMsg;

export const getInventorySheetContainers = (state: RootState): IInventorySheetState["containers"] =>
  state.inventorySheet.containers;

export const getInventorySheetNextPageState = (state: RootState): IInventorySheetState["isNextPageEnable"] =>
  state.inventorySheet.isNextPageEnable;

export const getInventorySheetPagination = (state: RootState): IInventorySheetState["pagination"] =>
  state.inventorySheet.pagination;
export const getInventorySheetOrdering = (state: RootState): IInventorySheetState["ordering"] =>
  state.inventorySheet.ordering;
export const getInventorySheetFilters = (state: RootState): IInventorySheetState["filters"] =>
  state.inventorySheet.filters;
export const getInventorySheetFiltersItems = (state: RootState): IInventorySheetState["filters"]["items"] =>
  state.inventorySheet.filters.items;
export const getInventorySheetFilterValue = (state: RootState, filterId: string) =>
  state.inventorySheet.filters.items.find((item) => item.id === filterId);

export const getInventorySheetSearch = (state: RootState): IInventorySheetState["search"] =>
  state.inventorySheet.search;

export const getInventorySheetScrollTop = (state: RootState): IInventorySheetState["scrollTop"] =>
  state.inventorySheet.scrollTop;

export const getInventorySheetSummary = (state: RootState) => state.inventorySheet.summary;

export const getInventorySheetOrderingValue = (state: RootState, fieldName: string) =>
  fieldName.toLowerCase() === state.inventorySheet.ordering.orderBy.toLowerCase()
    ? state.inventorySheet.ordering.descending
      ? "desc"
      : "asc"
    : null;

export const getInventorySheetFilterIsActive = (state: RootState, fieldName: string) =>
  !!state.inventorySheet.filters.items.find((item) => String(item.id).startsWith(fieldName));

export const getInventorySheetFilterOptionsForContainerTypes = (state: RootState): number[] => {
  return state.inventorySheet.summaryDefault.containers.map((container) => container.containerType) ?? [];
};
