import "mapbox-gl/dist/mapbox-gl.css";

import { FC, ReactNode } from "react";
import Map, { MapboxEvent, NavigationControl, ViewStateChangeEvent } from "react-map-gl";

import styles from "./Map.module.scss";

interface Props {
  children?: ReactNode;
  initLong?: number;
  initLat?: number;
  initZoom?: number;
  initView?: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  getView?: (e: ViewStateChangeEvent | MapboxEvent) => void;
}

export const CustomMap: FC<Props> = ({ children, getView, initView }) => {
  return (
    <div className={styles.map}>
      <Map
        onLoad={getView}
        onZoomEnd={getView}
        onDragEnd={getView}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX}
        initialViewState={initView}
        mapStyle="mapbox://styles/danjos/clofsp4qg006p01o64t9adwe5"
        doubleClickZoom={false}
        dragRotate={false}
        reuseMaps={true}
      >
        {children}
        <NavigationControl showCompass={false} position="bottom-left" />
      </Map>
    </div>
  );
};
