export enum IconNameEnum {
  loader = "loader",
  helpdeskMessage = "helpdeskMessage",
  chevron = "chevron",
  chevronRight = "chevronRight",
  logo = "logo",
  clock = "clock",
  arrowDown = "arrowDown",
  arrowUp = "arrowUp",
  arrowBack = "arrowBack",
  logoText = "logoText",
  plus = "plus",
  cross = "cross",
  check = "check",
  paperclip = "paperclip",
  password = "password",
  username = "username",
  mail = "mail",
  home = "home",
  location = "location",
  truck = "truck",
  flag = "flag",
  arrows = "arrows",
  globe = "globe",
  logout = "logout",
  search = "search",
  calendar = "calendar",
  camera = "camera",
  note = "note",
  bank = "bank",
  stat = "stat",
  filter = "filter",
  sortAsc = "sortAsc",
  sortDesc = "sortDesc",
  sortNone = "sortNone",
  bin = "bin",
  weight = "weight",
  rfid = "rfid",
  report = "report",
  delete = "delete",
  warning = "warning",
  exclamation = "exclamation",
  preview = "preview",
  paper = "paper",
  pencil = "pencil",
}
