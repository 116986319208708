import { useTranslation } from "react-i18next";

import { SUPPORT_EMAIL } from "../../middleware/routes";
import { CustomButton, IButtonVariant } from "../CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./Footer.module.scss";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <p>
          <span className={styles.bold}>{t("welcome.hello")} </span>
          <span>{t("welcome.login")}</span>
        </p>
      </div>
      <div className={styles.centerSection}>
        <span>Developed by</span>
        <img src="icons/koalaLogo.svg" />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightSectionContent}>
          <p className={styles.transparent}>
            &#169; {t("Ewe")}, {new Date().getFullYear()}
          </p>
          <label>{t("ConditionsOfUse")}</label>
        </div>
        <CustomButton
          text={t("helpdesk")}
          startIcon={<Icon name={IconNameEnum.helpdeskMessage} type={IconTypeEnum.green} />}
          variant={IButtonVariant.info}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default Footer;
