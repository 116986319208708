import { createAsyncThunk } from "@reduxjs/toolkit";

import { PATCH_CONTAINERS_INFO_URL } from "../middleware/routes";
import { RootState } from "../store";
import { axiosRequestApi } from "../utils/axiosRequest";
import { cleanUpCollectionPointDetail, getCollectionPointDetailThunk } from "./collectionPointReducer";
import { getWeightHistoryThunk } from "./weightHistoryReducer";

export const updateContainerDetailThunk = createAsyncThunk<
  null,
  { containerId: number; containerDetail: { noteType: number | null } },
  { state: RootState }
>("containerDetail/updateContainerDetailThunk", async (container, { dispatch }) => {
  const response = await axiosRequestApi.patch(
    `${PATCH_CONTAINERS_INFO_URL}/${container.containerId}`,
    container.containerDetail
  );

  if (response.status === 200) {
    const params = new URLSearchParams(window.location.search);
    dispatch(cleanUpCollectionPointDetail());
    dispatch(getCollectionPointDetailThunk(Number(params.get("id") as string)));
    dispatch(getWeightHistoryThunk(container.containerId));
  }
  return response.data;
});
