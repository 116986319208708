import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Image } from "../../../components/Image";
import { ImageNameEnum } from "../../../components/Image/ImageNameEnum";
import { ImageSuffixEnum } from "../../../components/Image/ImageSuffixEnum";
import { getWagonsReportIsIdle } from "../../../selectors/wagonsReport";

import styles from "./WagonsInitOverlay.module.scss";

export const WagonsInitOverlay: React.FC = () => {
  const { t } = useTranslation();
  const isIdle = useSelector(getWagonsReportIsIdle);

  return (
    <div className={styles.imageContainer}>
      <Image
        name={ImageNameEnum.wagonsInit}
        suffix={ImageSuffixEnum.png}
        alt="žádná data"
        width="279px"
        height="176px"
      />
      {isIdle ? (
        <p className={styles.overlayText}>{t(`wagonsReport.noData`)}</p>
      ) : (
        <div className={styles.progressBar}>
          <div className={styles.progressBarValue}></div>
        </div>
      )}
    </div>
  );
};
