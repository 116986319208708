import { useEffect, useState } from "react";

export const useResize = (breakpoint: number): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(`(min-width: ${breakpoint}px)`).matches);

  useEffect(() => {
    const match = window.matchMedia(`(min-width: ${breakpoint}px)`);
    const callback = (e: MediaQueryListEvent) => setMatches(e.matches);
    match.addEventListener("change", callback);

    return () => match.removeEventListener("change", callback);
  }, [breakpoint]);

  return matches;
};
