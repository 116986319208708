import { DayOfWeek, NotificationsType, WagonEventPeriodicity, WasteType } from "../utils/enums";

export enum WagonsFilter {
  past = "past",
  actual = "actual",
}

export interface IWagonEvent {
  wasteCollectionCalendarId: number;
  date: string;
  dayOfWeek: DayOfWeek;
  wasteType: WasteType;
  periodicity: WagonEventPeriodicity;
  isInHoliday?: boolean;
}

export interface IWagonEventCreate {
  wasteType: WasteType;
  periodicity: WagonEventPeriodicity;
  startDate: string;
  endDate: string | null;
}

export interface IWagonEventDetail {
  wasteCollectionCalendarId: number;
  date: string;
  wasteType: WasteType;
  dayOfWeek: DayOfWeek;
  periodicity: WagonEventPeriodicity;
  notificationsOn: boolean;
  notificationsType: NotificationsType;
  notificationText: string;
}
