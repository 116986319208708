import { useTranslation } from "react-i18next";

import { Image } from "../../../components/Image";
import { ImageNameEnum } from "../../../components/Image/ImageNameEnum";
import { ImageSuffixEnum } from "../../../components/Image/ImageSuffixEnum";

import styles from "./BannersComponent.module.scss";

export const SeparationBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.separationBanner}>
      <div className={styles.textContainer}>
        <h4>{t("doYouWantToKnowDegreeOfSeparation")}</h4>
        <p>{t("beginToMonitorAlsoSeparatedWaste")}</p>
      </div>
      <Image name={ImageNameEnum.bins} suffix={ImageSuffixEnum.svg} width="80px" />
    </div>
  );
};
