import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Supercluster from "supercluster";

import { CustomMap } from "../../../components/Map";
import { resetJumpTo } from "../../../reducers/wagonsReportStateReducer";
import { getJumpTo, getWagonsMap, getWagonsMapFilters } from "../../../selectors/wagonsReport";
import { WagonMarker } from "./WagonMarker";

export const WagonsMap = () => {
  const dispatch = useDispatch();
  const points = useSelector(getWagonsMap);
  const jumpTo = useSelector(getJumpTo);
  const mapFilters = useSelector(getWagonsMapFilters);

  const [zoom, setZoom] = useState<number>(15);
  const [bounds, setBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0]);
  const index = new Supercluster({ radius: 30, maxZoom: 16 });
  index.load(points);
  index.getClusterExpansionZoom;
  const [clusters, setClusters] = useState<any[]>([]);

  useEffect(() => {
    setClusters(index.getClusters(bounds, zoom));
  }, [zoom, bounds, mapFilters]);

  return (
    <CustomMap
      initView={
        jumpTo && {
          latitude: jumpTo.lat,
          longitude: jumpTo.long,
          zoom: jumpTo.zoom,
        }
      }
      getView={(e) => {
        if (jumpTo?.vehicleEventId === 0) dispatch(resetJumpTo);
        setZoom(e.target.getZoom());
        setBounds(e.target.getBounds().toArray().flat() as GeoJSON.BBox);
      }}
    >
      {clusters.map((cluster) => {
        let expansionZoom: number;

        try {
          expansionZoom =
            (cluster.properties.cluster && index.getClusterExpansionZoom(cluster.properties.cluster_id)) ?? 1;
        } catch {
          expansionZoom = 1;
        }

        return (
          <WagonMarker
            point={cluster}
            key={cluster.properties.cluster ? cluster.properties.cluster_id : cluster.properties.vehicleEventId}
            expansionZoom={expansionZoom}
          />
        );
      })}
    </CustomMap>
  );
};
