import { TooltipData } from "./useGetSummaryLineChartProperties";

import styles from "./SummaryLineChart.module.scss";

interface SummaryLineChartTooltipProps {
  tooltipData: TooltipData;
}

export const SummaryLineChartTooltip: React.FC<SummaryLineChartTooltipProps> = ({ tooltipData }) => {
  const { top, left, title, text, opacity } = tooltipData;

  return (
    <div className={styles.tooltip} style={{ top, left, opacity }}>
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  );
};
