import { isAnyOf } from "@reduxjs/toolkit";

import {
  setWagonEventCreateEndDate,
  setWagonEventCreatePeriodicity,
  setWagonEventCreateStartDate,
} from "../../reducers/wagonEventCreateReducer";
import { WagonEventPeriodicity } from "../../utils/enums";
import { startAppListening } from "./listenerMiddleware";

export const wagonEventCreateListener = (): void => {
  startAppListening({
    matcher: isAnyOf(setWagonEventCreatePeriodicity, setWagonEventCreateStartDate),
    effect: async (_, { dispatch, getState }) => {
      const state = getState();
      const periodicity = state.wagonEventCreate.wagonEvent.periodicity;
      const startDate = state.wagonEventCreate.wagonEvent.startDate;
      const startDateDateObject = new Date(startDate);

      if (periodicity === WagonEventPeriodicity.none) {
        const dateWithDayAhead = startDateDateObject;
        dateWithDayAhead.setDate(startDateDateObject.getDate() + 1);
        dispatch(setWagonEventCreateEndDate(dateWithDayAhead.toISOString()));
      }
    },
  });
};
