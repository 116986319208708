export const getContainerTypeCellTitle = (
  containerTypeTitle: string,
  appliedWasteTypeTitle: string,
  wasteType: number
) => {
  let title = "";
  if (containerTypeTitle && wasteType !== 7) {
    title += `${containerTypeTitle} `;
  }
  title += appliedWasteTypeTitle;

  return title;
};
