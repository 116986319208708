import { IWagonsMapFeature, IWagonsMapFilters } from "../types/wagons";

const wagonsMapFilterNormal = (data: IWagonsMapFeature[]): IWagonsMapFeature[] => {
  return data.filter((feature) => feature.properties.hasRfid && !feature.properties.hasWarning);
};

const wagonsMapFilterWarning = (data: IWagonsMapFeature[]): IWagonsMapFeature[] => {
  return data.filter((feature) => feature.properties.hasRfid && feature.properties.hasWarning);
};

const wagonsMapFilterNoRfid = (data: IWagonsMapFeature[]): IWagonsMapFeature[] => {
  return data.filter((feature) => !feature.properties.hasRfid);
};

export const wagonsMapFilter = (data: IWagonsMapFeature[], filter: IWagonsMapFilters): IWagonsMapFeature[] => {
  let filteredData: IWagonsMapFeature[] = [];

  if (filter.normal.value && filter.noRfid.value && filter.warning.value) return data;

  if (filter.normal.value) filteredData = filteredData.concat(wagonsMapFilterNormal(data));

  if (filter.warning.value) filteredData = filteredData.concat(wagonsMapFilterWarning(data));

  if (filter.noRfid.value) filteredData = filteredData.concat(wagonsMapFilterNoRfid(data));

  return filteredData;
};

export const initWagonsMapFilterState = (data: IWagonsMapFeature[]): IWagonsMapFilters => {
  const normal = wagonsMapFilterNormal(data).length > 0;
  const warning = wagonsMapFilterWarning(data).length > 0;
  const noRfid = wagonsMapFilterNoRfid(data).length > 0;
  return {
    normal: {
      value: normal,
      disabled: !normal,
    },
    warning: {
      value: warning,
      disabled: !warning,
    },
    noRfid: {
      value: noRfid,
      disabled: !noRfid,
    },
  };
};
