import { useTranslation } from "react-i18next";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import styles from "./TableAllWastesCell.module.scss";

interface ITableAllWastesCellProps {
  params: GridRenderCellParams<number[]>;
}

export const TableAllWastesCell: React.FC<ITableAllWastesCellProps> = ({ params }) => {
  if (!params?.value) return null;
  const { t } = useTranslation();

  const text = params.value.map((value) => t(`enums.allWasteTypes.${value}.short`)).join(", ");

  return <div className={styles.container}>{text}</div>;
};
