import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISettings } from "../types/settings";
import { ISettingsState, Status } from "../types/state";

const initSettingsState: ISettingsState = {
  status: Status.idle,
  errorMsg: null,
  settings: { darkMode: false },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initSettingsState,
  reducers: {
    setSettings: (state: ISettingsState, action: PayloadAction<ISettings>) => {
      state.settings = action.payload;
    },
    resetSettingsState: () => {
      return initSettingsState;
    },
  },
});

export const { setSettings, resetSettingsState } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
