import { useSelector } from "react-redux";
import { BubbleDataPoint, ChartData, ScatterDataPoint } from "chart.js";

import { getLocationCurrentYearSummary } from "../../../selectors/location";
import { getWasteCollectionSuccessRatesProperties } from "../../../utils/getWasteCollectionSuccessRatesProperties";

interface StatusCirleChartProperties {
  data: ChartData<"doughnut", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  options: any;
}

export const useGetStatusCirleChartProperties = (): StatusCirleChartProperties => {
  const { degreeOfSeparation } = useSelector(getLocationCurrentYearSummary);

  const { appliedChartLineColorString } = getWasteCollectionSuccessRatesProperties(degreeOfSeparation, true);
  const graphData = [
    { value: degreeOfSeparation, bgColor: appliedChartLineColorString },
    { value: 100 - degreeOfSeparation, bgColor: "#00000033" },
  ];
  const graphDataValues = graphData.map(({ value }) => value);
  const graphDataColors = graphData.map(({ bgColor }) => bgColor);

  const data: ChartData<"doughnut", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> = {
    datasets: [
      {
        data: graphDataValues,
        backgroundColor: graphDataColors,
        borderWidth: 0,
      },
    ],
  };

  const options: StatusCirleChartProperties["options"] = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      interaction: {
        mode: "point",
      },
      datalabels: {
        display: false,
      },
    },
    cutout: "80%",
  };

  const statusCirleChartProperties: StatusCirleChartProperties = { data, options };

  return statusCirleChartProperties;
};
