import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";

import { selectYear } from "../../reducers/applicationReducer";
import { getLastYears, getSelectedYear } from "../../selectors/application";
import { capitalizeString } from "../../utils/formatters/capitalizeString";
import { getNextYears } from "../../utils/yearsMocker";
import { SelectComponent } from "./SelectComponent";

import styles from "./SelectComponent.module.scss";

interface YearSelectProps {
  since?: number;
  numberOfYears?: number;
}

export const YearSelect: React.FC<YearSelectProps> = ({ since, numberOfYears }) => {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<number>): void => {
    const newSelectedYear = {
      value: Number(event.target.value),
      label: String(event.target.value),
    };

    dispatch(selectYear(newSelectedYear));
  };

  const selectedYear = useSelector(getSelectedYear);
  let lastYears = useSelector(getLastYears);
  if (since && !numberOfYears) {
    lastYears = lastYears.filter((yearOption) => Number(yearOption.value) >= since);
  } else if (since && numberOfYears) {
    lastYears = getNextYears(since, numberOfYears);
  }

  const dispatch = useDispatch();

  return (
    <div className={styles.yearSelect}>
      <p>{capitalizeString(t("year"))}:</p>
      <SelectComponent
        customCssClass="fixedWidth"
        value={selectedYear.value}
        handleChange={handleChange}
        optionsArray={lastYears}
      />
    </div>
  );
};
