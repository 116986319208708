import { useTranslation } from "react-i18next";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { PaymentStatus } from "../../types/collectionPoint";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./TablePaymentStatusIndicatorCell.module.scss";

interface IIsPaidButtonProps {
  params: GridRenderCellParams<PaymentStatus | boolean>;
  withPaidText?: boolean;
}

export const TablePaymentStatusIndicatorCell: React.FC<IIsPaidButtonProps> = ({ params, withPaidText = false }) => {
  const isPaid = params.value === PaymentStatus.Paid || params.value === true;
  const { t } = useTranslation();

  const text = t(`enums.answers.${isPaid ? "yes" : "no"}`);

  return (
    <div className={styles.container}>
      {isPaid ? (
        <Icon type={IconTypeEnum.green} name={IconNameEnum.check} size="14px" />
      ) : (
        <Icon type={IconTypeEnum.red} name={IconNameEnum.cross} size="14px" />
      )}
      {withPaidText && <span>{text}</span>}
    </div>
  );
};
