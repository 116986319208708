import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WAGON_CALENDAR_URL, WAGONS_CALENDAR_PAGE_PATH } from "../middleware/routes";
import { RootState } from "../store";
import { IWagonEventDetailState, Status } from "../types/state";
import { IWagonEvent, IWagonEventDetail } from "../types/wagonEvent";
import { axiosRequestApi } from "../utils/axiosRequest";
import { DayOfWeek, NotificationsType, WagonEventPeriodicity, WasteType } from "../utils/enums";
import { adaptPatchRequestForBackend, adaptResponseForFronted } from "./utils/wagonEventAdapter";
import { IWagonsEventResponse } from "./wagonsCalendarReducer";

const defaultWagonEvent: IWagonEventDetail = {
  wasteCollectionCalendarId: 0,
  wasteType: WasteType.mixed,
  date: "",
  periodicity: WagonEventPeriodicity.none,
  dayOfWeek: DayOfWeek.monday,
  notificationsOn: false,
  notificationsType: NotificationsType.holidays,
  notificationText: "",
};

const initWagonEventState: IWagonEventDetailState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  wagonEvent: defaultWagonEvent,
};

export const wagonEventDetailSlice = createSlice({
  name: "wagonEventDetailSlice",
  initialState: initWagonEventState,
  reducers: {
    cleanUpWagonEventDetail: (state) => {
      state.status = Status.idle;
      state.errorMsg = null;
      state.wagonEvent = defaultWagonEvent;
    },
    setWagonEventDetail: (state, action: PayloadAction<Partial<IWagonEventDetail>>) => {
      const newWagonEvent = { ...state.wagonEvent, ...action.payload };

      state.wagonEvent = newWagonEvent;
    },
    setWagonEventDetailWasteType: (state: IWagonEventDetailState, action: PayloadAction<WasteType>) => {
      state.wagonEvent.wasteType = action.payload;
    },
    setWagonEventDetailBeginningDate: (state: IWagonEventDetailState, action: PayloadAction<string>) => {
      state.wagonEvent.date = action.payload;
    },
    setWagonEventDetailNotificationsOn: (state: IWagonEventDetailState, action: PayloadAction<boolean>) => {
      state.wagonEvent.notificationsOn = action.payload;
    },
    setWagonEventDetailNotificationsType: (state: IWagonEventDetailState, action: PayloadAction<NotificationsType>) => {
      state.wagonEvent.notificationsType = action.payload;
    },
    setWagonEventDetailNotificationsText: (state: IWagonEventDetailState, action: PayloadAction<string>) => {
      state.wagonEvent.notificationText = action.payload;
    },
    resetEventDetailNotifications: (state: IWagonEventDetailState) => {
      state.wagonEvent.notificationsOn = false;
      state.wagonEvent.notificationsType = NotificationsType.holidays;
      state.wagonEvent.notificationText = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWagonEventDetailThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;
      })
      .addCase(getWagonEventDetailThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        if (!action.payload.wasteCollectionCalendarId) {
          window.location.href = WAGONS_CALENDAR_PAGE_PATH;
        }

        state.status = Status.success;
        state.errorMsg = "";
        state.wagonEvent = { ...state.wagonEvent, ...action.payload };
      })
      .addCase(getWagonEventDetailThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })

      .addCase(updateWagonEventDetailThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(updateWagonEventDetailThunk.fulfilled, (state) => {
        state.status = Status.success;
        state.errorMsg = "";
      })
      .addCase(updateWagonEventDetailThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export interface IWagonsEventResponseWithNotification extends IWagonsEventResponse {
  notification?: {
    notificationId: number;
    type: NotificationsType;
    message: string;
  };
}

export interface IWagonEventUpdateRequest {
  date?: string;
  wasteType?: number;
  notification?: {
    Type: number;
    Message: string;
  };
}

export const getWagonEventDetailThunk = createAsyncThunk<IWagonEvent, number, { state: RootState }>(
  "wagonEventDetail/getWagonEventDetailThunk",
  async (wagonEventId) => {
    const response = await axiosRequestApi.get(`${WAGON_CALENDAR_URL}/${wagonEventId}`);

    return adaptResponseForFronted(response.data);
  }
);

export const updateWagonEventDetailThunk = createAsyncThunk<
  IWagonsEventResponseWithNotification,
  number,
  { state: RootState }
>("wagonEventDetail/updateWagonEventDetailThunk", async (wagonEventId, { getState }) => {
  const state: RootState = getState();
  const wagonEventDetail = state.wagonEventDetail.wagonEvent;

  const response = await axiosRequestApi.patch(
    `${WAGON_CALENDAR_URL}/${wagonEventId}`,
    adaptPatchRequestForBackend(wagonEventDetail)
  );

  return response.data;
});

export const deleteWagonEventInstanceThunk = createAsyncThunk<IWagonsEventResponse, number, { state: RootState }>(
  "wagonEventDetail/deleteWagonEventInstanceThunk",
  async (wagonEventId) => {
    const response = await axiosRequestApi.delete(`${WAGON_CALENDAR_URL}/${wagonEventId}`);
    return response.data;
  }
);

export const {
  cleanUpWagonEventDetail,
  setWagonEventDetail,
  setWagonEventDetailWasteType,
  setWagonEventDetailBeginningDate,
  setWagonEventDetailNotificationsType,
  setWagonEventDetailNotificationsOn,
  setWagonEventDetailNotificationsText,
} = wagonEventDetailSlice.actions;

export const wagonEventDetailReducer = wagonEventDetailSlice.reducer;
