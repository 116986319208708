import { RootState } from "../store";
import { ILocationState } from "../types/location";

export const getLocationSummary = (state: RootState): ILocationState["location"]["summary"] =>
  state.location.location.summary;
export const getLocationCurrentYearSummary = (state: RootState): ILocationState["location"]["currentYearSummary"] =>
  state.location.location.currentYearSummary;

export const getWasteTypesDoughnutChart = (state: RootState): ILocationState["location"]["wasteTypesDoughnutChart"] =>
  state.location.location.wasteTypesDoughnutChart;

export const getGraphDataInPercentages = (
  state: RootState
): ILocationState["location"]["wasteTypesDoughnutChart"]["graphDataInPercentages"] =>
  state.location.location.wasteTypesDoughnutChart.graphDataInPercentages;
