import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "../../../../../components/TextInput";
import { setCollectionYardsFilter } from "../../../../../reducers/collectionYardsReducer";
import { getCollectionYardsFilterValueCached } from "../../../../../selectors/collectionYards";
import { RootState } from "../../../../../store";
import { ColumnType } from "../../CollectionYardsTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const NumberFilter: React.FC<Props> = ({ fieldName }) => {
  const minValueId = `${fieldName}Min`;
  const maxValueId = `${fieldName}Max`;
  const minValue = useSelector((state: RootState) => getCollectionYardsFilterValueCached(state, minValueId));
  const maxValue = useSelector((state: RootState) => getCollectionYardsFilterValueCached(state, maxValueId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        placeHolder={t(`collectionYardsVisits.filters.${fieldName}From`)}
        type="number"
        value={String(minValue?.value)}
        onChange={(e) => {
          dispatch(
            setCollectionYardsFilter({
              id: minValueId,
              name: fieldName,
              operator: "isGreaterOrEqualTo",
              value: e.target.value,
            })
          );
        }}
      />
      <TextInput
        placeHolder={t(`collectionYardsVisits.filters.${fieldName}To`)}
        type="number"
        value={String(maxValue?.value)}
        onChange={(e) => {
          dispatch(
            setCollectionYardsFilter({
              id: maxValueId,
              name: fieldName,
              operator: "isLessOrEqualTo",
              value: e.target.value,
            })
          );
        }}
      />
    </>
  );
};
