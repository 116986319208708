import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";

import { HandleAdditionalRowClickHandler } from "../../../components/DataTable/useGetDataTableHandlers";
import { Icon, IconNameEnum } from "../../../components/Icon";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { Tooltip } from "../../../components/Tooltip";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { WEIGHT_HISTORY_PAGE_PATH } from "../../../middleware/routes";
import { setWeightHistoryContainer } from "../../../reducers/weightHistoryReducer";
import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { getDoesWeigh } from "../../../selectors/mayorModules";
import { IContainer } from "../../../types/container";
import { IColumnAlignObject, IColumnValuePair } from "../../../types/table";
import { getWeighValue } from "../../../utils/collectionPoints/getWeighValue";
import { EnumName } from "../../../utils/enums";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { ContainerTypeCell } from "./ContainerTypeCell";
import { IsContainerActiveCell } from "./IsContainerActiveCell";
import { WeightHistoryCell } from "./WeightHistoryCell";

import styles from "./CollectionPointTable.module.scss";

export enum CollectionPointTableColumnType {
  containerType = "containerType",
  active = "active",
  rfid = "rfid",
  totalDumpings = "totalDumpings",
  weightHistory = "weightHistory",
  totalWaste = "totalWaste",
  barCode = "barCode",
  noteType = "noteType",
}
const columnLabels = Object.values(CollectionPointTableColumnType);

interface CollectionPointTableProperties {
  columns: GridColDef[];
  getCellClassName: (params: GridCellParams<string>) => string;
  handleAdditionalRowClick: HandleAdditionalRowClickHandler;
}

const columnAlignObject: IColumnAlignObject<CollectionPointTableColumnType> = {
  center: [
    CollectionPointTableColumnType.active,
    CollectionPointTableColumnType.totalDumpings,
    CollectionPointTableColumnType.totalWaste,
  ],
};

const columnValuePairs: IColumnValuePair<CollectionPointTableColumnType>[] = [
  { name: CollectionPointTableColumnType.weightHistory, value: 0 },
  { name: CollectionPointTableColumnType.rfid, value: 170 },
  { name: CollectionPointTableColumnType.totalWaste, value: 180 },
  { name: CollectionPointTableColumnType.noteType, value: 180 },
];

export const useGetCollectionPointTableProperties = (): CollectionPointTableProperties => {
  const { t } = useTranslation();
  const { getAlign, getMinWidth } = getTableColumnUtils();
  const history = useHistory();
  const { locationId } = useSelector(getCollectionPointDetail);
  const dispatch = useDispatch();
  const doesWeigh = useSelector(getDoesWeigh);
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();

  const renderHeader = (params: GridColumnHeaderParams): React.ReactNode => {
    return <span className={styles.styledHeader}>{t(params.field)}</span>;
  };

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      type: "string",
      flex: 1,
      headerAlign: getAlign(label, columnAlignObject),
      align: getAlign(label, columnAlignObject),
      minWidth: getMinWidth(label, columnValuePairs),
      renderHeader,
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case CollectionPointTableColumnType.containerType: {
          return <ContainerTypeCell params={params} />;
        }
        case CollectionPointTableColumnType.active:
          return <IsContainerActiveCell params={params} />;

        case CollectionPointTableColumnType.weightHistory: {
          return <WeightHistoryCell params={params} />;
        }
        case CollectionPointTableColumnType.totalWaste:
          return <p>{getWeighValue(doesWeigh, params.value, "kg")}</p>;
        case CollectionPointTableColumnType.rfid:
          return <p>{params.value}</p>;

        case CollectionPointTableColumnType.barCode: {
          const value = params.value;
          return <p>{value}</p>;
        }

        case CollectionPointTableColumnType.noteType: {
          const value = params.value;
          return (
            <div className={styles.noteTypeCell}>
              <Tooltip
                bgColor="transparent"
                content={getTranslatedEnumResourceCode(EnumName.ContainerNoteTypes, value)}
              >
                <p>{getTranslatedEnumResourceCode(EnumName.ContainerNoteTypes, value)}</p>
              </Tooltip>
              <Icon name={IconNameEnum.chevron} />
            </div>
          );
        }

        default:
          break;
      }
    };
    return columnObject;
  });

  const getCellClassName = () => {
    const cellClassNamesArray = [styles.rowCell];
    return getModuleClassNames(cellClassNamesArray);
  };

  const handleAdditionalRowClick = (params: GridRowParams<IContainer>): void => {
    const containerId = String(params.row.containerId);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("id", String(locationId));
    urlParams.set("cid", String(containerId));
    const newUrlSearch = `?${urlParams.toString()}`;
    dispatch(setWeightHistoryContainer(params.row));
    history.push(`${WEIGHT_HISTORY_PAGE_PATH}${newUrlSearch}`);
  };

  const weightHistoryTableProperties: CollectionPointTableProperties = {
    columns,
    getCellClassName,
    handleAdditionalRowClick,
  };

  return weightHistoryTableProperties;
};
