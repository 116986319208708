export enum ILanguageValueEnum {
  sk = "sk",
  de = "de",
  cs = "cs",
  hu = "hu",
}

export enum LanguageDateLocaleEnum {
  sk = "sk-SK",
  cs = "cs-CS",
  de = "de-DE",
  hu = "hu-HU",
}
