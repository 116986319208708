import { isAnyOf } from "@reduxjs/toolkit";

import {
  getCollectionYardsVisitsThunk,
  removeCollectionYardsFilter,
  setCollectionYardsFilter,
  setCollectionYardsListOrdering,
  setCollectionYardsListPagination,
  setCollectionYardsListSearch,
} from "../../reducers/collectionYardsReducer";
import { COLLECTION_YARDS_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { resetCollectionYards } from "./utils/resetCollectionYards";

export const collectionYardsVisitsListener = (): void => {
  startAppListening({
    matcher: isAnyOf(
      setCollectionYardsListOrdering,
      setCollectionYardsListSearch,
      setCollectionYardsFilter,
      removeCollectionYardsFilter
    ),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;
      switch (pathname) {
        case COLLECTION_YARDS_PAGE_PATH:
          resetCollectionYards(dispatch);
          break;

        default:
          break;
      }
    },
  });

  startAppListening({
    actionCreator: setCollectionYardsListPagination,
    effect: async (_, { dispatch }) => {
      dispatch(getCollectionYardsVisitsThunk());
    },
  });
};
