import {
  cleanUpCollectionPointDetailContainers,
  getCollectionPointDetailThunk,
} from "../../../reducers/collectionPointReducer";
import { AppDispatch } from "../../../store";
import { getQueryString } from "../../../utils/getQueryString";

export const resetCollectionPointContainers = (dispatch: AppDispatch): void => {
  const collectionPointId = Number(getQueryString("id"));
  dispatch(cleanUpCollectionPointDetailContainers());
  dispatch(getCollectionPointDetailThunk(collectionPointId));
};
