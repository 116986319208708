import { useDispatch, useSelector } from "react-redux";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { useFormatNumberToShousandsString } from "../../hooks/useFormatNumberToThousandsString";
import { updateWasteTypeObject } from "../../reducers/wasteTypesQuantityReducer";
import { getWasteTypesQuantitySummaries } from "../../selectors/wasteTypesQuantity";
import { IWasteTypeSummary } from "../../types/wasteTypeQuantity";
import { AllWasteTypes } from "../../utils/enums";

import styles from "./TableInput.module.scss";

interface ITableInputProps {
  params: GridRenderCellParams;
}

export const TableInput: React.FC<ITableInputProps> = ({ params }) => {
  const wasteTypeSummariesSelect = useSelector(getWasteTypesQuantitySummaries);
  const dispatch = useDispatch();
  const field = params.field as keyof IWasteTypeSummary;
  const { getFormattedThousandsString } = useFormatNumberToShousandsString();

  const wasteObject: IWasteTypeSummary = wasteTypeSummariesSelect.find(
    (wasteTypeSummary) => wasteTypeSummary.wasteType === params.row.wasteType
  ) as IWasteTypeSummary;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e): void => {
    const valueNumericWithoutSpaces = Number(e.target.value.replace(/\s/g, "").replace(/\D/g, ""));
    const updatedWasteObject: IWasteTypeSummary = { ...wasteObject };

    updatedWasteObject[field] = valueNumericWithoutSpaces;

    if (updatedWasteObject !== undefined) {
      dispatch(updateWasteTypeObject(updatedWasteObject));
    }
  };

  if (params.id === String(AllWasteTypes.totalWaste) || params.id === String(AllWasteTypes.recycled)) {
    return <p className={styles.total}>{getFormattedThousandsString(Number(wasteObject[field]))}</p>;
  }
  return (
    <div className={styles.container}>
      <input
        type="text"
        value={wasteObject ? getFormattedThousandsString(Number(wasteObject[field])) : "0"}
        className={styles.input}
        name="cellValue"
        onChange={handleChange}
      />
    </div>
  );
};
