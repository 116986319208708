import "../types/filter";

import { ChartLineColor, FilterType } from "../types/filter";
import {
  degreeOfSeparationNormalMax,
  degreeOfSeparationWorstMax,
  weightSumNormalMax,
  weightSumTotalMax,
  weightSumWorstMax,
} from "./constants";

interface WasteCollectionSuccessRatesProperties {
  appliedChartLineColorString: string;
  appliedTextResourceCode: FilterType;
  percentageValue: number;
  appliedValueString: string;
}

interface Bounds {
  lowerBound: number;
  upperBound: number;
}

export const getWasteCollectionSuccessRatesProperties = (
  value: number,
  isSeparating: boolean
): WasteCollectionSuccessRatesProperties => {
  const getBounds = (): Bounds => {
    let lowerBound: number;
    let upperBound: number;

    switch (isSeparating) {
      case true:
        lowerBound = degreeOfSeparationWorstMax;
        upperBound = degreeOfSeparationNormalMax;
        break;
      case false:
        lowerBound = weightSumWorstMax;
        upperBound = weightSumNormalMax;
        break;
    }

    return { lowerBound, upperBound };
  };

  const { lowerBound, upperBound } = getBounds();

  const MIN_PERCENTAGE_LEN = 5;
  const MIN_WEIGHT_LEN = 8;
  const nbsp = "\xa0";
  let appliedTextResourceCode = FilterType.recyclingModerate;
  let percentageValue = Math.round(value);
  let appliedValueString = `${percentageValue} %`.padStart(MIN_PERCENTAGE_LEN, nbsp);

  if (!isSeparating) {
    const percentageRatio = Math.round((value * 100) / weightSumTotalMax);
    percentageValue = percentageRatio <= 100 ? percentageRatio : 100;
    appliedValueString = `${Math.round(value)} kg`.padStart(MIN_WEIGHT_LEN, nbsp);
  }

  if ((isSeparating && value < lowerBound) || (!isSeparating && value > lowerBound)) {
    appliedTextResourceCode = FilterType.recyclingUnsuccessful;
  }
  if ((isSeparating && value >= upperBound) || (!isSeparating && value <= upperBound)) {
    appliedTextResourceCode = FilterType.recyclingSuccessful;
  }
  const appliedChartLineColorString = ChartLineColor[appliedTextResourceCode];

  const wasteCollectionSuccessRatesProperties: WasteCollectionSuccessRatesProperties = {
    appliedTextResourceCode,
    appliedChartLineColorString,
    percentageValue,
    appliedValueString,
  };

  return wasteCollectionSuccessRatesProperties;
};
