import { Link } from "react-router-dom";

import { Icon, IconNameEnum } from "../Icon";

import styles from "./BackButton.module.scss";

export interface IBackButtonProps {
  label?: string;
  to: string;
  children?: React.ReactNode;
}

export const BackButton: React.FC<IBackButtonProps> = ({ label, to, children }) => {
  return (
    <Link className={styles.container} to={to}>
      <Icon name={IconNameEnum.arrowBack} />
      {label && <span>{label}</span>}
      {children && children}
    </Link>
  );
};
