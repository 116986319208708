import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useGetApplicationBreakpointProperties } from "../../../hooks/useGetApplicationBreakpointProperties";
import { getLocationCurrentYearSummary } from "../../../selectors/location";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { getWasteCollectionSuccessRatesProperties } from "../../../utils/getWasteCollectionSuccessRatesProperties";
import { useGetStatusCirleChartProperties } from "./useGetStatusCirleChartProperties";

import styles from "./StatusCircleComponent.module.scss";

export const StatusCircleComponent: React.FC = () => {
  const { data, options } = useGetStatusCirleChartProperties();
  const { t } = useTranslation();
  const { degreeOfSeparation } = useSelector(getLocationCurrentYearSummary);
  const { appliedTextResourceCode } = getWasteCollectionSuccessRatesProperties(degreeOfSeparation, true);
  const { isLargeDesktop } = useGetApplicationBreakpointProperties();

  return (
    <div className={getModuleClassNames([styles.container, styles[appliedTextResourceCode]])}>
      <div className={styles.chartContainer}>
        <Doughnut className={styles.doughnutChart} data={data} options={options} />
        <div className={styles.inner}>
          <div className={styles.innerText}>
            <h5>{degreeOfSeparation} %</h5>
          </div>
        </div>
      </div>
      <p className={styles.text}>
        <span>{t(appliedTextResourceCode)}</span>
        {isLargeDesktop && (
          <>
            {" "}
            <span>{`${t("recycledWasteContains")}`}</span>{" "}
            <span className={styles.degreeOfSeparation}>{`${degreeOfSeparation} %.`}</span>
          </>
        )}
      </p>
    </div>
  );
};
