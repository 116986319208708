import { IconTypeEnum } from "../components/Icon";
import { ApplicationMode } from "../types/application";

export const iconTypeHandler = (
  applicationMode: ApplicationMode,
  darkType: IconTypeEnum,
  activeType?: IconTypeEnum,
  isActive?: boolean
): IconTypeEnum => {
  let resultType = IconTypeEnum.default;

  if (applicationMode === ApplicationMode.dark) resultType = darkType;
  if (isActive) resultType = activeType ?? IconTypeEnum.default;

  return resultType;
};
