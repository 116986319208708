import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomButton } from "../../../components/CustomButton";
import { YearSelect } from "../../../components/SelectComponent";
import { useHandleLeavePrompt } from "../../../hooks/useHandleLeavePrompt";
import { HOME_PAGE_PATH } from "../../../middleware/routes";
import {
  createWasteTypesQuantityYearThunk,
  restoreWasteTypeSummaries,
  updateWasteTypesQuantityYearThunk,
} from "../../../reducers/wasteTypesQuantityReducer";
import { getWasteTypesQuantity } from "../../../selectors/wasteTypesQuantity";
import { LeaveConfirmationModal } from "../LeaveConfirmationModal/LeaveConfirmationModal";

import styles from "./WasteCollectionEditPageHeaderComponent.module.scss";

export const WasteCollectionEditPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wasteTypesQuantity = useSelector(getWasteTypesQuantity);
  const history = useHistory();

  const { isTouched } = useSelector(getWasteTypesQuantity);
  const saveButtonRef = useRef<React.RefObject<HTMLButtonElement>>();
  const { isModalVisible, toggleModalVisibility, handleContinue } = useHandleLeavePrompt(isTouched, [saveButtonRef]);

  const handleModalContinue = () => {
    dispatch(restoreWasteTypeSummaries());
    handleContinue();
  };

  const handleSave: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (wasteTypesQuantity.wasteSummaryId === null) {
      dispatch(createWasteTypesQuantityYearThunk());
      history.push(HOME_PAGE_PATH);
      return;
    }
    dispatch(updateWasteTypesQuantityYearThunk());

    history.push(HOME_PAGE_PATH);
  };

  return (
    <>
      <div className={styles.controls}>
        <YearSelect />
        <CustomButton className={styles.saveBtn} text={t("saveData")} onClick={handleSave} ref={saveButtonRef} />
      </div>
      <LeaveConfirmationModal
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        handleContinue={handleModalContinue}
      />
    </>
  );
};
