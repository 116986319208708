import { LoadingSkeleton } from "../../LoadingSkeleton";

import styles from "./Loader.module.scss";

interface LoaderProps {
  days: number;
}

const getSkeleton = (days: number) => {
  return Array.from(Array(days).keys()).map((key) => (
    <LoadingSkeleton key={key} customStyles={{ width: "36px", height: "36px" }} />
  ));
};

export const Loader: React.FC<LoaderProps> = ({ days }) => {
  return <div className={styles.loader}>{getSkeleton(days)}</div>;
};
