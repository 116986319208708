import { ReactFragment } from "react";
import { darken, lighten } from "@material-ui/core";

import { Colors } from "../../utils/enums";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";
import { Tooltip } from "../Tooltip";

import styles from "./Tile.module.scss";

interface TileProps {
  color: Colors;
  icon: IconNameEnum;
  value: string;
  caption: string;
  tooltip?: ReactFragment;
}

export const Tile: React.FC<TileProps> = ({ color, icon, value, caption, tooltip }) => {
  const lightColor = lighten(color, 0.85);
  const darkColor = darken(color, 0.4);

  return (
    <div style={{ backgroundColor: lightColor }} className={styles.container}>
      <div style={{ backgroundColor: color }} className={styles.iconContainer}>
        <Icon name={icon} type={IconTypeEnum.white} />
      </div>
      <div className={styles.content}>
        <h1 style={{ color: darkColor }}>{value}</h1>
        <span style={{ color: darkColor }}>{caption}</span>
      </div>
      {tooltip && (
        <div className={styles.tooltip}>
          <Tooltip bgColor={color} content={tooltip} />
        </div>
      )}
    </div>
  );
};
