import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Dayjs } from "dayjs";

import { fetchWagonsDatePickerPins } from "../reducers/wagonsDatePickerReducer";

export const useLoadWagonsDatePickerPins = (date: Dayjs): void => {
  const dispatch = useDispatch();
  const lastRequest = useRef<any>(null);

  useEffect(() => {
    if (lastRequest.current) {
      lastRequest.current.abort();
    }

    lastRequest.current = dispatch(fetchWagonsDatePickerPins({ year: date.year(), month: date.month() + 1 }));
  }, [date]);
};
