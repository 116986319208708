import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";
import { CollectionYardsPageHeaderComponent } from "./CollectionYardsPageHeaderComponent";
import { CollectionYardsTable } from "./CollectionYardsTable";

import styles from "./CollectionYardsPage.module.scss";

export const CollectionYardsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer
      headerText={t("collectionYards")}
      module={MayorModulesType.CollectionYardsModule}
      HeaderComponent={CollectionYardsPageHeaderComponent}
    >
      <div className={styles.content}>
        <CollectionYardsTable />
      </div>
    </PageContainer>
  );
};
