import { useDispatch, useSelector } from "react-redux";
import { GridRowClassNameParams } from "@mui/x-data-grid-pro";

import { DataTableStatic } from "../../../../components/DataTable";
import {
  setFilteredItemsLookup,
  setJumpTo,
  setScrollTop,
  switchView,
} from "../../../../reducers/wagonsReportStateReducer";
import {
  getWagonsReportFilterModel,
  getWagonsReportList,
  getWagonsReportScrollTop,
  getWagonsReportSortModel,
} from "../../../../selectors/wagonsReport";
import { IReportItem } from "../../../../types/wagons";
import { useGetWagonsReportTalbeColumns } from "./useGetWagonsReportTableColumns";

export const WagonsReportTable: React.FC = () => {
  const dispatch = useDispatch();

  const wagonsReportList = useSelector(getWagonsReportList);
  const columns = useGetWagonsReportTalbeColumns();
  const filter = useSelector(getWagonsReportFilterModel);
  const sort = useSelector(getWagonsReportSortModel);
  const scrollTop = useSelector(getWagonsReportScrollTop);

  const getRowId = (row: IReportItem) => {
    return row.vehicleEventId;
  };
  const getRowClassName = (params: GridRowClassNameParams<IReportItem>) => (!params.row.rfid ? "error" : "");

  return (
    <DataTableStatic
      dataRows={wagonsReportList}
      columns={columns}
      setScrollTop={setScrollTop}
      scrollTop={scrollTop}
      getRowId={getRowId}
      getRowClassName={getRowClassName}
      filterModel={filter}
      sortModel={sort}
      handleAdditionalRowClick={(params) => {
        dispatch(
          setJumpTo({
            vehicleEventId: params.row.vehicleEventId,
            lat: Number(params.row.lat),
            long: Number(params.row.long),
            zoom: 18,
          })
        );
        dispatch(switchView());
      }}
      onStateChange={(params) => {
        dispatch(setFilteredItemsLookup(params.filter.filteredRowsLookup));
      }}
    />
  );
};
