import { RootState } from "../store";
import { ICollectionPointState, Status } from "../types/state";

export const getCollectionPoint = (state: RootState): ICollectionPointState => state.collectionPoint;
export const getCollectionPointStatus = (state: RootState): ICollectionPointState["status"] =>
  state.collectionPoint.status;
export const getIsCollectionPointStatusLoading = (state: RootState): boolean =>
  state.collectionPoint.status !== Status.success;

export const getCollectionPointErrorMsg = (state: RootState): ICollectionPointState["errorMsg"] =>
  state.collectionPoint.errorMsg;

export const getCollectionPointDetail = (state: RootState): ICollectionPointState["collectionPoint"] =>
  state.collectionPoint.collectionPoint;

export const getCollectionPointContainers = (
  state: RootState
): ICollectionPointState["collectionPoint"]["containers"] => state.collectionPoint.collectionPoint.containers;

export const getCollectionPointPagination = (state: RootState): ICollectionPointState["pagination"] =>
  state.collectionPoint.pagination;
export const getCollectionPointOrdering = (state: RootState): ICollectionPointState["ordering"] =>
  state.collectionPoint.ordering;

export const getCollectionPointDetailScrollTop = (state: RootState): ICollectionPointState["scrollTop"] =>
  state.collectionPoint.scrollTop;
