import { useSelector } from "react-redux";

import { getApplicationModeState } from "../../selectors/application";
import { getJwtFromStore } from "../../selectors/auth";
import { classHandler } from "../../utils/classHandler";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { SidePanel } from "../SidePanel";

import styles from "./Layout.module.scss";

export const Layout: React.FC = ({ children }) => {
  const applicationMode = useSelector(getApplicationModeState);
  const jwt = useSelector(getJwtFromStore);

  return (
    <div className={getModuleClassNames(classHandler(styles, "layout", applicationMode))}>
      {jwt && <SidePanel />}
      <div className={styles.container}>{children}</div>
    </div>
  );
};
