import type { TypedStartListening } from "@reduxjs/toolkit";
import { createListenerMiddleware } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "../../store";
import { collectionPointDetailListener } from "./collectionPointDetailListener";
import { collectionPointsListListener } from "./collectionPointsListListener";
import { collectionYardsVisitsListener } from "./collectionYardsVisitsListener";
import { initialDataLoadListener } from "./initialDataLoadListener";
import { inventorySheetListListener } from "./inventorySheetListListener";
import { logoutListener } from "./logoutListener";
import { selectYearListener } from "./selectYearListener";
import { updateLanguageListener } from "./updateLanguageListener";
import { wagonEventCreateListener } from "./wagonEventCreateListener";
import { wagonsCalendarListener } from "./wagonsCalendarListener";
import { wasteTypesQuantityYearListener } from "./wasteTypesQuantityYearListener";
import { weightHistoryListener } from "./weightHistoryListener";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const indexListener = (): void => {
  selectYearListener();
  collectionPointsListListener();
  updateLanguageListener();
  wasteTypesQuantityYearListener();
  weightHistoryListener();
  initialDataLoadListener();
  collectionPointDetailListener();
  logoutListener();
  wagonsCalendarListener();
  wagonEventCreateListener();
  inventorySheetListListener();
  collectionYardsVisitsListener();
};
