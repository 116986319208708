import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import {
  setCollectionPointDetailPagination,
  setCollectionPointDetailScrollTop,
} from "../../../reducers/collectionPointReducer";
import {
  getCollectionPoint,
  getCollectionPointContainers,
  getCollectionPointDetailScrollTop,
  getIsCollectionPointStatusLoading,
} from "../../../selectors/collectionPoint";
import { useGetCollectionPointTableProperties } from "./useGetCollectionPointTableProperties";

import styles from "./CollectionPointTable.module.scss";

export const CollectionPointTable: React.FC = () => {
  const { columns, getCellClassName, handleAdditionalRowClick } = useGetCollectionPointTableProperties();
  const data = useSelector(getCollectionPointContainers);
  const isLoading = useSelector(getIsCollectionPointStatusLoading);
  const scrollTop = useSelector(getCollectionPointDetailScrollTop);
  const collectionPointState = useSelector(getCollectionPoint);

  return (
    <DataTable
      className={styles.tableContent}
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setCollectionPointDetailScrollTop}
      setPagination={setCollectionPointDetailPagination}
      isLoading={isLoading}
      sliceState={collectionPointState}
      handleAdditionalRowClick={handleAdditionalRowClick}
      getCellClassName={getCellClassName}
    />
  );
};
