import { useSelector } from "react-redux";

import { getLocationCurrentYearSummary } from "../../selectors/location";
import { MainMenu } from "./MainMenu";
import { StatusCircleComponent } from "./StatusCircleComponent";
import { TopSegment } from "./TopSegment/TopSegment";

import styles from "./SidePanel.module.scss";

export const SidePanel: React.FC = () => {
  const { isDoughnutChartShown } = useSelector(getLocationCurrentYearSummary);

  return (
    <div className={styles.container}>
      <TopSegment />
      <div className={styles.content}>
        <MainMenu />
        {isDoughnutChartShown && <StatusCircleComponent />}
      </div>
    </div>
  );
};
