import {
  cleanUpWagonsCalendar,
  defaultPagination,
  setWagonsCalendarPagination,
} from "../../../reducers/wagonsCalendarReducer";
import { AppDispatch } from "../../../store";

export const resetWagonsCalendar = (dispatch: AppDispatch): void => {
  dispatch(cleanUpWagonsCalendar());
  dispatch(setWagonsCalendarPagination(defaultPagination));
};
