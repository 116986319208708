export interface IWasteSummary {
  wasteSummaryId: number | null;
  year: number;
  wasteTypeSummaries: IWasteTypeSummary[];
  staticWasteTypeSummaries: IWasteTypeSummary[];
  isTouched: boolean;
}

export type IWasteSummaryGetResponse = IWasteSummary;
export interface IWasteSummaryCreateResponse {
  createdWasteSummary: IWasteSummary;
}

export interface IWasteTypeSummary {
  wasteType: number;
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
  wasteTypeSummaryId?: number;
}

export interface IWasteTypeRow extends IWasteTypeSummary {
  header: string;
}

export enum WasteSummaryWasteTypesAggregated {
  Plastics = 1,
  Paper,
  Metal,
  Glass,
  RecycledOther,
  Mixed,
}
