import { FilterType, IFilterObject } from "../../types/filter";
import { DegreeOfSeparationFilterEnum } from "../../types/state";
import { getAppliedFilterArray } from "./getAppliedFilterArray";

export const getAppliedFilterEnum = (newFilter: IFilterObject) => {
  const appliedFilterArray = getAppliedFilterArray(newFilter);

  let appliedFilterEnum = DegreeOfSeparationFilterEnum.All;
  const getItemOfType = (type: FilterType) => appliedFilterArray.find((item) => item === type);
  const recyclingUnsuccessfulItem = getItemOfType(FilterType.recyclingUnsuccessful);
  const recyclingModerate = getItemOfType(FilterType.recyclingModerate);
  const recyclingSuccessfulItem = getItemOfType(FilterType.recyclingSuccessful);

  if (recyclingUnsuccessfulItem && recyclingModerate && recyclingSuccessfulItem) {
    return appliedFilterEnum;
  }

  if (recyclingUnsuccessfulItem && recyclingSuccessfulItem) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.LowHigh;
    return appliedFilterEnum;
  }
  if (recyclingUnsuccessfulItem && recyclingModerate) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.LowMedium;
    return appliedFilterEnum;
  }
  if (recyclingModerate && recyclingSuccessfulItem) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.MediumHigh;
    return appliedFilterEnum;
  }

  if (recyclingUnsuccessfulItem) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.Low;
  }
  if (recyclingModerate) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.Medium;
  }
  if (recyclingSuccessfulItem) {
    appliedFilterEnum = DegreeOfSeparationFilterEnum.High;
  }

  return appliedFilterEnum;
};
