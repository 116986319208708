import { useTranslation } from "react-i18next";

import { MayorModulesType } from "../../types/mayorModules";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Image } from "../Image";
import { ImageNameEnum } from "../Image/ImageNameEnum";
import { ImageSuffixEnum } from "../Image/ImageSuffixEnum";

import styles from "./NotYetImplemented.module.scss";

const selectBackground = (module?: MayorModulesType): ImageNameEnum => {
  switch (module) {
    case MayorModulesType.WagonModule:
      return ImageNameEnum.bgZvozy;
    case MayorModulesType.CommunityStateModule:
      return ImageNameEnum.bgStavOvzdusi;
    case MayorModulesType.CollectionYardsModule:
      return ImageNameEnum.bgZberneDvory;
    default:
      return ImageNameEnum.bgDomov;
  }
};

interface NotYetImplementedProps {
  module?: MayorModulesType;
}

export const NotYetImplemented: React.FC<NotYetImplementedProps> = ({ module }) => {
  const { t } = useTranslation();
  const appliedClassNamesArray = [styles.NYI, styles.centerWrapper];

  return (
    <>
      <div className={getModuleClassNames(appliedClassNamesArray)}>
        <div className={styles.container}>
          <div className={styles.image}>
            <Image name={ImageNameEnum.car} suffix={ImageSuffixEnum.png} />
          </div>
          <div className={styles.title}>{t("workingOnIt")}</div>
          <div className={styles.text}>{t("sectionSoon")}</div>
        </div>
      </div>
      <Image name={selectBackground(module)} suffix={ImageSuffixEnum.jpeg} />
    </>
  );
};
