import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { WEIGHT_HISTORY_PAGE_PATH } from "../../middleware/routes";
import { ContainerDetailForm } from "./ContainerDetailForm";

export const ContainerDetailPage = () => {
  const { t } = useTranslation();

  const goToWeightHistory = () => {
    const queryParams = new URLSearchParams(location.search);
    const cid = queryParams.get("cid");
    const id = queryParams.get("id");
    const prevPage = queryParams.get("prev-page");

    return `${WEIGHT_HISTORY_PAGE_PATH}?cid=${cid}&id=${id}${prevPage ? `&prev-page=${prevPage}` : ""}`;
  };

  return (
    <PageContainer
      backOption={true}
      backPath={goToWeightHistory()}
      fullHeight={false}
      headerText={t("containerDetail")}
    >
      <ContainerDetailForm />
    </PageContainer>
  );
};
