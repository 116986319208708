import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { showNotification } from "../../../reducers/notificationReducer";
import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { InvitiationStatus } from "../../../types/collectionPoint";
import { NotificationSeverity } from "../../../types/notification";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";

import styles from "./InvitationSentComponent.module.scss";

export const InvitationSentComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invitationToken, invitationStatus } = useSelector(getCollectionPointDetail);

  const invitationUrl = `${process.env.REACT_APP_CITIZEN_APP_URL}/auth/register/${invitationToken}`;

  const handleCopyToClipboard = (): void => {
    navigator.clipboard.writeText(invitationUrl);
    dispatch(
      showNotification({
        message: t("invitationUrlCopiedToClipboard"),
        severity: NotificationSeverity.success,
        hideAfter: 3000,
      })
    );
  };

  const appliedContainerClassNameArray = [styles.container];
  let appliedTextResourceCode = "";
  switch (invitationStatus) {
    case InvitiationStatus.Invited:
      appliedContainerClassNameArray.push(styles.orange);
      appliedTextResourceCode = "invitationSent";
      break;
    case InvitiationStatus.Registered:
      appliedContainerClassNameArray.push(styles.green);
      appliedTextResourceCode = "invitationAccepted";
      break;
  }

  return (
    <div className={getModuleClassNames(appliedContainerClassNameArray)}>
      <p className={styles.text}>{t(appliedTextResourceCode)}</p>
      {invitationStatus !== InvitiationStatus.Registered && (
        <div className={styles.clipContainer} onClick={handleCopyToClipboard}>
          <Icon name={IconNameEnum.paperclip} type={IconTypeEnum.white} size="20px" />
        </div>
      )}
    </div>
  );
};
