import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { COLLECTION_POINTS_PAGE_PATH } from "../middleware/routes";
import { GET_WEIGHT_HISTORY_URL } from "../middleware/routes";
import { RootState } from "../store";
import { IContainer } from "../types/container";
import { IPagination } from "../types/pagination";
import { Status } from "../types/state";
import { IDumping, IWeightHistory, IWeightHistoryState } from "../types/weightHistory";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { defaultContainer } from "./collectionPointReducer";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "date",
  descending: false,
};

export const defaultWeightHistoryListItem = {
  date: "",
  wasteType: 0,
  weight: 0,
  isMocked: false,
  id: 0,
};

const defaultWeightHistoryList: IDumping[] = getSkeletonMockedArray(defaultWeightHistoryListItem, 50, 0);

const initialWeightHistory = {
  owner: "",
  container: defaultContainer,
  dumpings: [],
};

const initWeightHistoryState: IWeightHistoryState = {
  weightHistory: initialWeightHistory,
  status: Status.idle,
  errorMsg: null,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  requestId: "",
  isNextPageEnable: false,
  scrollTop: 0,
};

export const weightHistorySlice = createSlice({
  name: "collectionPoint",
  initialState: initWeightHistoryState,
  reducers: {
    cleanUpWeightHistoryList: (state: IWeightHistoryState) => {
      state.status = Status.idle;
      state.errorMsg = null;
      state.weightHistory.dumpings = defaultWeightHistoryList;
      state.pagination = defaultPagination;
      state.ordering = defaultOrdering;
    },
    setWeightHistoryContainer: (state, action: PayloadAction<IContainer>) => {
      state.weightHistory.container = action.payload;
    },
    setWeightHistoryList: (state, action) => {
      state.weightHistory.dumpings = action.payload;
    },
    setWeightHistoryPagination: (state: IWeightHistoryState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setWeightHistoryScrollTop: (state: IWeightHistoryState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetWeightHistoryState: () => {
      return initWeightHistoryState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeightHistoryThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;
      })
      .addCase(getWeightHistoryThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }
        state.status = Status.success;
        state.errorMsg = "";

        const weightHistoryListFromState = state.weightHistory.dumpings;
        const weightHistoryFromResponse = action.payload;

        if (!weightHistoryFromResponse) {
          window.location.href = COLLECTION_POINTS_PAGE_PATH;
        }
        const weightHistoryListFromResponse = weightHistoryFromResponse.dumpings;
        const newWeightHistoryList: IDumping[] = getSkeletonSuccessArray(
          weightHistoryListFromState,
          weightHistoryListFromResponse
        );
        const newWeightHistory = {
          ...weightHistoryFromResponse,
          weightHistoryList: newWeightHistoryList,
        };

        state.weightHistory = newWeightHistory;

        let isNextPageEnable = true;
        if (weightHistoryListFromResponse.length < state.pagination.limit) {
          isNextPageEnable = false;
        }
        state.isNextPageEnable = isNextPageEnable;
      })
      .addCase(getWeightHistoryThunk.rejected, (state, action) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        if (action.payload === 404) {
          window.location.href = COLLECTION_POINTS_PAGE_PATH;
        }
      });
  },
});

export const getWeightHistoryThunk = createAsyncThunk<IWeightHistory, IContainer["containerId"], { state: RootState }>(
  "weightHistory/getWeightHistoryThunk",
  async (containerId, { getState, rejectWithValue }) => {
    const state = getState();

    const requestBody = {
      containerId: Number(containerId),
      year: state.application.years.selectedYear.value,
      pagination: state.weightHistory.pagination,
      ordering: state.weightHistory.ordering,
    };

    try {
      const response = await axiosRequestApi.post(GET_WEIGHT_HISTORY_URL, requestBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.statusCode);
    }
  }
);

export const {
  setWeightHistoryContainer,
  setWeightHistoryList,
  resetWeightHistoryState,
  setWeightHistoryPagination,
  setWeightHistoryScrollTop,
  cleanUpWeightHistoryList,
} = weightHistorySlice.actions;

export const weightHistoryReducer = weightHistorySlice.reducer;
