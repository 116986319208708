import i18next from "i18next";

import { ICollectionYardsEventApi, ICollectionYardsVisit } from "../types/collectionYards";
import { IServerEnumObject } from "../types/enums";
import { IInventorySheetContainer } from "../types/inventorySheet";
import { ICollectionPointLocation } from "../types/location";
import { getDateTimeStrings } from "./dateHandler";
import { EnumName } from "./enums";

export const formatLocationDataForXlsx = (xlsxData: ICollectionPointLocation[]) => {
  return xlsxData.map((data) => {
    return {
      [i18next.t("name")]: data.name,
      [i18next.t("address")]: data.address,
      [i18next.t("containerCount")]: data.containerCount,
      [`${i18next.t("totalTKO")} (${i18next.t("kg")})`]: data.totalTKO,
      [i18next.t("householdMembersCount")]: data.householdMembersCount,
      [`${i18next.t("wastePerHouseholdMember")} (${i18next.t("kg")})`]: data.wastePerHouseholdMember,
      [i18next.t("degreeOfSeparation")]: data.degreeOfSeparation + "%",
      [i18next.t("totalDumpings")]: data.totalDumpings,
      [i18next.t("isPaid")]: Number(data.isPaid) === 1 ? i18next.t("yes") : i18next.t("no"),
      [i18next.t("wcpid")]: data.wcpid,
    };
  });
};

export const formatInventorySheetDataForXlsx = (xlsxData: IInventorySheetContainer[], enums: IServerEnumObject[]) => {
  return xlsxData.map((data) => {
    const wasteTypeResourceCode =
      enums.find((item) => item.name === EnumName.WasteTypes)?.values.find((item) => item.value === data.wasteType)
        ?.resourceCode ?? "";
    const containerTypeResourceCode =
      enums
        .find((item) => item.name === EnumName.ContainerTypes)
        ?.values.find((item) => item.value === data.containerType)?.resourceCode ?? "";

    return {
      [i18next.t("inventoryList.collectionPointName")]: data.collectionPointName,
      [i18next.t("inventoryList.street")]: data.street,
      [i18next.t("inventoryList.streetNumber")]: data.streetNumber,
      [i18next.t("inventoryList.wasteType")]: i18next.t(wasteTypeResourceCode),
      [i18next.t("inventoryList.containerType")]: i18next.t(containerTypeResourceCode),
      [i18next.t("inventoryList.rfid")]: data.rfid,
      [i18next.t("inventoryList.barcode")]: data.barcode,
      [i18next.t("inventoryList.pointId")]: data.pointId,
      [i18next.t("inventoryList.householdMembersCount")]: data.householdMembersCount,
    };
  });
};

export const formatCollectionYardsVisitsForXlsx = (xlsxData: ICollectionYardsVisit[]) => {
  return xlsxData.map((data) => {
    const createdAt = getDateTimeStrings(new Date(data.created));

    return {
      [i18next.t("collectionYardsVisits.created")]: `${createdAt.date} ${createdAt.time}`,
      [i18next.t("collectionYardsVisits.locationName")]: data.locationName,
      [i18next.t("collectionYardsVisits.collectionYardName")]: data.collectionYardName,
      [i18next.t("collectionYardsVisits.wasteTypes")]: data.wasteTypes
        .map((item) => i18next.t(`enums.allWasteTypes.${item}.short`))
        .join(", "),
      [i18next.t("collectionYardsVisits.wasteSum")]: data.wasteSum + " kg",
      [i18next.t("collectionYardsVisits.priceSum")]: data.priceSum + "€",
      [i18next.t("collectionYardsVisits.paid")]: data.paid
        ? i18next.t("enums.answers.yes")
        : i18next.t("enums.answers.no"),
    };
  });
};

export const formatCollectionYardsEventsForXlsx = (xlsxData: ICollectionYardsEventApi[]) => {
  return xlsxData
    .map((data) => {
      const createdAt = getDateTimeStrings(new Date(data.CollectionYardVisits.created));

      return {
        [i18next.t("collectionYardsVisits.created")]: `${createdAt.date} ${createdAt.time}`,
        [i18next.t("collectionYardsVisits.locationName")]: data.CollectionYardVisits.Locations.Name,
        [i18next.t("collectionYardsVisits.collectionYardName")]: data.CollectionYardVisits.CollectionYards.name,
        [i18next.t("collectionYardsVisits.wasteType")]: i18next.t(`enums.allWasteTypes.${data.waste_type_id}.short`),
        [i18next.t("collectionYardsVisits.weight")]: data.brutto - data.tara + " kg",
      };
    })
    .reverse();
};
