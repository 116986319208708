import { useTranslation } from "react-i18next";

import { CustomButton } from "../CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./PremiumBanner.module.scss";

export const PremiumBanner = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          <Icon name={IconNameEnum.preview} type={IconTypeEnum.green} size="24px" />
          {t("premiumModule.heading")}
        </h1>
        <span>{t("premiumModule.description")}</span>
      </div>
      <CustomButton
        text={t("premiumModule.contactUs")}
        onClick={() => {
          window.open("mailto:info@wama.sk", "_blank");
        }}
      />
    </div>
  );
};
