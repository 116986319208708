import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";
import { CollectionPointsPageHeaderComponent } from "./CollectionPointsPageHeaderComponent";
import { CollectionPointsTable } from "./CollectionPointsTable";

import styles from "./CollectionPointsPage.module.scss";

export const CollectionPointsPage: React.FC = () => {
  return (
    <PageContainer
      HeaderComponent={CollectionPointsPageHeaderComponent}
      module={MayorModulesType.CollectionPointModule}
    >
      <div className={styles.content}>
        <CollectionPointsTable />
      </div>
    </PageContainer>
  );
};
