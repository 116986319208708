import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { GET_WAGONS_CALENDAR_PINS } from "../middleware/routes";
import { IWagonsDatePickerState, Status } from "../types/state";
import { IDateRange } from "../types/wagons";
import { axiosRequestApi } from "../utils/axiosRequest";
import { WasteType } from "../utils/enums";
import { adaptResponseForFronted } from "./utils/wagonsAdapter";

export const initWagonsDatePickerState: IWagonsDatePickerState = {
  status: Status.idle,
  errorMsg: null,
  datePickerPins: [],
  selectedDate: { from: null, to: null },
  selectedWasteType: WasteType.mixed,
};

export const wagonsDatePickerSlice = createSlice({
  name: "wagonsDatePicker",
  initialState: initWagonsDatePickerState,
  reducers: {
    resetWagonsDatePickerState: () => {
      return initWagonsDatePickerState;
    },
    setWagonsSelectedDate: (state, action: PayloadAction<IDateRange>) => {
      state.selectedDate = action.payload;
    },
    setWagonsSelectedWasteType: (state, action: PayloadAction<WasteType>) => {
      state.selectedWasteType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWagonsDatePickerPins.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(fetchWagonsDatePickerPins.fulfilled, (state, action: PayloadAction<IWagonsDatePickerPinsResponse[]>) => {
        state.status = Status.success;
        state.datePickerPins = adaptResponseForFronted(action.payload);
        state.errorMsg = null;
      })
      .addCase(fetchWagonsDatePickerPins.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "error loading pins";
      });
  },
});

export interface IWagonsDatePickerPinsRequest {
  month: number;
  year: number;
}

export interface IWagonsDatePickerPinsResponse {
  date: string;
  wasteTypes: number[];
}

export const fetchWagonsDatePickerPins = createAsyncThunk<
  IWagonsDatePickerPinsResponse[],
  IWagonsDatePickerPinsRequest
>("wagons/getWagonsDatePickerPins", async (request, { signal }) => {
  const source = axios.CancelToken.source();

  signal.addEventListener("abort", () => {
    source.cancel();
  });

  const response = await axiosRequestApi.get(GET_WAGONS_CALENDAR_PINS, {
    params: { year: request.year, month: request.month },
    cancelToken: source.token,
  });
  return response.data;
});

export const { setWagonsSelectedDate, setWagonsSelectedWasteType, resetWagonsDatePickerState } =
  wagonsDatePickerSlice.actions;

export const wagonsDatePickerReducer = wagonsDatePickerSlice.reducer;
