import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

import { Container } from "../../../components/Container";
import { ContentWithDividerLines } from "../../../components/ContentWithDividerLines";
import { CustomButton } from "../../../components/CustomButton";
import { ContainerNoteSelect } from "../../../components/SelectComponent";
import { updateContainerDetailThunk } from "../../../reducers/containerDetailReducer";
import { getWeightHistoryContainer } from "../../../selectors/weightHistory";

import styles from "./ContainerDetailForm.module.scss";

export const ContainerDetailForm = () => {
  const dispatch = useDispatch();
  const { noteType, disableTime, containerId } = useSelector(getWeightHistoryContainer);
  const [newNoteType, setNewNoteType] = useState<number | null>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const handleEventUpdate = () => {
    if (newNoteType !== null) {
      const noteType = newNoteType === 0 ? null : newNoteType;

      dispatch(updateContainerDetailThunk({ containerId, containerDetail: { noteType } }));
    }

    history.goBack();
  };

  const generalFormFields = [];

  if (disableTime) {
    generalFormFields.push({
      id: 1,
      element: (
        <div className={styles.top}>
          <p>{t("note")}</p>
          <ContainerNoteSelect
            customCssClass="normalWidth"
            handleChange={(event: SelectChangeEvent<number>) => {
              const value = Number(event.target.value ?? 0);
              setNewNoteType(value);
            }}
            value={newNoteType ?? noteType ?? 0}
          />
        </div>
      ),
    });
  }

  return (
    <div className={styles.form}>
      {generalFormFields.length > 0 && (
        <>
          <Container>
            <ContentWithDividerLines items={generalFormFields} />
          </Container>
          <div className={styles.buttonContainer}>
            <CustomButton className={styles.button} text={t("save")} onClick={handleEventUpdate} />
          </div>
        </>
      )}
    </div>
  );
};
