import { DoughnutChartItem, IWasteTypeSummariesAggregated } from "../../types/location";
import { wasteTypeAggregatedToBgColor } from "../enumMapping";

export const getDoughnutChartData = (wasteSummaryDetaiAggregatedData: IWasteTypeSummariesAggregated[]) => {
  const graphDataInPercentages = wasteSummaryDetaiAggregatedData
    .map((item) => {
      const graphItem = {
        wasteType: item.wasteTypeAggregated,
        percentages: item.share,
      } as DoughnutChartItem;
      graphItem.bgColor = wasteTypeAggregatedToBgColor.get(item.wasteTypeAggregated) ?? "";
      return graphItem;
    })
    .filter((it) => it.percentages !== 0);

  const doughnutChartData = {
    graphDataInPercentages,
  };
  return doughnutChartData;
};
