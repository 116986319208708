import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Card } from "../../../components/Card";
import { getSelectedYear } from "../../../selectors/application";
import { SummaryCards } from "../SummaryCards";
import { SummaryDoughnutChart } from "../SummaryDoughnutChart";
import { SummaryLineChart } from "../SummaryLineChart";

import styles from "./Graphs.module.scss";

export const Graphs: React.FC = () => {
  const { t } = useTranslation();
  const selectedYear = useSelector(getSelectedYear);

  return (
    <div className={styles.graphsContainer}>
      <Card className={styles.leftCard}>
        <div>
          <h5>{t("wasteTransportPerPeriod")}</h5>
          <h6>{t("yearView")}</h6>
        </div>
        <SummaryDoughnutChart />
      </Card>
      <div className={styles.rightSegment}>
        <Card className={styles.rightCard}>
          <div>
            <h5>{t("trendDegreeOfSeparation")}</h5>
            <h6>{`${t("year")} ${selectedYear.value}`}</h6>
          </div>
          <SummaryLineChart />
        </Card>
        <SummaryCards />
      </div>
    </div>
  );
};
