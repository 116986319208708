import { Store } from "@reduxjs/toolkit";
import axios from "axios";

import { API_URL } from "../middleware/routes";
import { getJwtFromLocalStorage } from "./localStorageHandlers";

export const axiosRequestApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status === 400 || status === 401;
  },
});

export const setInterceptors = (_store: Store) => {
  axiosRequestApi.interceptors.request.use(
    (config: any) => {
      config.headers.Authorization = `Bearer ${getJwtFromLocalStorage(true)}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
