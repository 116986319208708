import { DateRangePickerDay, DateRangePickerDayProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";

import { IDatePickerPin } from "../../../types/wagons";
import { Pin } from "../Pin";

import styles from "./CustomDay.module.scss";

interface CustomDayProps extends DateRangePickerDayProps<Dayjs> {
  daysWithPins?: IDatePickerPin[];
  selectDirection?: (day: Dayjs) => void;
}

export const CustomDay: React.FC<CustomDayProps> = ({
  daysWithPins = [],
  selectDirection,
  day,
  outsideCurrentMonth,
  ...other
}) => {
  const highlight = daysWithPins.find((item) => {
    const date = new Date(item.date);
    return date.getDate() === day.date() && date.getMonth() === day.month();
  });

  return (
    <div key={other.key}>
      <DateRangePickerDay
        className={styles.pickerDay}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        onMouseOver={() => {
          if (selectDirection) selectDirection(day);
        }}
      ></DateRangePickerDay>
      <div className={styles.pinContainer}>
        {highlight ? highlight.wasteTypes.map((type) => <Pin type={type} key={type} />) : undefined}
      </div>
    </div>
  );
};
