import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Status } from "../types/state";

export const getCollectionYards = (state: RootState) => state.collectionYards;
export const getCollectionYardsVisitsStatus = (state: RootState) => state.collectionYards.status;
export const getIsCollectionYardsVisitsStatusLoading = (state: RootState): boolean =>
  state.collectionYards.status !== Status.success;

export const getCollectionYardsVisitsErrorMsg = (state: RootState) => state.collectionYards.errorMsg;

export const getCollectionYardsVisits = (state: RootState) => state.collectionYards.visits;

export const getCollectionYardsNextPageState = (state: RootState) => state.collectionYards.isNextPageEnable;

export const getCollectionYardsPagination = (state: RootState) => state.collectionYards.pagination;
export const getCollectionYardsOrdering = (state: RootState) => state.collectionYards.ordering;
export const getCollectionYardsFilters = (state: RootState) => state.collectionYards.filters;
export const getCollectionYardsFiltersItems = (state: RootState) => state.collectionYards.filters.items;
const getCollectionYardsFilterValue = (state: RootState, filterId: string) =>
  state.collectionYards.filters.items.find((item) => item.id === filterId);
export const getCollectionYardsFilterValueCached = createSelector([getCollectionYardsFilterValue], (items) => items);

export const getCollectionYardsSearch = (state: RootState) => state.collectionYards.search;

export const getCollectionYardsScrollTop = (state: RootState) => state.collectionYards.scrollTop;

export const getCollectionYardsOrderingValue = (state: RootState, fieldName: string) =>
  fieldName.toLowerCase() === state.collectionYards.ordering.orderBy.toLowerCase()
    ? state.collectionYards.ordering.descending
      ? "desc"
      : "asc"
    : null;

const getCollectionYardsFilterIsActive = (state: RootState, fieldName: string) =>
  !!state.collectionYards.filters.items.find((item) => String(item.id).startsWith(fieldName));

export const getCollectionYardsFilterIsActiveCached = createSelector(
  [getCollectionYardsFilterIsActive],
  (items) => items
);
