import { useGetTranslatedEnumResourceCodeGetter } from "../../../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { ISummaryContainer } from "../../../../../types/wagons";
import { EnumName } from "../../../../../utils/enums";

import styles from "./WagonsReportSummaryTooltip.module.scss";

interface Props {
  containers: ISummaryContainer[];
}

export const WagonsReportSummaryTooltip: React.FC<Props> = ({ containers }) => {
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();

  return (
    <div className={styles.container}>
      {containers.map(({ count, containerType }) => (
        <div key={containerType}>
          <span>{count}&times;</span>
          <span>{getTranslatedEnumResourceCode(EnumName.ContainerTypes, containerType, "unknown")}</span>
        </div>
      ))}
    </div>
  );
};
