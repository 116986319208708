export const getFormattedWasteTypeCodeString = (wasteTypeCode: string): string => {
  let formattedWasteTypeCode = "";
  for (let i = 0; i < wasteTypeCode.length; i++) {
    if (i !== 0 && i % 2 === 0) {
      formattedWasteTypeCode += "-";
    }
    formattedWasteTypeCode += wasteTypeCode[i];
  }

  return formattedWasteTypeCode;
};
