import { IDatePickerPin } from "../../types/wagons";
import { numberToWasteType } from "../../utils/enumMapping";
import { WasteType } from "../../utils/enums";
import { IWagonsDatePickerPinsResponse } from "../wagonsDatePickerReducer";

export const adaptResponseForFronted = (responseObject: IWagonsDatePickerPinsResponse[]): IDatePickerPin[] => {
  return responseObject.map((item) => {
    return {
      date: item.date,
      wasteTypes: item.wasteTypes.map((type) => numberToWasteType.get(type) ?? WasteType.mixed),
    };
  });
};
