import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

import { allWasteTypesIds } from "../../../../../reducers/collectionYardDetailSettingsReducer";
import { setCollectionYardsFilter } from "../../../../../reducers/collectionYardsReducer";
import { getCollectionYardsFilterValueCached } from "../../../../../selectors/collectionYards";
import { RootState } from "../../../../../store";
import { ColumnType } from "../../CollectionYardsTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const AllWasteTypesFilter: React.FC<Props> = ({ fieldName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const options = allWasteTypesIds;
  const value = [
    ...((useSelector((state: RootState) => getCollectionYardsFilterValueCached(state, fieldName))?.value as number[]) ??
      []),
  ];

  return (
    <>
      {options.map((option) => (
        <div
          onClick={() => {
            if (!value.includes(option)) {
              value.push(option);
            } else {
              value.splice(value.indexOf(option), 1);
            }
            dispatch(
              setCollectionYardsFilter({
                id: fieldName,
                name: fieldName,
                operator: "arrayContains",
                value: [...value],
              })
            );
          }}
          key={option}
        >
          <Checkbox
            name={`allWasteTypes.${String(option)}`}
            style={{ paddingTop: 0, paddingBottom: 0 }}
            checked={value.includes(option)}
          />
          <label htmlFor={`allWasteTypes.${String(option)}`}>{t(`enums.allWasteTypes.${option}.short`)}</label>
        </div>
      ))}
    </>
  );
};
