import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setWagonsCalendarFilter, setWagonsCalendarScrollTop } from "../../../reducers/wagonsCalendarReducer";
import { getWagonsCalendarFilter } from "../../../selectors/wagonsCalendar";
import { WagonsFilter } from "../../../types/wagonEvent";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";

import styles from "./WagonsFilterButton.module.scss";

type WagonsFilterButtonProps = {
  type: WagonsFilter;
};

export const WagonsFilterButton: React.FC<WagonsFilterButtonProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appliedFilter = useSelector(getWagonsCalendarFilter);
  const appliedClassNamesArray = [styles.button];

  const isActive = appliedFilter === type;
  if (isActive) {
    appliedClassNamesArray.push(styles.active);
  }

  const handleClick = () => {
    dispatch(setWagonsCalendarScrollTop(0));
    dispatch(setWagonsCalendarFilter(type));
  };

  return (
    <button className={getModuleClassNames(appliedClassNamesArray)} onClick={handleClick}>
      {t(type)}
    </button>
  );
};
