import { ISelectOption } from "../types/select";

const lastYears: ISelectOption[] = [];

let currentYear = new Date().getFullYear();

while (lastYears.length < 4) {
  lastYears.push({ value: Number(currentYear), label: String(currentYear) });
  currentYear--;
}

export { lastYears };

export const getNextYears = (sinceYear: number, numberOfYears: number): ISelectOption[] => {
  const years: ISelectOption[] = [];

  while (years.length < numberOfYears) {
    years.push({ value: Number(sinceYear), label: String(sinceYear) });
    sinceYear++;
  }

  return years;
};
