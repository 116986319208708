import { IMenuItemProps } from "../components/SidePanel/MenuItem";

export enum SortingStatus {
  sort = "sort",
  notSort = "not sort",
  notWeigh = "not weigh",
}

export enum MayorModuleStatus {
  active = "active",
  inactive = "inactive",
  hidden = "hidden",
}

export enum TopMayorModulesType {
  CollectionPointModule = "CollectionPointModule",
  WagonModule = "WagonModule",
  CommunityStateModule = "CommunityStateModule",
  CollectionYardsModule = "CollectionYardsModule",
  WagonCalendarModule = "WagonCalendarModule",
  InventorySheetModule = "InventorySheetModule",
}

export enum BottomMayorModulesType {
  SupportModule = "SupportModule",
}

export const MayorModulesType = { ...TopMayorModulesType, ...BottomMayorModulesType };
export type MayorModulesType = TopMayorModulesType | BottomMayorModulesType;

export interface IMayorModules {
  WasteSortingStatus: SortingStatus;
  CollectionPointModule: MayorModuleStatus;
  WagonModule: MayorModuleStatus;
  CommunityStateModule: MayorModuleStatus;
  WagonCalendarModule: MayorModuleStatus;
  InventorySheetModule: MayorModuleStatus;
  CollectionYardsModule: MayorModuleStatus;
  MobileAppModule: MayorModuleStatus;
  SupportModule: MayorModuleStatus;
}

type MayorModulesKey = keyof typeof MayorModulesType;
export type IMayorModulesObject = {
  [key in MayorModulesKey]: IMenuItemProps;
};
