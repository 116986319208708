import styles from "./Toggle.module.scss";

export const Toggle: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({ children, ...rest }) => {
  return (
    <label className={styles.toggle}>
      <input className={styles.toggleInput} type="checkbox" {...rest} />
      <span className={styles.slider} />
    </label>
  );
};
