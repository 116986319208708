import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useFormatNumberToShousandsString } from "../../../hooks/useFormatNumberToThousandsString";
import { getLocationSummary } from "../../../selectors/location";
import { SummaryCard } from "./SummaryCard";

import styles from "./SummaryCards.module.scss";

export const SummaryCards: React.FC = () => {
  const { t } = useTranslation();
  const { householdsSum, householdsMembersSum, containersSum, wasteSum, degreeOfSeparation } =
    useSelector(getLocationSummary);
  const { getFormattedThousandsString } = useFormatNumberToShousandsString();

  const summaryCards = [
    {
      title: t("degreeOfSeparation"),
      value: `${degreeOfSeparation} %`,
    },
    {
      title: t("householdsInMunicipality"),
      value: getFormattedThousandsString(householdsSum),
    },
    {
      title: t("peopleInMunicipality"),
      value: getFormattedThousandsString(householdsMembersSum),
    },
    {
      title: t("numberOfContainersInMunicipality"),
      value: getFormattedThousandsString(containersSum),
    },
    {
      title: t("wasteInTotal"),
      value: `${getFormattedThousandsString(wasteSum)} kg`,
    },
  ];

  return (
    <div className={styles.summaryCardsContainer}>
      {summaryCards.map(({ title, value }) => (
        <SummaryCard title={title} value={value} key={title} />
      ))}
    </div>
  );
};
