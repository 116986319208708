import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { CustomButton } from "../../../components/CustomButton";
import { DateRangePicker } from "../../../components/DateRangePicker";
import { WasteTypeSelect } from "../../../components/SelectComponent/WasteTypeSelect";
import { useLoadWagonsDatePickerPins } from "../../../hooks/useLoadWagonsDatePickerPins";
import { setWagonsSelectedDate, setWagonsSelectedWasteType } from "../../../reducers/wagonsDatePickerReducer";
import { fetchWagonsReport, switchView } from "../../../reducers/wagonsReportStateReducer";
import { isWagonsModuleInactive } from "../../../selectors/mayorModules";
import {
  getWagonsCanGenerate,
  getWagonsDatePickerIsLoading,
  getWagonsDatePickerPins,
  getWagonsSelectedDate,
  getWagonsSelectedWasteType,
} from "../../../selectors/wagonsDatePicker";
import { getWagonsReportIsIdle, getWagonsReportIsLoading, isMapViewActive } from "../../../selectors/wagonsReport";
import { getDateStringFromDayjs } from "../../../utils/dateHandler";
import { WasteType } from "../../../utils/enums";
import { WagonsMapFilter } from "./WagonsMapFilter";
import { WagonsViewSwitch } from "./WagonsViewSwitch";

import styles from "./WagonsPageHeaderComponent.module.scss";

dayjs.extend(utc);

export const WagonsPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedWasteType = useSelector(getWagonsSelectedWasteType);
  const selectedRange = useSelector(getWagonsSelectedDate);
  const [date, setDate] = useState(dayjs());
  const isLoading = useSelector(getWagonsDatePickerIsLoading);
  const daysWithPins = useSelector(getWagonsDatePickerPins);
  const canGenerate = useSelector(getWagonsCanGenerate);
  const isReportLoading = useSelector(getWagonsReportIsLoading);
  const isReportIdle = useSelector(getWagonsReportIsIdle);
  const isModuleInactive = useSelector(isWagonsModuleInactive);
  const isMapActive = useSelector(isMapViewActive);

  useLoadWagonsDatePickerPins(date);

  return (
    <div className={styles.controls}>
      <div>
        <WagonsViewSwitch />
      </div>
      {isMapActive && <WagonsMapFilter />}
      <div>
        <DateRangePicker
          onMonthChange={(date) => setDate(date)}
          isLoading={isLoading}
          daysWithPins={daysWithPins}
          defaultValue={[dayjs(selectedRange.from), dayjs(selectedRange.to)]}
          disabled={isModuleInactive}
          onChange={(value) => {
            dispatch(
              setWagonsSelectedDate({
                from: getDateStringFromDayjs(value[0]),
                to: getDateStringFromDayjs(value[1]),
              })
            );
          }}
        />
        <WasteTypeSelect
          customCssClass="minWidth"
          disabled={isModuleInactive}
          handleChange={(event: SelectChangeEvent<WasteType>) =>
            dispatch(setWagonsSelectedWasteType(event.target.value as WasteType))
          }
          value={selectedWasteType}
        />
        <CustomButton
          text={t("wagonsReport.generate")}
          onClick={() => {
            if (isMapActive) dispatch(switchView());
            dispatch(fetchWagonsReport());
          }}
          disabled={!canGenerate || (isReportLoading && !isReportIdle)}
        />
      </div>
    </div>
  );
};
