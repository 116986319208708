import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";
import { WagonsCalendarPageHeaderComponent } from "./WagonsCalendarPageHeaderComponent";
import { WagonsCalendarTable } from "./WagonsCalendarTable";

import styles from "./WagonsCalendarPage.module.scss";

export const WagonsCalendarPage = () => {
  return (
    <PageContainer HeaderComponent={WagonsCalendarPageHeaderComponent} module={MayorModulesType.WagonCalendarModule}>
      <div className={styles.content}>
        <WagonsCalendarTable />
      </div>
    </PageContainer>
  );
};
