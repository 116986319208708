export enum IconTypeEnum {
  default = "",
  grey = "Grey",
  white = "White",
  light = "Light",
  red = "Red",
  green = "Green",
  blue = "Blue",
  black = "Black",
}
