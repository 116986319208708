import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BubbleDataPoint, ChartData, ScatterDataPoint } from "chart.js";

import { getGraphDataInPercentages } from "../../../selectors/location";

interface SummaryDoughnutChartProperties {
  data: ChartData<"doughnut", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  options: any;
}

export const useGetSummaryDoughnutChartProperties = (): SummaryDoughnutChartProperties => {
  const { t } = useTranslation();

  const graphDataInPercentages = useSelector(getGraphDataInPercentages);
  const graphData = graphDataInPercentages.map((data) => data.percentages);
  const bgColors = graphDataInPercentages.map((data) => data.bgColor);
  const labels: string[] = graphDataInPercentages.map((data) => t(`enums.allWasteTypes.${data.wasteType}.short`));

  const data: ChartData<"doughnut", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> = {
    labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: bgColors,
        borderWidth: 0,
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      interaction: {
        mode: "point",
      },
      datalabels: {
        anchor: "end",
        formatter: (value: number): string | null => {
          if (value !== 0) {
            return `${value} %`;
          }
          return null;
        },
        borderColor: "#00000029",
        borderRadius: Number.MAX_VALUE,
        borderWidth: 1,
        color: "black",
        backgroundColor: "white",
        textAlign: "center",
        offset: 0,
        font: {
          weight: "800",
          size: 16,
          lineHeight: 1,
          family: "Sora",
        },
        padding: (ctx: any) => {
          const item = graphData[ctx.dataIndex];
          const chartContext = ctx.chart.ctx;
          const circlePerimeter = 60;
          const text = `${item} %`;
          chartContext.font = "normal normal 800 16px/1 Sora";
          const textProperties = chartContext.measureText(text);
          const fontHeight = textProperties.fontBoundingBoxAscent + textProperties.fontBoundingBoxDescent;
          const textWidth = textProperties.width;
          const paddingSides = (circlePerimeter - textWidth) / 2 - 2;
          const paddingTopAndBottom = (circlePerimeter - fontHeight) / 2;
          const paddingObject = {
            top: paddingTopAndBottom + 2,
            bottom: paddingTopAndBottom - 2,
            left: paddingSides,
            right: paddingSides,
          };

          return paddingObject;
        },
      },
    },
    layout: {
      padding: 30,
    },
    cutout: "60%",
  };

  const summaryDoughnutChartProperties: SummaryDoughnutChartProperties = { data, options };

  return summaryDoughnutChartProperties;
};
