import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { ActiveIndicator } from "../../../components/ActiveIndicator";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { ProgressBar } from "../../../components/ProgressBar";
import { TableCellWithRightArrow } from "../../../components/TableCellWithRightArrow";
import { TablePaymentStatusIndicatorCell } from "../../../components/TablePaymentStatusIndicatorCell";
import { useIsFeatureEnabled } from "../../../hooks/useIsFeatureEnabled";
import { getCollectionPointsLocations } from "../../../selectors/collectionPoints";
import { getDoesWeigh, getIsSeparating } from "../../../selectors/mayorModules";
import { InvitiationStatus } from "../../../types/collectionPoint";
import { IColumnAlignObject, IColumnValuePair } from "../../../types/table";
import { getWeighValue } from "../../../utils/collectionPoints/getWeighValue";
import { FeatureName } from "../../../utils/enums";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import {
  columnLabelsIsNotSeparating,
  columnLabelsIsSeparating,
  ColumnTypeIsNotSeparating,
  ColumnTypeIsSeparating,
} from "./CollectionPointsTableColumnTypes";
import { CollectionPointsTableHeaderCell } from "./CollectionPointsTableHeaderCell";

import styles from "./CollectionPointsTable.module.scss";

const columnAlignObject: IColumnAlignObject<ColumnTypeIsSeparating | ColumnTypeIsNotSeparating> = {
  center: [
    ColumnTypeIsSeparating.degreeOfSeparation,
    ColumnTypeIsSeparating.containerCount,
    ColumnTypeIsSeparating.totalDumpings,
    ColumnTypeIsSeparating.isMobile,
    ColumnTypeIsSeparating.isPaid,
    ColumnTypeIsNotSeparating.wastePerHouseholdMember,
    ColumnTypeIsSeparating.totalTKO,
  ],
};

const columnFlexValuePairs: IColumnValuePair<ColumnTypeIsSeparating | ColumnTypeIsNotSeparating>[] = [
  {
    name: ColumnTypeIsSeparating.name,
    value: 3,
  },
  { name: ColumnTypeIsSeparating.degreeOfSeparation, value: 2 },
  { name: ColumnTypeIsNotSeparating.wastePerHouseholdMember, value: 2 },
  { name: ColumnTypeIsSeparating.address, value: 3 },
  { name: ColumnTypeIsSeparating.containerCount, value: 2 },
];

const columnMinWidthValuePairs: IColumnValuePair<ColumnTypeIsSeparating | ColumnTypeIsNotSeparating>[] = [
  {
    name: ColumnTypeIsSeparating.wcpid,
    value: 130,
  },
];

export const useGetCollectionPointsTableColumns = () => {
  const data = useSelector(getCollectionPointsLocations);

  const { t } = useTranslation();
  const { getAlign, getFlex, getMinWidth } = getTableColumnUtils();
  const isSeparating = useSelector(getIsSeparating);
  const doesWeigh = useSelector(getDoesWeigh);
  const mobileEnabled = useIsFeatureEnabled(FeatureName.mobile);

  const filterColumns = (label: ColumnTypeIsNotSeparating | ColumnTypeIsSeparating) =>
    mobileEnabled || !(label === ColumnTypeIsSeparating.isMobile);

  const appliedColumnLabels = isSeparating
    ? columnLabelsIsSeparating.filter(filterColumns)
    : columnLabelsIsNotSeparating.filter(filterColumns);

  const columns: GridColDef[] = appliedColumnLabels.map((label, i) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <CollectionPointsTableHeaderCell params={params} />;
      },
      flex: getFlex(label, columnFlexValuePairs),
      align: getAlign(label, columnAlignObject),
      headerAlign: getAlign(label, columnAlignObject),
      minWidth: getMinWidth(label, columnMinWidthValuePairs),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case ColumnTypeIsSeparating.name: {
          return (
            <div className={styles.nameWrapper}>
              <ActiveIndicator enableTime={params.row.enableTime} disableTime={params.row.disableTime} />
              <span>{params.row.name}</span>
            </div>
          );
        }
        case ColumnTypeIsSeparating.degreeOfSeparation:
        case ColumnTypeIsNotSeparating.wastePerHouseholdMember: {
          const lastLocationId: number = data[data.length - 1].locationId;
          return (
            <ProgressBar
              value={params.value}
              doesWeigh={doesWeigh}
              key={`${params.field}-${params.id}-${lastLocationId}-${i}`}
            />
          );
        }

        case ColumnTypeIsSeparating.isPaid: {
          return <TablePaymentStatusIndicatorCell params={params} />;
        }

        case ColumnTypeIsSeparating.isMobile: {
          const getAppliedIconProperties = () => {
            const invitationStatus = params.row.invitationStatus;
            let iconName = IconNameEnum.cross;
            let iconType = IconTypeEnum.blue;

            switch (invitationStatus) {
              case InvitiationStatus.Invited:
                iconName = IconNameEnum.clock;
                iconType = IconTypeEnum.default;
                break;
              case InvitiationStatus.Registered:
                iconName = IconNameEnum.check;
                iconType = IconTypeEnum.green;
                break;

              default:
                break;
            }

            return { iconName, iconType };
          };

          const { iconName, iconType } = getAppliedIconProperties();

          return <Icon name={iconName} type={iconType} />;
        }

        case ColumnTypeIsSeparating.wcpid: {
          return <TableCellWithRightArrow mainComponent={<p>{params.row.wcpid}</p>} />;
        }
        case ColumnTypeIsSeparating.totalTKO: {
          return <p>{getWeighValue(doesWeigh, params.value, "kg")}</p>;
        }

        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
