import { WagonsFilter } from "../../types/wagonEvent";
import { getEndOfYearDate, getStartOfYearDate } from "../../utils/dateHandler";

export const getStartEndDate = (filter: WagonsFilter, selectedYear: number) => {
  let startDate = "";
  let endDate = getEndOfYearDate(selectedYear).toISOString();

  const currentDate = new Date();
  if (filter === WagonsFilter.actual) {
    if (selectedYear === currentDate.getFullYear()) {
      startDate = currentDate.toISOString();
    } else {
      startDate = getStartOfYearDate(selectedYear).toISOString();
    }
  } else {
    startDate = getStartOfYearDate(selectedYear).toISOString();
    if (selectedYear === currentDate.getFullYear()) endDate = currentDate.toISOString();
  }

  return { startDate, endDate };
};
