import { IWasteSummary, IWasteTypeRow } from "../../types/wasteTypeQuantity";
import { getWasteTypesSummaryRows } from "./dataTransform";
import { getTotals } from "./getTotals";

interface WasteSummaryProperties {
  allWasteSummaryDetailData: IWasteSummary;
  wasteTypesSummaryRows: IWasteTypeRow[];
}

export const getWasteSummaryProperties = (wasteSummaryDetailData: IWasteSummary): WasteSummaryProperties => {
  const allWasteSummaryDetailData: IWasteSummary = getTotals(wasteSummaryDetailData);
  const wasteTypesSummaryRows = getWasteTypesSummaryRows(wasteSummaryDetailData);

  const wasteSummaryProperties: WasteSummaryProperties = { allWasteSummaryDetailData, wasteTypesSummaryRows };

  return wasteSummaryProperties;
};
