import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Popover } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { CustomButton, IButtonVariant } from "../../../../../components/CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../../../components/Icon";
import { removeFilter, setSort } from "../../../../../reducers/wagonsReportStateReducer";
import { getWagonsReportFilterIsActive, getWagonsReportSortValue } from "../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../store";
import { wagonReportTableColumnType } from "../WagonsReportTableUtils";
import { ContainerType } from "./filters/containerType";
import { DateFilter } from "./filters/date";
import { LocationFilter } from "./filters/location";
import { RfidFilter } from "./filters/rfid";
import { WeightFilter } from "./filters/weight";

import styles from "./WagonsReportTableHeader.module.scss";

interface Props {
  params: GridColumnHeaderParams;
}

const selectFilters = (type: wagonReportTableColumnType) => {
  switch (type) {
    case wagonReportTableColumnType.date:
      return <DateFilter fieldName={type} />;
    case wagonReportTableColumnType.location:
      return <LocationFilter fieldName={type} />;
    case wagonReportTableColumnType.containerType:
      return <ContainerType fieldName={type} />;
    case wagonReportTableColumnType.weight:
      return <WeightFilter fieldName={type} />;
    case wagonReportTableColumnType.rfid:
      return <RfidFilter fieldName={type} />;
    default:
      <div />;
  }
};

export const WagonsReportTableHeader: React.FC<Props> = ({ params }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const sort = useSelector((state: RootState) => getWagonsReportSortValue(state, params.field));
  const isFilterActive = useSelector((state: RootState) => getWagonsReportFilterIsActive(state, params.field));
  const { t } = useTranslation();

  const changeSort = () => {
    dispatch(setSort(params.field as wagonReportTableColumnType));
  };

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <div className={styles.title}>{t(`wagonsReport.${params.field}`)}</div>
      <IconButton aria-describedby={id} onClick={openFilters}>
        {isFilterActive ? (
          <Icon name={IconNameEnum.filter} type={IconTypeEnum.grey} />
        ) : (
          <Icon name={IconNameEnum.filter} />
        )}
      </IconButton>
      <IconButton onClick={changeSort}>
        {sort === "asc" && <Icon name={IconNameEnum.sortAsc} />}
        {sort === "desc" && <Icon name={IconNameEnum.sortDesc} />}
        {!sort && <Icon name={IconNameEnum.sortNone} />}
      </IconButton>
      <Popover
        className={styles.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <h1>{t(`wagonsReport.${params.field}`)}</h1>
        <div className={styles.filters}>{selectFilters(params.field as wagonReportTableColumnType)}</div>
        <CustomButton
          variant={IButtonVariant.textImportant}
          className={styles.textSize}
          onClick={() => {
            dispatch(removeFilter(params.field));
          }}
          text={t("wagonsReport.filters.cancel")}
        />
      </Popover>
    </>
  );
};
