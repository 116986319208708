import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "../../../../../../components/Checkbox";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../../../../components/Icon";
import { TextInput, TextInputIconPosition } from "../../../../../../components/TextInput";
import { setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const WeightFilter: React.FC<Props> = ({ fieldName }) => {
  const minValueId = `${fieldName}Min`;
  const maxValueId = `${fieldName}Max`;
  const warningId = `${fieldName}Warning`;
  const minValue = useSelector((state: RootState) => getWagonsReportFilterValue(state, minValueId));
  const maxValue = useSelector((state: RootState) => getWagonsReportFilterValue(state, maxValueId));
  const warningValue = useSelector((state: RootState) => getWagonsReportFilterValue(state, warningId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={warningValue?.value === true}
          onChange={() => {
            dispatch(
              setFilter({
                id: `${fieldName}Warning`,
                columnField: fieldName,
                operatorValue: "hasWarning",
                value: !warningValue?.value,
              })
            );
          }}
        />
        {t("wagonsReport.filters.hasWarning")}
        <Icon name={IconNameEnum.warning} style={{ marginLeft: "8px" }} />
      </div>
      <TextInput
        placeHolder={t("wagonsReport.filters.weightFrom")}
        type="number"
        value={String(minValue?.value)}
        icon={IconNameEnum.weight}
        iconType={IconTypeEnum.default}
        iconSize={28}
        iconPosition={TextInputIconPosition.end}
        onChange={(e) => {
          dispatch(setFilter({ id: minValueId, columnField: fieldName, operatorValue: ">=", value: e.target.value }));
        }}
      />
      <TextInput
        placeHolder={t("wagonsReport.filters.weightTo")}
        type="number"
        value={String(maxValue?.value)}
        icon={IconNameEnum.weight}
        iconType={IconTypeEnum.default}
        iconSize={28}
        iconPosition={TextInputIconPosition.end}
        onChange={(e) => {
          dispatch(setFilter({ id: maxValueId, columnField: fieldName, operatorValue: "<=", value: e.target.value }));
        }}
      />
    </>
  );
};
