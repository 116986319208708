import { isArray } from "lodash";

import { RootState } from "../../store";
import {
  ICollectionYardsListRequest,
  ICollectionYardsVisit,
  ICollectionYardsVisitApi,
} from "../../types/collectionYards";
import { camelToSnakeCase } from "../../utils/transformCase";

export const adaptResponseForFronted = (apiVisits: ICollectionYardsVisitApi[]): ICollectionYardsVisit[] => {
  return apiVisits.map((apiVisit) => {
    return {
      id: apiVisit.collection_yard_visit_id,
      locationId: apiVisit.location_id,
      wasteTypes: apiVisit.waste_types,
      wasteSum: apiVisit.waste_sum,
      priceSum: apiVisit.price_sum,
      paid: apiVisit.paid,
      created: apiVisit.created,
      locationName: apiVisit.location_name,
      collectionYardName: apiVisit.collection_yard_name,
    };
  });
};

export const adaptRequestBodyForBackend = (state: RootState): ICollectionYardsListRequest => {
  const { pagination, search, ordering, filters } = state.collectionYards;

  return {
    year: Number(state.application.years.selectedYear.value),
    fulltext: search.fulltext,
    pagination,
    ordering: {
      orderBy: camelToSnakeCase(ordering.orderBy),
      descending: ordering.descending,
    },
    filters: filters.items
      .filter((filter) => {
        const isEmptyArray = isArray(filter.value) && filter.value.length === 0;
        const isEmptyString = filter.value === "";

        return !isEmptyString && !isEmptyArray;
      })
      .map((filter) => {
        return {
          name: camelToSnakeCase(filter.name),
          operator: filter.operator,
          value: filter.value,
        };
      }),
  };
};
