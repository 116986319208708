import { useTranslation } from "react-i18next";

import styles from "./DateCell.module.scss";

interface DateCellProps {
  isInHoliday: boolean;
  date: string;
}

export const DateCell: React.FC<DateCellProps> = ({ isInHoliday, date }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.date}>
      <p>{date}</p>
      {isInHoliday && <div className={styles.holiday}>! {t("holiday")}</div>}
    </div>
  );
};
