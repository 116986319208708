import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GridEventListener, GridEvents, GridSortModel } from "@mui/x-data-grid-pro";

import { COLLECTION_POINT_PAGE_PATH } from "../../../middleware/routes";
import {
  cleanUpCollectionPointDetail,
  getCollectionPointDetailThunk,
  setCollectionPoint,
} from "../../../reducers/collectionPointReducer";
import { defaultOrdering, setCollectionPointsListOrdering } from "../../../reducers/collectionPointsReducer";
import {
  getCollectionPointsOrdering,
  getIsCollectionPointsListStatusLoading,
} from "../../../selectors/collectionPoints";
import { ColumnTypeIsSeparating } from "./CollectionPointsTableColumnTypes";

interface CollectionPointsTableHandlers {
  handleAdditionalRowClick: GridEventListener<GridEvents.rowClick>;
  handleSortModelChange: (model: GridSortModel) => void;
}

export const useGetCollectionPointsTableHandlers = (): CollectionPointsTableHandlers => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ordering = useSelector(getCollectionPointsOrdering);
  const isLoading = useSelector(getIsCollectionPointsListStatusLoading);

  const handleAdditionalRowClick: GridEventListener<GridEvents.rowClick> = (params) => {
    const collectionPointId = params.row.locationId;
    dispatch(cleanUpCollectionPointDetail());
    dispatch(setCollectionPoint(params.row));
    dispatch(getCollectionPointDetailThunk(collectionPointId));

    history.push(`${COLLECTION_POINT_PAGE_PATH}?id=${collectionPointId}`);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (isLoading) {
      return;
    }
    const field = model[0].field;
    //sort case sensitivity
    const newOrderBy = () => {
      switch (field) {
        case ColumnTypeIsSeparating.isMobile:
          return "invitationStatus";
        default:
          return field;
      }
    };

    const newOrdering = {
      orderBy: newOrderBy(),
      descending: ordering.orderBy === newOrderBy() ? !ordering.descending : defaultOrdering.descending,
    };
    dispatch(setCollectionPointsListOrdering(newOrdering));
  };

  const collectionPointsTableHandlers: CollectionPointsTableHandlers = {
    handleAdditionalRowClick,
    handleSortModelChange,
  };

  return collectionPointsTableHandlers;
};
