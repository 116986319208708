import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { NoResultsOverlay } from "../NoResultsOverlay";
import { HandleAdditionalRowClickHandler, useGetDataTableHandlers } from "./useGetDataTableHandlers";
import { SetPaginationHandler, SliceStatePartial, useHandleDataTableNextPage } from "./useHandleDataTableNextPage";

import styles from "./DataTable.module.scss";

interface DataTableProps {
  dataRows: any[];
  columns: GridColDef<any, any, any>[];
  scrollTop: number;
  setScrollTop: ActionCreatorWithPayload<number>;
  setPagination: SetPaginationHandler;
  sliceState: SliceStatePartial;
  isLoading: boolean;
  handleAdditionalRowClick?: HandleAdditionalRowClickHandler;
  handleSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
  getCellClassName?: (params: GridCellParams<any, any, any>) => string;
  getRowClassName?: (params: GridRowClassNameParams<any>) => string;
  className?: string;
}

export const DataTable: React.FC<DataTableProps> = ({
  className,
  dataRows,
  columns,
  scrollTop,
  setScrollTop,
  setPagination,
  isLoading,
  handleSortModelChange,
  sliceState,
  handleAdditionalRowClick,
  getCellClassName,
  getRowClassName,
}) => {
  const { handleNextPage } = useHandleDataTableNextPage(sliceState, setPagination);
  const { handleRowClick, apiRef } = useGetDataTableHandlers(scrollTop, setScrollTop, handleAdditionalRowClick);

  const appliedContainerClassNamesArray = [styles.container];
  if (isLoading) {
    appliedContainerClassNamesArray.push(styles.isLoading);
  }
  if (className) {
    appliedContainerClassNamesArray.push(className);
  }

  return (
    <div className={getModuleClassNames(appliedContainerClassNamesArray)}>
      <DataGridPro
        apiRef={apiRef}
        rows={dataRows}
        columns={columns}
        disableColumnMenu
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: NoResultsOverlay,
          NoResultsOverlay,
        }}
        onRowsScrollEnd={handleNextPage}
        pagination
        paginationMode="server"
        hideFooter
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sortingOrder={["asc", "desc"]}
        scrollEndThreshold={100}
        getCellClassName={getCellClassName}
        disableSelectionOnClick
        getRowClassName={getRowClassName}
      />
    </div>
  );
};
