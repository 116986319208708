import { useSelector } from "react-redux";
import { GridRowClassNameParams } from "@mui/x-data-grid-pro";

import { DataTable } from "../../../components/DataTable";
import {
  setInventorySheetListPagination,
  setInventorySheetListScrollTop,
} from "../../../reducers/inventorySheetReducer";
import {
  getInventorySheet,
  getInventorySheetContainers,
  getInventorySheetScrollTop,
  getIsInventorySheetListStatusLoading,
} from "../../../selectors/inventorySheet";
import { IInventorySheetContainer } from "../../../types/inventorySheet";
import { useGetInventorySheetTableColumns } from "./useGetInventorySheetTableColumns";
import { useGetInventorySheetTableHandlers } from "./useGetInventorySheetTableHandlers";

export const InventorySheetTable: React.FC = () => {
  const data = useSelector(getInventorySheetContainers);
  const columns = useGetInventorySheetTableColumns();
  const isLoading = useSelector(getIsInventorySheetListStatusLoading);
  const { handleAdditionalRowClick } = useGetInventorySheetTableHandlers();
  const scrollTop = useSelector(getInventorySheetScrollTop);
  const inventorySheetState = useSelector(getInventorySheet);

  const getRowClassName = (params: GridRowClassNameParams<IInventorySheetContainer>) =>
    !params.row.rfid && !params.row.isMocked ? "error" : "";

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setInventorySheetListScrollTop}
      setPagination={setInventorySheetListPagination}
      isLoading={isLoading}
      sliceState={inventorySheetState}
      handleAdditionalRowClick={handleAdditionalRowClick}
      getRowClassName={getRowClassName}
    />
  );
};
