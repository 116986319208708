import { IWasteSummary, IWasteTypeSummary } from "../../types/wasteTypeQuantity";
import { AllWasteTypes, comunalWasteTypes, monthsArray } from "../enums";

export const getTotals = (wasteSummaryDetailData: IWasteSummary) => {
  const sum = (a: number, b: number) => a + b;

  const totalWaste = {} as IWasteTypeSummary;
  monthsArray.forEach((month) => {
    const arr = wasteSummaryDetailData.wasteTypeSummaries.map((wasteTypeObj) => {
      const { wasteTypeSummaryId, wasteType, ...wasteTypeObjOnlyData } = wasteTypeObj;

      return wasteTypeObjOnlyData[month];
    });

    const arrSum = arr.reduce(sum);
    totalWaste[month] = arrSum;
    totalWaste.wasteType = AllWasteTypes.totalWaste;
  });

  const recycled = {} as IWasteTypeSummary;
  monthsArray.forEach((month) => {
    const arr = wasteSummaryDetailData.wasteTypeSummaries
      .filter((obj) => !comunalWasteTypes.includes(obj.wasteType))
      .map((wasteTypeObj) => {
        const { wasteTypeSummaryId, wasteType, ...wasteTypeObjOnlyData } = wasteTypeObj;

        return wasteTypeObjOnlyData[month];
      });

    const arrSum = arr.reduce(sum);
    recycled[month] = arrSum;
    recycled.wasteType = AllWasteTypes.recycled;
  });

  const allWasteSummaryDetailData = {
    ...wasteSummaryDetailData,
    wasteTypeSummaries: [...wasteSummaryDetailData.wasteTypeSummaries, recycled, totalWaste],
  };

  return allWasteSummaryDetailData;
};
