import { memo } from "react";
import { FC, useEffect, useRef, useState } from "react";
import { Marker, useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { Pin } from "../../../../components/Pin";
import { ClickableTooltip } from "../../../../components/Tooltip";
import { resetJumpTo } from "../../../../reducers/wagonsReportStateReducer";
import { getJumpTo } from "../../../../selectors/wagonsReport";
import { Colors } from "../../../../utils/enums";
import { WagonDetail } from "../WagonDetail";

interface Props {
  point: any;
  expansionZoom?: number;
}
const getInitOpen = (props: any, id?: number) => {
  if (!props.cluster && props.containers.some((c: any) => c.dumpings.some((d: any) => d.vehicleEventId === id)))
    return true;
  return false;
};

const selectColor = (props: any) => {
  if (!props.hasRfid) return Colors.Red;
  if (props.hasRfid && props.hasWarning) return Colors.Orange;
  return Colors.Green;
};

export const WagonMarker: FC<Props> = memo(({ point, expansionZoom }) => {
  const dispatch = useDispatch();
  const markerRef = useRef<mapboxgl.Marker>(null);
  const jumpTo = useSelector(getJumpTo);
  const [open, setOpen] = useState(getInitOpen(point.properties, jumpTo?.vehicleEventId));
  const map = useMap();

  useEffect(() => {
    if (!markerRef.current) return;
    markerRef.current.getElement().setAttribute("open", `${open}`);
  }, [markerRef, open]);

  return (
    <Marker
      key={point.properties.vehicleEventId}
      longitude={point.geometry.coordinates[0]}
      latitude={point.geometry.coordinates[1]}
      anchor="center"
      style={{ cursor: "pointer" }}
      ref={markerRef}
    >
      {point.properties.cluster ? (
        <div
          onClick={() => {
            if (map.current) map.current.flyTo({ center: point.geometry.coordinates, zoom: expansionZoom });
          }}
        >
          <Pin color={Colors.SecondaryText} hoverOverlay={true} mainValue={point.properties.point_count} />
        </div>
      ) : (
        <ClickableTooltip
          bgColor={"none"}
          tooltipState={open}
          tooltipOpen={() => {
            setOpen(true);
          }}
          tooltipClose={() => {
            setOpen(false);
            dispatch(resetJumpTo());
          }}
          content={<WagonDetail pointDetail={point.properties} />}
        >
          <Pin
            color={selectColor(point.properties)}
            hoverOverlay={true}
            hoverOverlayActive={open}
            mainValue={<Icon name={IconNameEnum.delete} type={IconTypeEnum.white} size="24px" />}
            additinalValue={point.properties.dumpingsCount > 1 && point.properties.dumpingsCount}
          />
        </ClickableTooltip>
      )}
    </Marker>
  );
});
