import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { indexListener, listenerMiddleware } from "../middleware/listenerMiddleware/listenerMiddleware";
import { rootReducer } from "../reducers/rootReducer";

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export const useAppDispatch: () => AppDispatch = useDispatch;

indexListener();
