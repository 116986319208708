import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import classNames from "classnames";

import { Container } from "../../components/Container";
import { CustomButton, IButtonVariant } from "../../components/CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../../components/Icon";
import { getCollectionYardDetailCredentialsThunk } from "../../reducers/collectionYardDetailSettingsReducer";
import {
  getCollectionYardDetailCredentials,
  getCollectionYardDetailLogin,
} from "../../selectors/collectionYardDetailSettings";

import styles from "./CollectionYardDetailSettingsPage.module.scss";

export const CollectionYardDetailSettingsPageCredentialsContainer: React.FC = () => {
  const { t } = useTranslation();
  const login = useSelector(getCollectionYardDetailLogin);
  const generatedCredentials = useSelector(getCollectionYardDetailCredentials);
  const dispatch = useDispatch();

  const [areCredentialsRequested, setAreCredentialsRequested] = useState<boolean>(false);

  const generateCredentials = () => {
    setAreCredentialsRequested(true);
    dispatch(getCollectionYardDetailCredentialsThunk());
  };

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(generatedCredentials?.login ?? login ?? "");
  };

  const copyPasswordToClipboard = () => {
    navigator.clipboard.writeText(generatedCredentials?.password ?? "");
  };

  const showCredentials = !!login || !!generatedCredentials;

  return (
    <Container>
      <div className={styles.wrapperItems}>
        <div className={classNames(styles.item, styles.generateCredentialsContainer)}>
          <h6 className={styles.title}>{t("credentialsContainer.title")}</h6>
          <p>{t("credentialsContainer.text")}</p>
          <CustomButton
            onClick={generateCredentials}
            className={styles.button}
            classNameText={styles.buttonText}
            disabled={areCredentialsRequested || !!generatedCredentials}
            text={t("credentialsContainer.buttonText")}
            variant={login ? IButtonVariant.neutral : undefined}
          />
        </div>
        {showCredentials && <Divider />}
        {showCredentials && (
          <div className={classNames(styles.item, styles.credentialsContainer)}>
            <div className={styles.credentialContainer}>
              <div>
                <p className={styles.label}>{t("email")}</p>
                <p className={styles.credential}>{generatedCredentials?.login ?? login}</p>
              </div>
              <div className={styles.iconContainer} onClick={copyEmailToClipboard}>
                <Icon name={IconNameEnum.paperclip} type={IconTypeEnum.grey} />
              </div>
            </div>
            <div className={styles.credentialContainer}>
              <div>
                <p className={styles.label}>{t("password")}</p>
                <p className={styles.credential}>{generatedCredentials?.password ?? "**************************"}</p>
              </div>
              <div className={styles.iconContainer} onClick={copyPasswordToClipboard}>
                <Icon name={IconNameEnum.paperclip} type={IconTypeEnum.grey} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
