import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton } from "../../../components/CustomButton";
import { SearchBar } from "../../../components/SearchBar";
import {
  defaultSearch,
  exportInventorySheetToXlsxThunk,
  setInventorySheetListSearch,
} from "../../../reducers/inventorySheetReducer";
import { getInventorySheetSearch } from "../../../selectors/inventorySheet";

import styles from "./InventorySheetPageHeaderComponent.module.scss";

export const InventorySheetPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentSearch = useSelector(getInventorySheetSearch);
  const [search, setSearch] = useState(currentSearch.fulltext);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;

    if (!value && currentSearch.fulltext) {
      dispatch(setInventorySheetListSearch(defaultSearch));
    }
    setSearch(value);
  };

  const handleSearch = (): void => {
    dispatch(setInventorySheetListSearch({ fulltext: search }));
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={styles.controls}>
      <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} onKeyDown={handleKeyPress} />
      <CustomButton onClick={() => dispatch(exportInventorySheetToXlsxThunk())} text={t("downloadSheetXLS")} />
    </div>
  );
};
