import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_LOCATION_DOUGHNUT_CHART_DATA_URL, GET_LOCATION_SUMMARY_URL } from "../middleware/routes";
import { RootState } from "../store";
import {
  ILocation,
  ILocationState,
  ILocationSummary,
  ILocationThunkResponse,
  IWasteSummaryAggregatedDetailThunkResponse,
  IWasteTypesDoughnutChart,
} from "../types/location";
import { ISelectOption } from "../types/select";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getDoughnutChartData } from "../utils/charts/getDoughnutChartData";

const defaultLocationSummary: ILocationSummary = {
  containersSum: 0,
  wasteSum: 0,
  householdsSum: 0,
  householdsMembersSum: 0,
  degreeOfSeparation: 0,
  isDoughnutChartShown: true,
};

const initWasteTypesDoughnutChart: IWasteTypesDoughnutChart = {
  graphDataInPercentages: [],
};
export const defaultLocation: ILocation = {
  summary: defaultLocationSummary,
  currentYearSummary: defaultLocationSummary,
  wasteTypesDoughnutChart: initWasteTypesDoughnutChart,
};

const initLocationState: ILocationState = {
  status: Status.idle,
  errorMsg: null,
  location: defaultLocation,
};

export const locationSlice = createSlice({
  name: "location",
  initialState: initLocationState,
  reducers: {
    resetLocationState: () => {
      return initLocationState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationSummaryThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(getLocationSummaryThunk.fulfilled, (state, action) => {
        const { data, year } = action.payload;
        const currentYear = new Date().getFullYear();

        state.status = Status.success;
        state.errorMsg = "";
        state.location.summary = { ...state.location.summary, ...data };

        if (year === currentYear) {
          state.location.currentYearSummary = { ...state.location.currentYearSummary, ...data };
        }
      })
      .addCase(getLocationSummaryThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        state.location.summary = defaultLocationSummary;
      })

      .addCase(getLocationDoughnutChartDataThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(
        getLocationDoughnutChartDataThunk.fulfilled,
        (state, action: PayloadAction<IWasteSummaryAggregatedDetailThunkResponse>) => {
          const { data, year } = action.payload;
          const currentYear = new Date().getFullYear();
          const doughnutChartData = getDoughnutChartData(action.payload.data);
          state.location.wasteTypesDoughnutChart = doughnutChartData;

          const isDoughnutChartShown = data && data.some((summaryObj) => summaryObj.share !== 0);

          state.location.summary.isDoughnutChartShown = isDoughnutChartShown;
          if (year === currentYear) state.location.currentYearSummary.isDoughnutChartShown = isDoughnutChartShown;
        }
      )
      .addCase(getLocationDoughnutChartDataThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export const { resetLocationState } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;

export const getLocationSummaryThunk = createAsyncThunk<
  ILocationThunkResponse,
  ISelectOption["value"] | undefined,
  { state: RootState }
>("location/getLocationSummaryThunk", async (year, { getState }) => {
  const state = getState();
  const selectedYear = state.application.years.selectedYear.value;
  const appliedYear = year ?? selectedYear;

  const response = await axiosRequestApi.get(`${GET_LOCATION_SUMMARY_URL}/${appliedYear}`);

  return { data: response.data, year: appliedYear };
});

export const getLocationDoughnutChartDataThunk = createAsyncThunk<
  IWasteSummaryAggregatedDetailThunkResponse,
  ISelectOption["value"] | undefined,
  { state: RootState }
>("wasteTypeQuantity/getLocationDoughnutChartDataThunk", async (year, { getState }) => {
  const state = getState();
  const selectedYear = state.application.years.selectedYear.value;
  const appliedYear = year ?? selectedYear;

  const response = await axiosRequestApi.get(`${GET_LOCATION_DOUGHNUT_CHART_DATA_URL}/${appliedYear}`);
  return { data: response.data, year: appliedYear };
});
