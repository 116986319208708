import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider } from "@mui/material";

import { Container } from "../../components/Container";
import { Icon, IconNameEnum } from "../../components/Icon";
import { PageSettingsContainer } from "../../components/PageSettingsContainer";
import { COLLECTION_YARD_DETAIL_SETTINGS_PAGE } from "../../middleware/routes";
import {
  getCollectionYardDetailThunk,
  resetCollectionYardDetailSettingsState,
} from "../../reducers/collectionYardDetailSettingsReducer";
import { getCollectionYardsList } from "../../selectors/collectionYardsSettings";
import { useAppDispatch } from "../../store";

import styles from "./CollectionYardsSettingsPage.module.scss";

export const CollectionYardsSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const collectionYardsList = useSelector(getCollectionYardsList);
  const history = useHistory();

  const handleRedirect = (collectionYardId: number) => {
    dispatch(resetCollectionYardDetailSettingsState());
    dispatch(getCollectionYardDetailThunk(collectionYardId));
    history.push(`${COLLECTION_YARD_DETAIL_SETTINGS_PAGE}?id=${collectionYardId}`);
  };

  return (
    <PageSettingsContainer headerText={t("collectionYard")} fullHeight={false}>
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.wrapperItems}>
            {collectionYardsList.map(({ name, collectionYardId }, i) => (
              <Fragment key={collectionYardId}>
                <div onClick={() => handleRedirect(collectionYardId)} className={styles.item}>
                  <p>{name}</p>
                  <Icon name={IconNameEnum.chevronRight} />
                </div>
                {i !== collectionYardsList.length - 1 && <Divider />}
              </Fragment>
            ))}
          </div>
        </Container>
      </div>
    </PageSettingsContainer>
  );
};
