import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { getDoesWeigh, getIsSeparating } from "../../../selectors/mayorModules";
import { NumberBanner } from "../NumberBanner/NumberBanner";
import { SeparationBanner } from "./SeparationBanner";

import styles from "./BannersComponent.module.scss";

export const BannersComponent: React.FC = () => {
  const { t } = useTranslation();
  const collectionPointDetail = useSelector(getCollectionPointDetail);

  const appliedHouseholdMemberCountValue = collectionPointDetail ? collectionPointDetail.householdMembersCount : 0;
  const appliedWastePerHouseholdMemberLabel = collectionPointDetail.isPO
    ? t("wasteTotal")
    : `${t("enums.wastetypes.mixed").toLowerCase()} / ${t("person")} / ${t("year")}`;

  const isSeparating = useSelector(getIsSeparating);
  const doesWeigh = useSelector(getDoesWeigh);

  return (
    <div className={styles.banners}>
      {isSeparating && doesWeigh ? (
        <NumberBanner
          label={t("separationRate")}
          value={collectionPointDetail ? collectionPointDetail.degreeOfSeparation : 0}
          unit="%"
          adaptiveColor={isSeparating}
          isSeparating={true}
        />
      ) : (
        <SeparationBanner />
      )}
      {collectionPointDetail.wastePerHouseholdMember !== null && (
        <>
          {!collectionPointDetail.isPO && (
            <NumberBanner label={t("memberCount")} value={appliedHouseholdMemberCountValue} />
          )}
          <NumberBanner
            label={appliedWastePerHouseholdMemberLabel}
            value={Number(collectionPointDetail.wastePerHouseholdMember)}
            unit={doesWeigh ? "kg" : ""}
            adaptiveColor={!isSeparating}
            isSeparating={false}
            doesWeigh={doesWeigh}
          />
        </>
      )}
    </div>
  );
};
