import { IWagonsCalendarRequest } from "../../../reducers/wagonsCalendarReducer";
import { RootState } from "../../../store";

export const getWagonsCalendarThunkRequestObject = (getState: () => RootState) => {
  const state = getState();
  const selectedYearValue = Number(state.application.years.selectedYear.value);
  const { pagination } = state.wagonsCalendar;

  const requestObject: IWagonsCalendarRequest = {
    pagination,
    year: selectedYearValue,
  };

  return requestObject;
};
