export const dummyData = {
  summary: {
    found: 19,
    containers: [
      { count: 15, containerType: 1 },
      { count: 2, containerType: 2 },
    ],
    noRfid: 1,
    totalWeight: 414,
  },
  list: [
    {
      vehicleEventId: 1,
      containerId: 109,
      locationId: 209,
      sector: 26,
      date: "2023-09-02T09:45:23.000Z",
      location: "Juraj Maťko, Železná 17",
      containerType: 1,
      weight: 160,
      wasteType: 6,
      rfid: "****30",
      lat: 48.172584,
      long: 17.16658,
      warning: true,
    },
    {
      vehicleEventId: 2,
      containerId: 110,
      locationId: 210,
      sector: 26,
      date: "2023-09-03T14:20:31.000Z",
      location: "Eva Nováková, Železná 25",
      containerType: 1,
      weight: 10,
      wasteType: 6,
      rfid: "****19",
      lat: 48.172795,
      long: 17.166982,
      warning: false,
    },
    {
      vehicleEventId: 4,
      containerId: 111,
      locationId: 211,
      sector: 26,
      date: "2023-09-04T11:55:17.000Z",
      location: "Andrej Kováč, Bielkova 9",
      containerType: 1,
      weight: 22,
      wasteType: 6,
      rfid: "****21",
      lat: 48.172115,
      long: 17.167315,
      warning: false,
    },
    {
      vehicleEventId: 21,
      containerId: null,
      locationId: null,
      sector: null,
      date: "2023-09-15T14:40:52.000Z",
      location: null,
      containerType: null,
      weight: 13,
      wasteType: null,
      rfid: null,
      lat: 48.171975,
      long: 17.167067,
      warning: false,
    },
    {
      vehicleEventId: 5,
      containerId: 103,
      locationId: 203,
      sector: 26,
      date: "2023-09-04T11:55:17.000Z",
      location: "Martin Horváth, Bielkova 17",
      containerType: 1,
      weight: 21,
      wasteType: 6,
      rfid: "****17",
      lat: 48.172224,
      long: 17.16753,
      warning: false,
    },
    {
      vehicleEventId: 6,
      containerId: 112,
      locationId: 212,
      sector: 26,
      date: "2023-09-05T18:10:47.000Z",
      location: "Jana Horváthová, Rádiová 26",
      containerType: 1,
      weight: 8,
      wasteType: 6,
      rfid: "****07",
      lat: 48.172821,
      long: 17.167648,
      warning: false,
    },
    {
      vehicleEventId: 7,
      containerId: 104,
      locationId: 204,
      sector: 26,
      date: "2023-09-05T18:10:47.000Z",
      location: "Elena Krajčírová, Rádiová 34",
      containerType: 1,
      weight: 7,
      wasteType: 6,
      rfid: "****03",
      lat: 48.173179,
      long: 17.167222,
      warning: false,
    },
    {
      vehicleEventId: 8,
      containerId: 113,
      locationId: 213,
      sector: 26,
      date: "2023-09-06T12:30:14.000Z",
      location: "Peter Krajčír, Rádiová 32",
      containerType: 1,
      weight: 16,
      wasteType: 6,
      rfid: "****13",
      lat: 48.173102,
      long: 17.167343,
      warning: false,
    },
    {
      vehicleEventId: 3,
      containerId: 102,
      locationId: 202,
      sector: 26,
      date: "2023-09-03T14:20:31.000Z",
      location: "Jana Kováč, Železná 5",
      containerType: 1,
      weight: 9,
      wasteType: 6,
      rfid: "****09",
      lat: 48.172325,
      long: 17.166109,
      warning: false,
    },
    {
      vehicleEventId: 9,
      containerId: 110,
      locationId: 210,
      sector: 26,
      date: "2023-09-06T12:30:14.000Z",
      location: "Eva Nováková, Železná 25",
      containerType: 1,
      weight: 12,
      wasteType: 6,
      rfid: "****19",
      lat: 48.172795,
      long: 17.166982,
      warning: false,
    },
    {
      vehicleEventId: 10,
      containerId: 114,
      locationId: 214,
      sector: 26,
      date: "2023-09-07T07:15:59.000Z",
      location: "Mária Tomášová, Rádiová 28",
      containerType: 1,
      weight: 18,
      wasteType: 6,
      rfid: "****27",
      lat: 48.173011,
      long: 17.167474,
      warning: false,
    },
    {
      vehicleEventId: 12,
      containerId: 107,
      locationId: 207,
      sector: 26,
      date: "2023-09-08T16:40:28.000Z",
      location: "Ivan Sládek, Rádiová 22",
      containerType: 1,
      weight: 14,
      wasteType: 6,
      rfid: "****14",
      lat: 48.17266,
      long: 17.167815,

      warning: false,
    },
    {
      vehicleEventId: 13,
      containerId: 108,
      locationId: 208,
      sector: 26,
      date: "2023-09-09T10:05:36.000Z",
      location: "Zuzana Kmeťová, Bielkova 25",
      containerType: 1,
      weight: 5,
      wasteType: 6,
      rfid: "****22",
      lat: 48.172467,
      long: 17.167949,
      warning: false,
    },

    {
      vehicleEventId: 15,
      containerId: 115,
      locationId: 215,
      sector: 26,
      date: "2023-09-10T13:50:42.000Z",
      location: "Jozef Hruška, Rádiová 14",
      containerType: 2,
      weight: 23,
      wasteType: 6,
      rfid: "****16",
      lat: 48.172385,
      long: 17.168177,
      warning: false,
    },
    {
      vehicleEventId: 16,
      containerId: 116,
      locationId: 216,
      sector: 26,
      date: "2023-09-11T09:30:58.000Z",
      location: "Mária Kmeťová, Bulharská 97",
      containerType: 1,
      weight: 11,
      wasteType: 6,
      rfid: "****10",
      lat: 48.171755,
      long: 17.168359,
      warning: false,
    },
    {
      vehicleEventId: 17,
      containerId: 117,
      locationId: 217,
      sector: 26,
      date: "2023-09-12T16:15:25.000Z",
      location: "Peter Novotný, Bulharská 95",
      containerType: 1,
      weight: 27,
      wasteType: 6,
      rfid: "****23",
      lat: 48.171689,
      long: 17.168211,
      warning: false,
    },
    {
      vehicleEventId: 18,
      containerId: 118,
      locationId: 218,
      sector: 26,
      date: "2023-09-13T08:55:39.000Z",
      location: "Eva Kováčová, Bulharská 89",
      containerType: 1,
      weight: 13,
      wasteType: 6,
      rfid: "****15",
      lat: 48.171585,
      long: 17.168023,
      warning: false,
    },
    {
      vehicleEventId: 19,
      containerId: 119,
      locationId: 219,
      sector: 26,
      date: "2023-09-14T12:00:05.000Z",
      location: "Ján Horváth, Bulharská 87",
      containerType: 1,
      weight: 19,
      wasteType: 6,
      rfid: "****11",
      lat: 48.171547,
      long: 17.167943,
      warning: false,
    },
    {
      vehicleEventId: 20,
      containerId: 120,
      locationId: 220,
      sector: 26,
      date: "2023-09-15T14:40:52.000Z",
      location: "Zuzana Krajčírová, Bulharská 83",
      containerType: 2,
      weight: 6,
      wasteType: 6,
      rfid: "****20",
      lat: 48.171429,
      long: 17.167762,
      warning: false,
    },
  ],
};
