import { RootState } from "../store";
import { MayorModuleStatus } from "../types/mayorModules";
import { IMayorModulesState } from "../types/state";

export const getMayorModulesStatus = (state: RootState): IMayorModulesState["status"] => state.mayorModules.status;

export const getMayorModules = (state: RootState): IMayorModulesState["mayorModules"] =>
  state.mayorModules.mayorModules;

export const getIsSeparating = (state: RootState): IMayorModulesState["isSeparating"] =>
  state.mayorModules.isSeparating;

export const getDoesWeigh = (state: RootState): IMayorModulesState["doesWeigh"] => state.mayorModules.doesWeigh;

export const isWagonsModuleInactive = (state: RootState): boolean =>
  state.mayorModules.mayorModules.WagonModule === MayorModuleStatus.inactive;
