import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { WAGONS_CALENDAR_PAGE_PATH } from "../../middleware/routes";
import { MayorModulesType } from "../../types/mayorModules";
import { WagonEventDetailForm } from "./WagonEventDetailForm";

export const WagonEventDetailPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer
      backOption={true}
      backPath={WAGONS_CALENDAR_PAGE_PATH}
      fullHeight={false}
      headerText={t("wagonEventDetail")}
      module={MayorModulesType.WagonCalendarModule}
    >
      <WagonEventDetailForm />
    </PageContainer>
  );
};
