import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "../../../../../../components/Checkbox";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsSummaryDefault } from "../../../../../../selectors/wagonsReport";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { EnumName } from "../../../../../../utils/enums";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const ContainerType: React.FC<Props> = ({ fieldName }) => {
  const dispatch = useDispatch();
  const options = useSelector(getWagonsSummaryDefault).uniqueContainers.map((item) => item.containerType);
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  const value = [...(useSelector((state: RootState) => getWagonsReportFilterValue(state, fieldName))?.value ?? [])];

  return (
    <>
      {options.map((option) => (
        <div key={option}>
          <Checkbox
            checked={value.includes(option)}
            onChange={() => {
              if (!value.includes(option)) {
                value.push(option);
              } else {
                value.splice(value.indexOf(option), 1);
              }
              dispatch(
                setFilter({ id: fieldName, columnField: fieldName, operatorValue: "isAnyOf", value: [...value] })
              );
            }}
          />
          {getTranslatedEnumResourceCode(EnumName.ContainerTypes, option)}
        </div>
      ))}
    </>
  );
};
