import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { WAGONS_CALENDAR_PAGE_PATH } from "../../middleware/routes";
import { MayorModulesType } from "../../types/mayorModules";
import { WagonEventForm } from "./WagonEventForm";

export const WagonEventCreatePage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer
      backOption={true}
      backPath={WAGONS_CALENDAR_PAGE_PATH}
      fullHeight={false}
      headerText={t("newWagon")}
      module={MayorModulesType.WagonCalendarModule}
    >
      <WagonEventForm />
    </PageContainer>
  );
};
