import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

import { useGetTranslatedEnumResourceCodeGetter } from "../../../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { setInventorySheetFilter } from "../../../../../reducers/inventorySheetReducer";
import {
  getInventorySheetFilterOptionsForContainerTypes,
  getInventorySheetFilterValue,
} from "../../../../../selectors/inventorySheet";
import { RootState } from "../../../../../store";
import { EnumName } from "../../../../../utils/enums";
import { ColumnType } from "../../InventorySheetTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const ContainerTypeFilter: React.FC<Props> = ({ fieldName }) => {
  const dispatch = useDispatch();
  const options = useSelector(getInventorySheetFilterOptionsForContainerTypes);

  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  const value = [
    ...((useSelector((state: RootState) => getInventorySheetFilterValue(state, fieldName))?.value as number[]) ?? []),
  ];

  return (
    <>
      {options.map((option) => (
        <div key={option}>
          <Checkbox
            checked={value.includes(option)}
            onChange={() => {
              if (!value.includes(option)) {
                value.push(option);
              } else {
                value.splice(value.indexOf(option), 1);
              }
              dispatch(
                setInventorySheetFilter({ id: fieldName, name: fieldName, operator: "isAnyOf", value: [...value] })
              );
            }}
          />
          {getTranslatedEnumResourceCode(EnumName.ContainerTypes, option)}
        </div>
      ))}
    </>
  );
};
