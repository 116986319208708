import { useTranslation } from "react-i18next";

import { CustomButton, IButtonVariant } from "../../../components/CustomButton";
import { SUPPORT_EMAIL } from "../../../middleware/routes";

import styles from "./RightSegment.module.scss";

export const RightSegment: React.FC = () => {
  const { t } = useTranslation();

  const handleMailTo: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  return (
    <div className={styles.sidepanel}>
      <div className={styles.text}>
        <h3 className={styles.title}>{t("howToRegisterTitle")}</h3>
        <p className={styles.description}>{t("howToRegisterContent")}</p>
      </div>
      <CustomButton text={t("writeEmail")} variant={IButtonVariant.transparent} onClick={handleMailTo} />
    </div>
  );
};
