import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { Graphs } from "./Graphs";
import { HomePageHeaderComponent } from "./HomePageHeaderComponent";

export const HomePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer headerText={t("summary")} HeaderComponent={HomePageHeaderComponent}>
      <Graphs />
    </PageContainer>
  );
};
