import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./TableCellWithRightArrow.module.scss";

interface ITableCellWithRightArrowProps {
  mainComponent: React.ReactNode;
}

export const TableCellWithRightArrow: React.FC<ITableCellWithRightArrowProps> = ({ mainComponent }) => {
  return (
    <div className={styles.container}>
      {mainComponent}
      <Icon name={IconNameEnum.chevronRight} type={IconTypeEnum.default} />
    </div>
  );
};
