import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GridEventListener, GridEvents } from "@mui/x-data-grid-pro";

import { DETAIL_WAGON_EVENT_PAGE_PATH } from "../../../middleware/routes";
import {
  cleanUpWagonEventDetail,
  getWagonEventDetailThunk,
  setWagonEventDetail,
} from "../../../reducers/wagonEventDetailReducer";
import { getWagonsCalendarFilter } from "../../../selectors/wagonsCalendar";
import { WagonsFilter } from "../../../types/wagonEvent";

interface WagonsCalendarTableHandlers {
  handleAdditionalRowClick: GridEventListener<GridEvents.rowClick>;
}

export const useGetWagonsCalendarTableHandlers = (): WagonsCalendarTableHandlers => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appliedFilter = useSelector(getWagonsCalendarFilter);

  const handleAdditionalRowClick: GridEventListener<GridEvents.rowClick> = (params) => {
    if (appliedFilter !== WagonsFilter.past) {
      const wagonEventId = params.row.wasteCollectionCalendarId;
      dispatch(cleanUpWagonEventDetail());
      dispatch(setWagonEventDetail(params.row));
      dispatch(getWagonEventDetailThunk(wagonEventId));
      history.push(`${DETAIL_WAGON_EVENT_PAGE_PATH}?id=${wagonEventId}`);
    }
  };

  return { handleAdditionalRowClick };
};
