import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { switchView } from "../../../../reducers/wagonsReportStateReducer";
import {
  getWagonsMap,
  getWagonsReportIsIdle,
  getWagonsReportIsLoading,
  isMapViewActive,
} from "../../../../selectors/wagonsReport";

import styles from "./WagonsViewSwitch.module.scss";

enum State {
  map = "map",
  report = "report",
}

export const WagonsViewSwitch: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isActive = useSelector(isMapViewActive);
  const isReportLoading = useSelector(getWagonsReportIsLoading);
  const isReportIdle = useSelector(getWagonsReportIsIdle);
  const mapPoints = useSelector(getWagonsMap);

  const selectIcon = (ico: State) => {
    if (isReportLoading || isReportIdle || mapPoints.length === 0) return IconTypeEnum.grey;
    if (isActive && ico === State.report) return IconTypeEnum.green;
    if (!isActive && ico === State.map) return IconTypeEnum.green;
    return IconTypeEnum.white;
  };

  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        checked={isActive}
        onChange={() => dispatch(switchView())}
        disabled={isReportLoading || isReportIdle || mapPoints.length === 0}
      />
      <div className={styles.slider} />
      <div className={styles.report}>
        <Icon name={IconNameEnum.report} type={selectIcon(State.report)} /> {t(`wagonsReport.${State.report}`)}
      </div>
      <div className={styles.map}>
        <Icon name={IconNameEnum.location} type={selectIcon(State.map)} />
        {t(`wagonsReport.${State.map}`)}
      </div>
    </label>
  );
};
