import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";

import { ISelectOption } from "../../types/select";
import { wasteTypeToBgColor } from "../../utils/enumMapping";
import { WasteType } from "../../utils/enums";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { wasteTypesOptionsArray } from "../../utils/selectOptions";
import { SelectComponent } from "./SelectComponent";

import styles from "./SelectComponent.module.scss";

interface IWasteTypeProps {
  customCssClass?: string;
  value: ISelectOption["value"];
  handleChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  disabled?: boolean;
}

export const WasteTypeSelect: React.FC<IWasteTypeProps> = ({
  value,
  customCssClass = "",
  handleChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <SelectComponent
      customCssClass={customCssClass}
      handleChange={handleChange}
      value={value}
      disabled={disabled}
      optionsArray={wasteTypesOptionsArray}
      renderOption={(item) => {
        return (
          <div className={styles.flexContainer}>
            <div
              className={getModuleClassNames([styles.circle, styles[item.value]])}
              style={{ backgroundColor: wasteTypeToBgColor.get(item.value as WasteType) }}
            ></div>
            {t(item.label)}
          </div>
        );
      }}
    />
  );
};
