import { IColumnAlignObject, IColumnValuePair } from "../../types/table";

interface TableColumnUtils {
  getAlign: <LabelNameType>(
    label: LabelNameType,
    columnAlignObject: IColumnAlignObject<LabelNameType>
  ) => keyof IColumnAlignObject<LabelNameType> | undefined;
  getFlex: <LabelNameType>(
    label: LabelNameType,
    valuePairsArray: IColumnValuePair<LabelNameType>[]
  ) => number | undefined;
  getMinWidth: <LabelNameType>(
    label: LabelNameType,
    valuePairsArray: IColumnValuePair<LabelNameType>[]
  ) => number | undefined;
}

export const getTableColumnUtils = (): TableColumnUtils => {
  const getAlign = <LabelNameType>(label: LabelNameType, columnAlignObject: IColumnAlignObject<LabelNameType>) => {
    let key: keyof IColumnAlignObject<LabelNameType>;

    for (key in columnAlignObject) {
      const arr = columnAlignObject[key];

      if (!arr) {
        continue;
      }

      const thisItem = arr.find((item) => item === label);

      if (thisItem) {
        return key;
      }
    }

    return;
  };

  const getFlex = <LabelNameType>(label: LabelNameType, valuePairsArray: IColumnValuePair<LabelNameType>[]) => {
    const thisItem = valuePairsArray.find(({ name }) => name === label);
    if (thisItem) {
      return thisItem.value;
    }
    return 1;
  };

  const getMinWidth = <LabelNameType>(label: LabelNameType, valuePairsArray: IColumnValuePair<LabelNameType>[]) => {
    const thisItem = valuePairsArray.find(({ name }) => name === label);
    if (thisItem) {
      return thisItem.value;
    }
    return undefined;
  };

  return {
    getAlign,
    getFlex,
    getMinWidth,
  };
};
