import { IconButton, InputAdornment, TextField } from "@mui/material";
import classNames from "classnames";

import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./TextInput.module.scss";

export enum TextInputIconPosition {
  start = "start",
  end = "end",
}

export enum TextInputSize {
  default = "default",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export enum FixedTextInputSize {
  fixedSm = "fixedSm",
  fixedMd = "fixedMd",
  fixedLg = "fixedLg",
  fixedXl = "fixedXl",
  fixedXxl = "fixedXxl",
}

export enum TextInputVariant {
  primary = "primary",
  secondary = "secondary",
}

export interface ITextInputProps {
  error?: boolean;
  helperText?: string | null;
  required?: boolean;
  label?: string;
  name?: string;
  type?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onSearch?: React.MouseEventHandler<HTMLButtonElement>;
  autoComplete?: string;
  icon?: IconNameEnum;
  placeHolder?: string;
  iconPosition?: TextInputIconPosition;
  iconType?: IconTypeEnum;
  iconSize?: number;
  size?: TextInputSize;
  fixedSize?: FixedTextInputSize;
  variant?: TextInputVariant;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  customCssClass?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  maxLength?: number;
  fullWidth?: boolean;
  isDisabled?: boolean;
  startItem?: React.ReactNode;
  endItem?: React.ReactNode;
}

export const TextInput: React.FC<ITextInputProps> = ({
  error,
  helperText,
  required,
  label,
  name,
  type,
  value,
  onChange,
  onKeyDown,
  onSearch,
  autoComplete,
  icon,
  placeHolder,
  iconPosition = TextInputIconPosition.start,
  iconType = IconTypeEnum.grey,
  iconSize = 14,
  size = TextInputSize.default,
  fixedSize,
  variant = TextInputVariant.primary,
  onClick,
  multiline,
  minRows,
  maxRows,
  maxLength,
  fullWidth = true,
  isDisabled = false,
  startItem,
  endItem,
}) => {
  const appliedStyles = [styles.textField, styles[size], classNames({ [styles.disabled]: isDisabled })];
  if (fixedSize) {
    appliedStyles.push(styles[fixedSize]);
  }

  const startAdornmentIcon = icon && iconPosition === TextInputIconPosition.start && (
    <InputAdornment position={iconPosition}>
      <Icon size={`${iconSize}px`} name={icon} type={iconType} />
    </InputAdornment>
  );

  const endAdornmentIcon = icon && iconPosition === TextInputIconPosition.end && (
    <InputAdornment position={iconPosition}>
      <IconButton onClick={onSearch}>
        <Icon size={`${iconSize}px`} name={icon} type={iconType} />
      </IconButton>
    </InputAdornment>
  );
  const startItemAdornment = startItem && (
    <InputAdornment position={TextInputIconPosition.start}>{startItem}</InputAdornment>
  );
  const endItemAdornment = endItem && <InputAdornment position={TextInputIconPosition.end}>{endItem}</InputAdornment>;

  return (
    <>
      {variant === TextInputVariant.primary && label && <label className={styles.label}>{label}</label>}
      <TextField
        className={getModuleClassNames(appliedStyles)}
        error={error}
        helperText={helperText}
        required={required}
        label={variant === TextInputVariant.secondary && label ? label : undefined}
        placeholder={placeHolder}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        variant="outlined"
        fullWidth={fullWidth}
        onClick={onClick}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        disabled={isDisabled}
        inputProps={{ maxLength: maxLength }}
        InputProps={{
          startAdornment: startItemAdornment ? startItemAdornment : startAdornmentIcon,
          endAdornment: endItemAdornment ? endItemAdornment : endAdornmentIcon,
        }}
      />
    </>
  );
};
