import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

import { ComplexPageHeaderTitle } from "../../../components/ComplexPageHeaderTitle";
import { FilterButton } from "../../../components/FilterButton";
import { MenuButton } from "../../../components/MenuButton";
import { SearchBar } from "../../../components/SearchBar";
import { YearSelect } from "../../../components/SelectComponent";
import {
  defaultSearch,
  exportCollectionPointsToXlsxThunk,
  setCollectionPointsListSearch,
} from "../../../reducers/collectionPointsReducer";
import { getCollectionPointsSearch } from "../../../selectors/collectionPoints";
import { getLocationSummary } from "../../../selectors/location";
import { getDoesWeigh } from "../../../selectors/mayorModules";
import { filterTypesArray } from "./filterTypesArray";

import styles from "./CollectionPointsPageHeaderComponent.module.scss";

export const CollectionPointsPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locationSummary = useSelector(getLocationSummary);
  const currentSearch = useSelector(getCollectionPointsSearch);
  const doesWeigh = useSelector(getDoesWeigh);
  const [search, setSearch] = useState(currentSearch.fulltext);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;

    if (!value && currentSearch.fulltext) {
      dispatch(setCollectionPointsListSearch(defaultSearch));
    }
    setSearch(value);
  };

  const handleSearch = (): void => {
    dispatch(setCollectionPointsListSearch({ fulltext: search }));
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const exportTable = () => {
    dispatch(exportCollectionPointsToXlsxThunk());
  };

  return (
    <div className={styles.pageHeader}>
      <ComplexPageHeaderTitle
        title="collectionPoints"
        boldText={String(locationSummary.householdsSum)}
        text="householdCount"
      />
      {doesWeigh && (
        <div className={styles.filtersContainer}>
          <span>Filter:</span>
          <div className={styles.filters}>
            {filterTypesArray.map((type) => (
              <FilterButton type={type} key={type} />
            ))}
          </div>
        </div>
      )}
      <div className={styles.inputs}>
        <YearSelect />
        <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} onKeyDown={handleKeyPress} />
        <MenuButton>
          <MenuItem onClick={exportTable}>{t("exportTable")}</MenuItem>
        </MenuButton>
      </div>
    </div>
  );
};
