import { ImageNameEnum } from "./ImageNameEnum";
import { ImageSuffixEnum } from "./ImageSuffixEnum";

interface IImageProps {
  suffix?: ImageSuffixEnum;
  name: ImageNameEnum;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
}

export const Image: React.FC<IImageProps> = ({
  name,
  suffix = ImageSuffixEnum.jpeg,
  alt = name,
  width = "100%",
  height = "100%",
  className,
}) => {
  return <img {...{ src: `${process.env.PUBLIC_URL}/img/${name}.${suffix}`, alt, width, height, className }} />;
};
