import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridFilterModel,
  GridRowClassNameParams,
  GridRowIdGetter,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { NoResultsOverlay } from "../NoResultsOverlay";
import { HandleAdditionalRowClickHandler, useGetDataTableHandlers } from "./useGetDataTableHandlers";

import styles from "./DataTable.module.scss";

interface DataTableProps {
  dataRows: any[];
  columns: GridColDef<any, any, any>[];
  scrollTop: number;
  setScrollTop: ActionCreatorWithPayload<number>;
  getRowId?: GridRowIdGetter<any>;
  handleAdditionalRowClick?: HandleAdditionalRowClickHandler;
  handleSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
  getCellClassName?: (params: GridCellParams<any, any, any>) => string;
  getRowClassName?: (params: GridRowClassNameParams<any>) => string;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
  onStateChange?: GridEventListener<"stateChange">;
}

export const DataTableStatic: React.FC<DataTableProps> = ({
  dataRows,
  columns,
  scrollTop,
  setScrollTop,
  handleAdditionalRowClick,
  getCellClassName,
  getRowId,
  getRowClassName,
  filterModel,
  sortModel,
  onStateChange,
}) => {
  const { handleRowClick, apiRef } = useGetDataTableHandlers(scrollTop, setScrollTop, handleAdditionalRowClick);

  const appliedContainerClassNamesArray = [styles.container];

  return (
    <div className={getModuleClassNames(appliedContainerClassNamesArray)}>
      <DataGridPro
        apiRef={apiRef}
        rows={dataRows}
        columns={columns}
        onColumnHeaderClick={(_params: GridColumnHeaderParams, e: MuiEvent<React.MouseEvent>) => {
          e.defaultMuiPrevented = true;
        }}
        disableColumnMenu
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: NoResultsOverlay,
          NoResultsOverlay,
        }}
        getRowId={getRowId}
        hideFooter
        getCellClassName={getCellClassName}
        disableSelectionOnClick
        getRowClassName={getRowClassName}
        filterModel={filterModel}
        sortModel={sortModel}
        onStateChange={onStateChange}
      />
    </div>
  );
};
