import { ReactNode } from "react";

import { ComplexPageHeaderTitle } from "../ComplexPageHeaderTitle";
import { BackButton } from "./BackButton";

interface HeaderBackButtonProps {
  title: string | ReactNode;
  subtitle?: string;
  backTo: string;
}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({ title, subtitle = "", backTo }) => {
  return (
    <BackButton to={backTo}>
      {subtitle ? <ComplexPageHeaderTitle title={title} text={subtitle} /> : <h2>{title}</h2>}
    </BackButton>
  );
};
