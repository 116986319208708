import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CustomButton } from "../../../components/CustomButton";
import { CustomModal } from "../../../components/CustomModal";
import { IconNameEnum } from "../../../components/Icon";
import { TextInput } from "../../../components/TextInput";
import { inviteToMobileAppThunk } from "../../../reducers/collectionPointReducer";
import { IModal } from "../../../types/modal";
import { getIsEmailValid } from "../../../utils/validators/getIsEmailValid";

import styles from "./InviteToMobileAppModal.module.scss";

type InviteToMobileAppModal = IModal;

export const InviteToMobileAppModal: React.FC<InviteToMobileAppModal> = ({ isModalVisible, toggleModalVisibility }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setEmail(event.target.value);
  };

  const handleInviteToMobileApp = () => {
    if (!getIsEmailValid(email)) {
      setError(t("validation.email"));
      return;
    }
    dispatch(inviteToMobileAppThunk(email));
    toggleModalVisibility();
  };

  const clearModalState = () => {
    setEmail("");
    setError("");
  };

  const handleCloseModal = () => {
    clearModalState();
    toggleModalVisibility();
  };

  return (
    <CustomModal
      isModalVisible={isModalVisible}
      toggleModalVisibility={handleCloseModal}
      title="enterEmailOfCollectionPoint"
      text="enterEmailDescriptionText"
    >
      <div className={styles.formContainer}>
        <TextInput
          placeHolder="jan.novotny56@seznam.cz"
          onChange={handleChange}
          value={email}
          error={!!error}
          helperText={error}
          icon={IconNameEnum.mail}
          iconSize={24}
        />
        <CustomButton text={t("sendInvitation")} onClick={handleInviteToMobileApp} />
      </div>
    </CustomModal>
  );
};
