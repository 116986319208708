import { IServerEnumObject } from "./enums";
import { ISelectOption } from "./select";
import { Status } from "./state";

export enum ApplicationMode {
  dark = "dark",
  light = "light",
}

export enum ApplicationBreakpoint {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  largeDesktop = "largeDesktop",
  extraLargeDesktop = "extraLargeDesktop",
}

export interface IApplicationState {
  applicationMode: ApplicationMode;
  applicationBreakpoint: ApplicationBreakpoint;
  sidePanelVisibility: boolean;
  loaderVisibility: boolean;
  applicationEnums: IServerEnumObject[];
  uploadFileStatus: Status;
  years: IYears;
}

export interface IYears {
  lastYears: ISelectOption[];
  selectedYear: ISelectOption;
}
