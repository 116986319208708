import { CSSProperties, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BubbleDataPoint, ChartData, Plugin, ScatterDataPoint, ScriptableContext } from "chart.js";
import { AnyObject } from "chart.js/types/basic";

import { getApplicationBreakpointState, getSelectedYear } from "../../../selectors/application";
import { getWasteTypesQuantitySummaries } from "../../../selectors/wasteTypesQuantity";
import { ApplicationBreakpoint } from "../../../types/application";
import { AllWasteTypes, monthsArray } from "../../../utils/enums";
import { capitalizeString } from "../../../utils/formatters/capitalizeString";

interface SummaryLineChartProperties {
  data: ChartData<"line", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;
  plugins: Plugin<"line", AnyObject>[];
  options: any;
  chartRef: any;
  tooltipData: TooltipData;
}

export interface TooltipData {
  top: number;
  left: number;
  title: string;
  text: string;
  opacity: CSSProperties["opacity"];
}

export const useGetSummaryLineChartProperties = (): SummaryLineChartProperties => {
  const { t } = useTranslation();
  const selectedYear = useSelector(getSelectedYear);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);

  const translatedLabels = monthsArray.map((month) => {
    const translation = t(`enums.months.${month}`);
    const capitalizedTranslation = capitalizeString(translation);

    return capitalizedTranslation;
  });
  const labels: string[] = translatedLabels.map((fullLabel) => fullLabel.slice(0, 3));

  const wasteTypeSummaries = useSelector(getWasteTypesQuantitySummaries);
  const totalWaste = wasteTypeSummaries.filter((obj) => obj.wasteType === AllWasteTypes.totalWaste)[0];
  const recycledObj = wasteTypeSummaries.filter((obj) => obj.wasteType === AllWasteTypes.recycled)[0];

  const degreesOfSeparation: number[] = monthsArray.map((rec) => {
    const degreeOfSeparation =
      totalWaste[rec] === 0 ? 0 : Number(((recycledObj[rec] / totalWaste[rec]) * 100).toFixed(0));

    return degreeOfSeparation;
  });
  const degreesOfSeparationColor = "#46AF1A";

  const chartRef = useRef<any>(null);

  const defaultTooltipData = {
    top: 0,
    left: 0,
    title: "",
    text: "",
    opacity: 0,
  };
  const [tooltipData, setTooltipData] = useState<TooltipData>(defaultTooltipData);

  const getCustomTooltip = (context: any): void => {
    if (context.replay || !chartRef || !chartRef.current) {
      return;
    }

    if (context.tooltip.opacity === 0 && tooltipData.opacity !== 0) {
      setTooltipData((prev) => ({ ...prev, opacity: 0 }));
      return;
    }

    const left: number = context.tooltip.caretX;
    const top: number = context.tooltip.caretY;
    const value: number = context.tooltip.dataPoints[0].raw;
    const dataPointLabel: string = context.tooltip.dataPoints[0].label;
    const separationLabel = t("separation").toLowerCase();
    const title = `${value}% ${separationLabel}`;
    const label = translatedLabels.find((translatedLabel) => translatedLabel.startsWith(dataPointLabel)) ?? "";
    const text = `${label}, ${selectedYear.label}`;
    const newData = { top, left, title, text, opacity: 1 };

    setTooltipData(newData);
  };

  const data: ChartData<"line", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> = {
    //   labels to be added based on the waste types
    labels,
    datasets: [
      {
        label: t("degreeOfSeparation"),
        data: degreesOfSeparation,
        fill: true,
        borderColor: degreesOfSeparationColor,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const height = context.chart.height;
          const gradientBg = ctx.createLinearGradient(0, 0, 0, height);
          gradientBg.addColorStop(0, "rgba(100, 212, 53, 0.5)");
          gradientBg.addColorStop(0.8, "rgba(255,255,255,1)");
          return gradientBg;
        },
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointHoverBackgroundColor: "white",
        pointHoverBorderColor: degreesOfSeparationColor,
        pointHoverBorderWidth: 2,
        pointHoverRadius: 4.5,
        pointHitRadius: 0,
      },
    ],
  };

  const getAppliedResizeProperties = () => {
    let scalesFontSize = 16;

    if (
      applicationBreakpoint !== ApplicationBreakpoint.largeDesktop &&
      applicationBreakpoint !== ApplicationBreakpoint.extraLargeDesktop
    ) {
      scalesFontSize = 14;
    }

    return { scalesFontSize };
  };

  const { scalesFontSize } = getAppliedResizeProperties();
  const scalesColor = "#88A6D1";

  const options: any = {
    aspectRatio: 2.5,
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "x",
    },
    animation: {
      duration: 0,
    },
    elements: {
      point: { radius: 20, pointStyle: "circle", borderColor: "rgba(0,0,0,1)", backgroundColor: "rgba(255,255,255,1)" },
    },
    tension: 0.4,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 70,
        ticks: {
          stepSize: 20,
          callback(value: number) {
            return `${value} %`;
          },
          color: scalesColor,
          font: {
            size: scalesFontSize,
            family: "Sora, sans-serif",
          },
          padding: 15,
        },
      },
      x: {
        ticks: {
          color: scalesColor,
          font: {
            size: scalesFontSize,
            family: "Sora, sans-serif",
          },
          padding: 28,
        },
        grid: { display: false },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: getCustomTooltip,
        display: false,
      },
    },
  };

  const plugins: Plugin<"line", AnyObject>[] = [
    {
      id: "lineChart",
      afterDraw: (chart: any): void => {
        const ctx = chart.ctx;

        if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
          ctx.save();
          const activePoint = chart.tooltip._active[0];
          ctx.beginPath();
          ctx.setLineDash([5, 7]);
          ctx.moveTo(activePoint.element.x, chart.chartArea.bottom);
          ctx.lineTo(activePoint.element.x, activePoint.element.y);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "rgba(160,221,135,255)";
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  const summaryLineChartProperties = { data, plugins, options, chartRef, tooltipData };
  return summaryLineChartProperties;
};
