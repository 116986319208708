import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { getWeighValue } from "../../utils/collectionPoints/getWeighValue";

interface ITableWeightCellProps {
  params: GridRenderCellParams<number>;
}

export const TableWeightCell: React.FC<ITableWeightCellProps> = ({ params }) => {
  const weight = params.value ? Math.round(params.value * 100) / 100 : 0;
  const weightDisplay = getWeighValue(true, weight, "kg");

  return <p>{weightDisplay}</p>;
};
