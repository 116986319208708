import { RootState } from "../store";
import { Status } from "../types/state";
import { IWeightHistoryState } from "../types/weightHistory";

export const getWeightHistory = (state: RootState): IWeightHistoryState => state.weightHistory;
export const getWeightHistoryStatus = (state: RootState): IWeightHistoryState["status"] => state.weightHistory.status;
export const getIsWeightHistoryStatusLoading = (state: RootState): boolean =>
  state.weightHistory.status !== Status.success;

export const getWeightHistoryStatusErrorMsg = (state: RootState): IWeightHistoryState["errorMsg"] =>
  state.weightHistory.errorMsg;

export const getWeightHistoryDetail = (state: RootState): IWeightHistoryState["weightHistory"] =>
  state.weightHistory.weightHistory;
export const getWeightHistoryContainer = (state: RootState): IWeightHistoryState["weightHistory"]["container"] =>
  state.weightHistory.weightHistory.container;

export const getWeightHistoryList = (state: RootState): IWeightHistoryState["weightHistory"]["dumpings"] =>
  state.weightHistory.weightHistory.dumpings;

export const getWeightHistoryPagination = (state: RootState): IWeightHistoryState["pagination"] =>
  state.weightHistory.pagination;
export const getWeightHistoryOrdering = (state: RootState): IWeightHistoryState["ordering"] =>
  state.weightHistory.ordering;

export const getWeightHistoryDetailScrollTop = (state: RootState): IWeightHistoryState["scrollTop"] =>
  state.weightHistory.scrollTop;
