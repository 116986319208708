import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_COLLECTION_POINTS_LIST_URL, UPDATE_IS_PAID_STATUS_URL } from "../middleware/routes";
import { RootState } from "../store";
import {
  InvitiationStatus,
  IUpdateIsPaidStatusRequest,
  IUpdateLocationIsPaidStatusResponse,
  PaymentStatus,
} from "../types/collectionPoint";
import { FilterType, IFilterObject } from "../types/filter";
import { ICollectionPointLocation } from "../types/location";
import { IOrdering, IPagination } from "../types/pagination";
import { ISearch } from "../types/search";
import { DegreeOfSeparationFilterEnum, ICollectionPointsState, Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getAppliedFilterEnum } from "../utils/collectionPoints/getAppliedFilterEnum";
import { downloadFile } from "../utils/download";
import { formatLocationDataForXlsx } from "../utils/formatData";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { createXlsxFile } from "../utils/xlsx";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "Name",
  descending: false,
};

export const defaultSearch = {
  fulltext: "",
};

export const defaultFilterObject = {
  recyclingUnsuccessful: false,
  recyclingModerate: false,
  recyclingSuccessful: false,
};

export const defaultFilter = {
  filterObject: defaultFilterObject,
  filterEnum: DegreeOfSeparationFilterEnum.All,
};

const defaultLocation: ICollectionPointLocation = {
  locationId: 0,
  address: "",
  containerCount: 0,
  degreeOfSeparation: 0,
  householdMembersCount: 0,
  invitationStatus: InvitiationStatus.NotInvited,
  isPaid: PaymentStatus.Unpaid,
  name: "",
  totalDumpings: 0,
  totalTKO: 0,
  wastePerHouseholdMember: 0,
  wcpid: "",
  disableTime: null,
  enableTime: null,
};
const defaultLocations: ICollectionPointLocation[] = getSkeletonMockedArray(defaultLocation, 50, 0);

const initCollectionPointsState: ICollectionPointsState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  locations: defaultLocations,
  isNextPageEnable: false,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  filter: defaultFilter,
  search: defaultSearch,
  scrollTop: 0,
};

export const collectionPointsSlice = createSlice({
  name: "collectionPoints",
  initialState: initCollectionPointsState,
  reducers: {
    cleanUpCollectionPointsList: (state: ICollectionPointsState) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.locations = defaultLocations;
      state.isNextPageEnable = false;
    },
    replaceCollectionPoint: (state: ICollectionPointsState, action: PayloadAction<ICollectionPointLocation>) => {
      const collectionPoints = [...state.locations];
      const newLocations = collectionPoints.map((point) => {
        if (point.locationId === action.payload.locationId) {
          return action.payload;
        }
        return point;
      });

      state.locations = newLocations;
    },
    setCollectionPointsListPagination: (state: ICollectionPointsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setCollectionPointsListOrdering: (state: ICollectionPointsState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setCollectionPointsListSearch: (state: ICollectionPointsState, action: PayloadAction<ISearch>) => {
      state.search = action.payload;
    },
    setCollectionPointsListFilter: (state: ICollectionPointsState, action: PayloadAction<FilterType>) => {
      const newFilter: IFilterObject = {
        ...state.filter.filterObject,
        [action.payload]: !state.filter.filterObject[action.payload],
      };

      const appliedFilterEnum = getAppliedFilterEnum(newFilter);

      state.filter.filterObject = newFilter;
      state.filter.filterEnum = appliedFilterEnum;
    },
    setCollectionPointsListScrollTop: (state: ICollectionPointsState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetCollectionPointsState: () => {
      return initCollectionPointsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionPointsListThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedLocations = getSkeletonMockedArray(defaultLocation, 3, state.locations.length);
          state.locations = [...state.locations, ...mockedLocations];
        }
      })
      .addCase(getCollectionPointsListThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }
        const locationsFromState: ICollectionPointLocation[] = state.locations;
        const locationsFromResponse: ICollectionPointLocation[] = action.payload;
        const newLocations = getSkeletonSuccessArray(locationsFromState, locationsFromResponse);

        state.locations = newLocations;

        let isNextPageEnable = true;
        if (action.payload.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getCollectionPointsListThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })

      .addCase(updateLocationIsPaidStatusThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(updateLocationIsPaidStatusThunk.fulfilled, (state) => {
        state.status = Status.success;
        state.errorMsg = "";
      })
      .addCase(updateLocationIsPaidStatusThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export interface ICollectionPointsListRequest {
  search: ISearch;
  pagination: IPagination;
  year: number;
  ordering: IOrdering;
  degreeOfSeparation: DegreeOfSeparationFilterEnum;
}

export const getCollectionPointsListThunk = createAsyncThunk<
  any,
  ICollectionPointsListRequest | undefined,
  { state: RootState }
>("collectionPoints/getCollectionPointsListThunk", async (collectionPointsRequest, { getState }) => {
  const state = getState();
  const isSeparating = state.mayorModules.isSeparating;

  let requestBody = {
    fulltext: defaultSearch.fulltext,
    year: state.application.years.selectedYear.value,
    pagination: defaultPagination,
    ordering: defaultOrdering,
    degreeOfSeparation: DegreeOfSeparationFilterEnum.All,
    isSeparating,
  };

  if (collectionPointsRequest) {
    const { search, pagination, year, ordering, degreeOfSeparation } = collectionPointsRequest;
    requestBody = { ...requestBody, fulltext: search.fulltext, year, pagination, ordering, degreeOfSeparation };
  }

  const response = await axiosRequestApi.post(GET_COLLECTION_POINTS_LIST_URL, requestBody);

  return response.data;
});
export const exportCollectionPointsToXlsxThunk = createAsyncThunk<
  any,
  ICollectionPointsListRequest | undefined,
  { state: RootState }
>("collectionPoints/exportCollectionPointsToXlsxThunk", async (_, { getState }) => {
  const state = getState();
  const isSeparating = state.mayorModules.isSeparating;

  const requestBody = {
    fulltext: defaultSearch.fulltext,
    year: state.application.years.selectedYear.value,
    pagination: {
      limit: 100_000,
      page: 1,
    },
    ordering: defaultOrdering,
    degreeOfSeparation: DegreeOfSeparationFilterEnum.All,
    isSeparating,
  };

  const { data } = await axiosRequestApi.post(GET_COLLECTION_POINTS_LIST_URL, requestBody);

  const formattedData = formatLocationDataForXlsx(data);
  const file = createXlsxFile(formattedData);
  downloadFile(file, "collection-points.xlsx");
});
export const updateLocationIsPaidStatusThunk = createAsyncThunk<
  IUpdateLocationIsPaidStatusResponse,
  IUpdateIsPaidStatusRequest
>("collectionPoints/updateLocationIsPaidStatusThunk", async (updateIsPaidStatusRequest: IUpdateIsPaidStatusRequest) => {
  const response = await axiosRequestApi.post(UPDATE_IS_PAID_STATUS_URL, updateIsPaidStatusRequest);
  return response.data;
});

export const {
  cleanUpCollectionPointsList,
  setCollectionPointsListPagination,
  setCollectionPointsListOrdering,
  setCollectionPointsListSearch,
  setCollectionPointsListFilter,
  setCollectionPointsListScrollTop,
  replaceCollectionPoint,
  resetCollectionPointsState,
} = collectionPointsSlice.actions;

export const collectionPointsReducer = collectionPointsSlice.reducer;
