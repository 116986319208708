import { resetAuthState } from "../../reducers/authReducer";
import { startAppListening } from "./listenerMiddleware";
import { logoutUser } from "./utils/logoutUser";

export const logoutListener = () => {
  startAppListening({
    actionCreator: resetAuthState,
    effect: async (_, { dispatch }) => {
      logoutUser(dispatch);
    },
  });
};
