import { ICollectionPointsListRequest } from "../../../reducers/collectionPointsReducer";

export const getCollectionPointsListThunkRequestObject = (getState: () => any) => {
  const state = getState();
  const selectedYearValue = Number(state.application.years.selectedYear.value);
  const { pagination, search, ordering, filter } = state.collectionPoints;

  const requestObject: ICollectionPointsListRequest = {
    search,
    pagination,
    year: selectedYearValue,
    ordering: ordering,
    degreeOfSeparation: filter.filterEnum,
  };

  return requestObject;
};
