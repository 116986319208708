import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

import { setCollectionYardsFilter } from "../../../../../reducers/collectionYardsReducer";
import { getCollectionYardsFilterValueCached } from "../../../../../selectors/collectionYards";
import { RootState } from "../../../../../store";
import { ColumnType } from "../../CollectionYardsTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const BooleanFilter: React.FC<Props> = ({ fieldName }) => {
  const value = useSelector((state: RootState) => getCollectionYardsFilterValueCached(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Checkbox
        checked={!!value?.value}
        onChange={(e) => {
          dispatch(
            setCollectionYardsFilter({ id: fieldName, name: fieldName, operator: "isEqualTo", value: e.target.checked })
          );
        }}
      />
      {t(`collectionYardsVisits.filters.${fieldName}`)}
    </div>
  );
};
