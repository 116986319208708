import { useDispatch, useSelector } from "react-redux";

import { setCollectionPointsListFilter } from "../../reducers/collectionPointsReducer";
import { getCollectionPointsFilter } from "../../selectors/collectionPoints";
import { getIsSeparating } from "../../selectors/mayorModules";
import { FilterType } from "../../types/filter";
import { getModuleClassNames } from "../../utils/getModuleClassNames";

import styles from "./FilterButton.module.scss";

type FilterButtonProps = {
  type: FilterType;
};

export const FilterButton: React.FC<FilterButtonProps> = ({ type }) => {
  const dispatch = useDispatch();
  const { filterObject } = useSelector(getCollectionPointsFilter);
  const isActive = filterObject[type];
  const isSeparating = useSelector(getIsSeparating);

  const appliedClassNamesArray = [styles.button, styles[FilterType[type]]];
  if (isActive) {
    appliedClassNamesArray.push(styles.active);
  }

  const handleClick = () => {
    dispatch(setCollectionPointsListFilter(type));
  };

  const getFilterButtonValue = (type: FilterType): string => {
    let filterButtonValue = "";

    switch (type) {
      case FilterType.recyclingUnsuccessful:
        filterButtonValue = "200 kg+";
        if (isSeparating) {
          filterButtonValue = "< 25 %";
        }

        break;

      case FilterType.recyclingModerate:
        filterButtonValue = "100-200 kg";
        if (isSeparating) {
          filterButtonValue = "25-50 %";
        }
        break;
      case FilterType.recyclingSuccessful:
        filterButtonValue = "<100 kg";
        if (isSeparating) {
          filterButtonValue = "> 50 %";
        }
        break;

      default:
        break;
    }
    return filterButtonValue;
  };

  const value = getFilterButtonValue(type);

  return (
    <button className={getModuleClassNames(appliedClassNamesArray)} onClick={handleClick}>
      {value}
    </button>
  );
};
