import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "../../../../../components/TextInput";
import { setInventorySheetFilter } from "../../../../../reducers/inventorySheetReducer";
import { getInventorySheetFilterValue } from "../../../../../selectors/inventorySheet";
import { RootState } from "../../../../../store";
import { ColumnType } from "../../InventorySheetTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const TextFilter: React.FC<Props> = ({ fieldName }) => {
  const value = useSelector((state: RootState) => getInventorySheetFilterValue(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        placeHolder={t(`inventoryList.${fieldName}`)}
        value={String(value?.value ?? "")}
        onChange={(e) => {
          dispatch(
            setInventorySheetFilter({
              id: fieldName,
              name: fieldName,
              operator: "contains",
              value: e.target.value,
            })
          );
        }}
      />
    </>
  );
};
