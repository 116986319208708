import { MouseEventHandler } from "react";

import { IconNameEnum } from "./IconNameEnum";
import { IconTypeEnum } from "./IconTypeEnum";

type IconProps = {
  name: IconNameEnum;
  type?: IconTypeEnum;
  size?: string;
  rotation?: string;
  style?: object;
  height?: string;
  onClick?: MouseEventHandler;
  className?: string;
};

export const Icon: React.FC<IconProps> = ({
  name,
  size,
  rotation,
  type = IconTypeEnum.default,
  style,
  height,
  onClick,
  className,
}) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/icons/${name}${type}.svg`}
      alt={name}
      width={size}
      style={{ ...style, transform: `rotate(${rotation})`, height }}
      onClick={onClick}
      className={className}
    />
  );
};
