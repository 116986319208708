import { WagonsReportSummary } from "./WagonsReportSummary";
import { WagonsReportTable } from "./WagonsReportTable";

import styles from "./WagonsReport.module.scss";

export const WagonsReport: React.FC = () => {
  return (
    <div className={styles.content}>
      <WagonsReportSummary />
      <WagonsReportTable />
    </div>
  );
};
