import { useTranslation } from "react-i18next";

import { useGetTranslatedEnumResourceCodeGetter } from "../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { EnumName } from "../../utils/enums";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./ContainerNoteBanner.module.scss";

interface ContainerNoteBannerProps {
  noteType: number | null;
}

export const ContainerNoteBanner = ({ noteType }: ContainerNoteBannerProps) => {
  const { t } = useTranslation();
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();

  if (!noteType) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          <Icon name={IconNameEnum.bin} type={IconTypeEnum.green} size="24px" />
          {t("note")}
        </h1>
        <span>{getTranslatedEnumResourceCode(EnumName.ContainerNoteTypes, noteType)}</span>
      </div>
    </div>
  );
};
