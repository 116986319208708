import { ReactFragment, ReactNode, useState } from "react";
import { ClickAwayListener, Tooltip as MuiTooltip } from "@mui/material";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  bgColor: string;
  content: ReactFragment;
  children?: ReactNode;
  tooltipState?: boolean;
  tooltipOpen?: () => void;
  tooltipClose?: () => void;
}

export const ClickableTooltip: React.FC<TooltipProps> = ({
  bgColor,
  content,
  children,
  tooltipOpen,
  tooltipClose,
  tooltipState,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={tooltipClose ?? handleTooltipClose}>
      <div className={tooltipState ?? open ? styles.open : ""}>
        <MuiTooltip
          className={styles.tooltip}
          style={{ backgroundColor: bgColor }}
          title={content}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={tooltipClose ?? handleTooltipClose}
          open={tooltipState ?? open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
        >
          {children ? (
            <div onClick={tooltipOpen ?? handleTooltipOpen}>{children}</div>
          ) : (
            <div onClick={tooltipOpen ?? handleTooltipOpen} className={styles.container}>
              i
            </div>
          )}
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  );
};
