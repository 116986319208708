export enum MenuItemLabelsEnum {
  collectionPoints = "collectionPoints",
  wagons = "wagons",
  salvageYard = "salvageYard",
  wagonsCalendar = "wagonsCalendar",
  communityState = "communityState",
  inventorySheet = "inventorySheet",
  collectionYards = "collectionYards",
  home = "home",
  helpdesk = "helpdesk",
  languageSettings = "languageSettings",
  logOut = "logOut",
  settings = "settings",
}
