import "dayjs/locale/cs";
import "dayjs/locale/hu";
import "dayjs/locale/sk";
import "dayjs/locale/de";
import "dayjs/locale/pl";

import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { IconNameEnum, IconTypeEnum } from "../Icon";
import { TextInput, TextInputIconPosition, TextInputSize } from "../TextInput";

interface IDatePickerProps {
  onChange: (newValue: Date) => void;
  value?: Date;
  error?: boolean;
  helperText?: string;
  size?: TextInputSize;
  placeHolder?: string;
  withIcon?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

// TODO: Make component more reusable
export const DatePicker: React.FC<IDatePickerProps> = ({
  onChange,
  value,
  error = false,
  helperText = "",
  size,
  placeHolder,
  withIcon,
  minDate,
  maxDate,
}) => {
  const { i18n } = useTranslation();
  const [date, setDate] = React.useState<Dayjs | null>(value ? dayjs(value) : placeHolder ? null : dayjs(new Date()));
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (newValue: Dayjs | null) => {
    setDate(newValue);
    onChange(newValue ? newValue.toDate() : new Date());
  };

  const handleOpen = (event: any) => {
    setOpen((open) => !open);
    setAnchorEl(event.currentTarget);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <DesktopDatePicker
        inputFormat="D.M.YYYY, dddd"
        value={value ? dayjs(value) : placeHolder ? null : date}
        onChange={handleChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        minDate={minDate}
        maxDate={maxDate}
        defaultCalendarMonth={minDate}
        renderInput={(params) => {
          if (params.inputProps) {
            return (
              <div>
                <TextInput
                  error={error}
                  helperText={helperText}
                  required={false}
                  name="date"
                  type="text"
                  autoComplete="off"
                  size={size}
                  onChange={params.inputProps.onChange}
                  value={params.inputProps.value}
                  placeHolder={placeHolder ? placeHolder : params.inputProps.placeHolder}
                  onClick={handleOpen}
                  icon={withIcon ? IconNameEnum.calendar : undefined}
                  iconType={IconTypeEnum.default}
                  iconSize={28}
                  iconPosition={TextInputIconPosition.end}
                />
              </div>
            );
          }
          return <TextField {...params} />;
        }}
        disableMaskedInput
        PopperProps={{
          placement: "bottom-end",
          anchorEl: anchorEl,
          sx: {
            "*": {
              fontFamily: "Sora, sans-serif",
            },
            ".Mui-selected": {
              backgroundColor: "#46af1a !important",
            },
            ".MuiPickersCalendarHeader-label": {
              color: "#46af1a !important",
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
