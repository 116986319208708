import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_INVENTORY_SHEET_LIST_URL, GET_INVENTORY_SHEET_SUMMARY_URL } from "../middleware/routes";
import { RootState } from "../store";
import { IFilterItem, IFilters } from "../types/filters";
import { IInventorySheetContainer, IInventorySheetState, IInventorySheetSummary } from "../types/inventorySheet";
import { IOrdering, IPagination } from "../types/pagination";
import { ISearch } from "../types/search";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { downloadFile } from "../utils/download";
import { formatInventorySheetDataForXlsx } from "../utils/formatData";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { createXlsxFile } from "../utils/xlsx";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "collectionPointName",
  descending: false,
};

export const defaultSearch = {
  fulltext: "",
};

const defaultContainer: IInventorySheetContainer = {
  id: 0,
  collectionPointName: "",
  street: "",
  streetNumber: "",
  wasteType: 0,
  containerType: 0,
  rfid: "",
  barcode: "",
  pointId: "",
  householdMembersCount: 0,
  locationId: 0,
  containerId: 0,
};
const defaultContainers: IInventorySheetContainer[] = getSkeletonMockedArray(defaultContainer, 50, 0);

const defaultSummary: IInventorySheetSummary = {
  collectionPoints: 0,
  containers: [],
  noRfid: 0,
};

const defaultFilters: IFilters = {
  items: [],
  linkOperator: "and",
};

const initInventorySheetState: IInventorySheetState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  containers: defaultContainers,
  isNextPageEnable: false,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  filters: defaultFilters,
  search: defaultSearch,
  scrollTop: 0,
  summary: defaultSummary,
  summaryDefault: defaultSummary,
};

export const inventorySheetSlice = createSlice({
  name: "inventorySheet",
  initialState: initInventorySheetState,
  reducers: {
    cleanUpInventorySheetList: (state: IInventorySheetState) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.containers = defaultContainers;
      state.isNextPageEnable = false;
    },
    replaceInventorySheet: (state: IInventorySheetState, action: PayloadAction<IInventorySheetContainer>) => {
      const containersPoints = [...state.containers];
      const newContainers = containersPoints.map((point) => {
        if (point.id === action.payload.id) {
          return action.payload;
        }
        return point;
      });

      state.containers = newContainers;
    },
    setInventorySheetListPagination: (state: IInventorySheetState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setInventorySheetListOrdering: (state: IInventorySheetState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setInventorySheetFilter: (state, action: PayloadAction<IFilterItem>) => {
      const findIdx = state.filters.items.findIndex((item) => item.id === action.payload.id);
      if (findIdx !== -1) {
        if (
          action.payload.operator === "isAnyOf" &&
          Array.isArray(action.payload?.value) &&
          action.payload.value.length === 0
        ) {
          state.filters.items.splice(findIdx, 1);
        } else {
          state.filters.items[findIdx] = action.payload;
        }
      } else state.filters.items.push(action.payload);
    },
    removeInventorySheetFilter: (state, action: PayloadAction<string>) => {
      state.filters.items = state.filters.items.filter((item) => !item.id?.toString().startsWith(action.payload));
    },
    setInventorySheetListSearch: (state: IInventorySheetState, action: PayloadAction<ISearch>) => {
      state.search = action.payload;
    },
    setInventorySheetListScrollTop: (state: IInventorySheetState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetInventorySheetState: () => {
      return initInventorySheetState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventorySheetListThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedContainers = getSkeletonMockedArray(defaultContainers, 3, state.containers.length);
          state.containers = [...state.containers, ...mockedContainers];
        }
      })
      .addCase(getInventorySheetListThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        const containersFromState: IInventorySheetContainer[] = state.containers;
        const containersFromResponse: IInventorySheetContainer[] = action.payload;
        const newLocations = getSkeletonSuccessArray(containersFromState, containersFromResponse);

        state.containers = newLocations;

        let isNextPageEnable = true;
        if (action.payload.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getInventorySheetListThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })
      .addCase(getInventorySheetSummaryThunk.fulfilled, (state, action) => {
        state.summary = action.payload;
      })
      .addCase(getInventorySheetSummaryDefaultThunk.fulfilled, (state, action) => {
        state.summaryDefault = action.payload;
      });
  },
});

export interface IInventorySheetListRequest {
  search: ISearch;
  pagination: IPagination;
  ordering: IOrdering;
  filters: IFilterItem[];
}

export const mapInventorySheetRequestBody = (inventorySheetRequest?: IInventorySheetListRequest) => {
  let requestBody = {
    fulltext: defaultSearch.fulltext,
    pagination: defaultPagination,
    ordering: defaultOrdering,
    filters: defaultFilters.items,
  };

  if (inventorySheetRequest) {
    const { search, pagination, ordering, filters } = inventorySheetRequest;
    requestBody = {
      ...requestBody,
      fulltext: search.fulltext,
      pagination,
      ordering,
      filters,
    };
  }

  return requestBody;
};

export const getInventorySheetListThunk = createAsyncThunk<
  any,
  IInventorySheetListRequest | undefined,
  { state: RootState }
>("inventorySheet/getInventorySheetListThunk", async (inventorySheetRequest) => {
  const requestBody = mapInventorySheetRequestBody(inventorySheetRequest);

  const response = await axiosRequestApi.post(GET_INVENTORY_SHEET_LIST_URL, requestBody);
  return response.data;
});

export const getInventorySheetSummaryThunk = createAsyncThunk<
  any,
  IInventorySheetListRequest | undefined,
  { state: RootState }
>("inventorySheet/getInventorySheetSummaryThunk", async (inventorySheetRequest) => {
  const requestBody = mapInventorySheetRequestBody(inventorySheetRequest);

  const response = await axiosRequestApi.post(GET_INVENTORY_SHEET_SUMMARY_URL, requestBody);
  return response.data;
});

export const getInventorySheetSummaryDefaultThunk = createAsyncThunk<
  any,
  IInventorySheetListRequest | undefined,
  { state: RootState }
>("inventorySheet/getInventorySheetSummaryDefaultThunk", async () => {
  const response = await axiosRequestApi.post(GET_INVENTORY_SHEET_SUMMARY_URL);
  return response.data;
});

export const exportInventorySheetToXlsxThunk = createAsyncThunk<
  any,
  IInventorySheetListRequest | undefined,
  { state: RootState }
>("collectionPoints/exportInventorySheetToXlsxThunk", async (_, { getState }) => {
  const state = getState();

  const requestBody = {
    fulltext: state.inventorySheet.search.fulltext ?? defaultSearch.fulltext,
    pagination: {
      limit: 100_000,
      page: 1,
    },
    ordering: state.inventorySheet.ordering ?? defaultOrdering,
    filters: state.inventorySheet.filters.items ?? defaultFilters.items,
  };

  const { data } = await axiosRequestApi.post(GET_INVENTORY_SHEET_LIST_URL, requestBody);

  const formattedData = formatInventorySheetDataForXlsx(data, state.application.applicationEnums);
  const file = createXlsxFile(formattedData);
  downloadFile(file, "inventory-list.xlsx");
});

export const {
  cleanUpInventorySheetList,
  setInventorySheetListPagination,
  setInventorySheetListOrdering,
  setInventorySheetListSearch,
  setInventorySheetListScrollTop,
  replaceInventorySheet,
  resetInventorySheetState,
  setInventorySheetFilter,
  removeInventorySheetFilter,
} = inventorySheetSlice.actions;

export const inventorySheetReducer = inventorySheetSlice.reducer;
