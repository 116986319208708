import { useSelector } from "react-redux";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { getSelectedYear } from "../../../selectors/application";
import { IContainer } from "../../../types/container";
import { isDisabled } from "../../../utils/time";

interface IsContainerActiveCellProps {
  params: GridRenderCellParams<IContainer["disableTime"]>;
}

export const IsContainerActiveCell: React.FC<IsContainerActiveCellProps> = ({ params }) => {
  const selectedYear = useSelector(getSelectedYear);
  const isActive = !isDisabled(params.row.disableTime, Number(selectedYear.value));

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {isActive ? (
        <Icon type={IconTypeEnum.green} name={IconNameEnum.check} size="14px" />
      ) : (
        <Icon type={IconTypeEnum.red} name={IconNameEnum.cross} size="14px" />
      )}
    </div>
  );
};
