import { DegreeOfSeparationFilterEnum } from "./state";

export enum FilterType {
  recyclingUnsuccessful = "recyclingUnsuccessful",
  recyclingModerate = "recyclingModerate",
  recyclingSuccessful = "recyclingSuccessful",
}

export interface IFilterObject {
  recyclingUnsuccessful: boolean;
  recyclingModerate: boolean;
  recyclingSuccessful: boolean;
}

export interface IFilter {
  filterObject: IFilterObject;
  filterEnum: DegreeOfSeparationFilterEnum;
}

export enum ChartLineColor {
  recyclingUnsuccessful = "#FB8181",
  recyclingModerate = "#FDC960",
  recyclingSuccessful = "#72D320",
}
