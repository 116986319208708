import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { Pin } from "../../../../components/Pin";
import { switchMapFilter } from "../../../../reducers/wagonsReportStateReducer";
import { getWagonsMapFilters } from "../../../../selectors/wagonsReport";
import { Colors, MapFilterType } from "../../../../utils/enums";

import styles from "./WagonsMapFilter.module.scss";

export const WagonsMapFilter = () => {
  const mapFilter = useSelector(getWagonsMapFilters);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span>{t("wagonsReport.binType")}:</span>
      <div onClick={() => dispatch(switchMapFilter(MapFilterType.normal))}>
        <Pin
          color={mapFilter.normal.value ? Colors.Green : Colors.Border}
          mainValue={<Icon name={IconNameEnum.delete} type={IconTypeEnum.white} />}
          additinalValue={<Icon name={IconNameEnum.check} type={IconTypeEnum.white} />}
        />
      </div>
      <div onClick={() => dispatch(switchMapFilter(MapFilterType.warning))}>
        <Pin
          color={mapFilter.warning.value ? Colors.Orange : Colors.Border}
          mainValue={<Icon name={IconNameEnum.delete} type={IconTypeEnum.white} />}
          additinalValue={<Icon name={IconNameEnum.exclamation} type={IconTypeEnum.white} />}
        />
      </div>
      <div onClick={() => dispatch(switchMapFilter(MapFilterType.noRfid))}>
        <Pin
          color={mapFilter.noRfid.value ? Colors.Red : Colors.Border}
          mainValue={<Icon name={IconNameEnum.delete} type={IconTypeEnum.white} />}
          additinalValue={<Icon name={IconNameEnum.cross} type={IconTypeEnum.white} />}
        />
      </div>
    </div>
  );
};
