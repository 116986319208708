import styles from "./Card.module.scss";

interface ContentSegmentProps {
  child: React.ReactNode;
  outerPaddingLeft?: number;
  outerPaddingRight?: number;
  contentPaddingTop?: number;
  contentPaddingBottom?: number;
}

export const ContentSegment: React.FC<ContentSegmentProps> = ({
  child,
  outerPaddingLeft,
  outerPaddingRight,
  contentPaddingTop,
  contentPaddingBottom,
}) => {
  const appliedContentSegmentStyles = {
    paddingLeft: outerPaddingLeft,
    paddingRight: outerPaddingRight,
    paddingTop: contentPaddingTop,
    paddingBottom: contentPaddingBottom,
  };

  return (
    <div className={styles.contentSegment} style={appliedContentSegmentStyles}>
      {child}
    </div>
  );
};
