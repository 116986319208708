import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_MAYOR_MODULES } from "../middleware/routes";
import { IMayorModules, MayorModuleStatus, SortingStatus } from "../types/mayorModules";
import { IMayorModulesState, Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";

const defaultMayorModules: IMayorModules = {
  WasteSortingStatus: SortingStatus.notSort,
  CollectionPointModule: MayorModuleStatus.inactive,
  WagonModule: MayorModuleStatus.inactive,
  CommunityStateModule: MayorModuleStatus.inactive,
  WagonCalendarModule: MayorModuleStatus.inactive,
  InventorySheetModule: MayorModuleStatus.active,
  CollectionYardsModule: MayorModuleStatus.inactive,
  MobileAppModule: MayorModuleStatus.inactive,
  SupportModule: MayorModuleStatus.hidden,
};

export const initMayorModulesState: IMayorModulesState = {
  status: Status.idle,
  errorMsg: null,
  mayorModules: defaultMayorModules,
  isSeparating: false,
  doesWeigh: false,
};

export const mayorModulesSlice = createSlice({
  name: "mayorModules",
  initialState: initMayorModulesState,
  reducers: {
    resetMayorModulesState: () => {
      return initMayorModulesState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMayorModulesSettings.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(getMayorModulesSettings.fulfilled, (state, action: PayloadAction<IMayorModules>) => {
        state.status = Status.success;
        state.mayorModules = action.payload;
        state.isSeparating = action.payload.WasteSortingStatus !== SortingStatus.notSort;
        state.doesWeigh = action.payload.WasteSortingStatus !== SortingStatus.notWeigh;
        state.errorMsg = null;
      })
      .addCase(getMayorModulesSettings.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "error loading modules";
      });
  },
});

export const getMayorModulesSettings = createAsyncThunk<IMayorModules>(
  "mayorModules/getMayorModulesSettings",
  async () => {
    const response = await axiosRequestApi.get(GET_MAYOR_MODULES);

    const collectionYardsModule = response.data["SalvageYardModule"];
    return { ...response.data, CollectionYardsModule: collectionYardsModule };
  }
);

export const mayorModulesReducer = mayorModulesSlice.reducer;
