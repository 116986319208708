export enum ImageNameEnum {
  bins = "bins",
  car = "car",
  carGrey = "car_grey",
  meadow = "meadow",
  noResults = "no_results",
  vzor = "vzor",
  bgDomov = "bg_DOMOV",
  bgZberneDvory = "bg_ZBERNE_DVORY",
  bgStavOvzdusi = "bg_STAV_OVZDUSI",
  bgZvozy = "bg_ZVOZY",
  wagonsInit = "wagons_init",
}
