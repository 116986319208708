import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { useGetTranslatedEnumResourceCodeGetter } from "../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { IContainer } from "../../../types/container";
import { EnumName } from "../../../utils/enums";
import { getContainerTypeCellTitle } from "./getContainerTypeCellTitle";

interface ContainerTypeCellProps {
  params: GridRenderCellParams<IContainer["containerType"]>;
}

export const ContainerTypeCell: React.FC<ContainerTypeCellProps> = ({ params }) => {
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();

  const wasteTypeTitle = getTranslatedEnumResourceCode(EnumName.WasteTypes, params.row.wasteType);
  const containerTypeTitle = getTranslatedEnumResourceCode(EnumName.ContainerTypes, params.value, "");

  return <p>{getContainerTypeCellTitle(containerTypeTitle, wasteTypeTitle, params.row.wasteType)}</p>;
};
