import { Status } from "./state";

export type ICollectionYardWasteTypeApi = {
  waste_type_id: number;
  rate: number;
  disabled: boolean;
};

export type ICollectionYardWasteType = {
  wasteTypeId: number;
  rate: number | string;
  disabled: boolean;
};

export type ICollectionYardDetailApi = {
  collection_yard_id: number;
  login?: string;
  name: string;
  address: string;
  identification: string;
  tax_identification: string;
  CollectionYardWasteTypes: ICollectionYardWasteTypeApi[];
};

export type ICollectionYardDetailApiPostBody = {
  name: string;
  address: string;
  identification: string;
  tax_identification: string;
  waste_types: ICollectionYardWasteTypeApi[];
};

export type ICollectionYardDetail = {
  collectionYardId: number;
  login?: string;
  name: string;
  address: string;
  identification: string;
  taxIdentification: string;
  collectionYardWasteTypes: ICollectionYardWasteType[];
};

export type ICollectionYardDetailCredentials = {
  login: string;
  password: string;
};

export type ICollectionYardDetailSettingsState = {
  status: Status;
  requestId: string;
  errorMsg: string | null;
  isTouched: boolean;
  initName: string;
  collectionYardDetail: ICollectionYardDetail;
  credentials?: ICollectionYardDetailCredentials;
};

export enum CollectionYardDetailSettingsFields {
  Name = "name",
  Address = "address",
  Identification = "identification",
  TaxIdentification = "taxIdentification",
}
