import { useTranslation } from "react-i18next";

import { Image } from "../Image";
import { ImageNameEnum } from "../Image/ImageNameEnum";
import { ImageSuffixEnum } from "../Image/ImageSuffixEnum";

import styles from "./NoResultsOverlay.module.scss";

export interface INoResultsOverlayProps {
  suggestion?: string;
  errorText?: string;
}

export const NoResultsOverlay: React.FC<INoResultsOverlayProps> = ({
  suggestion = "suggestion",
  errorText = "error",
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.imageContainer}>
      <Image
        name={ImageNameEnum.noResults}
        suffix={ImageSuffixEnum.png}
        alt="ziadne vysledky"
        width="308px"
        height="166px"
      />
      <h3 className={styles.errorSubheader}>{t(`noData.${suggestion}`)}</h3>
      <p className={styles.errorText}>{t(`noData.${errorText}`)}</p>
    </div>
  );
};
