import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Popover } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { CustomButton, IButtonVariant } from "../../../../components/CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { removeInventorySheetFilter, setInventorySheetListOrdering } from "../../../../reducers/inventorySheetReducer";
import { getInventorySheetFilterIsActive, getInventorySheetOrderingValue } from "../../../../selectors/inventorySheet";
import { RootState } from "../../../../store";
import { ColumnType } from "../InventorySheetTableColumnTypes";
import { ContainerTypeFilter } from "./filters/containerType";
import { NumberFilter } from "./filters/number";
import { RfidFilter } from "./filters/rfid";
import { TextFilter } from "./filters/text";
import { WasteTypeFilter } from "./filters/wasteType";

import styles from "./InventorySheetTableHeaderCell.module.scss";

interface IHeaderCellProps {
  params: GridColumnHeaderParams;
}

const selectFilters = (type: ColumnType) => {
  switch (type) {
    case ColumnType.collectionPointName:
      return <TextFilter fieldName={type} />;
    case ColumnType.street:
      return <TextFilter fieldName={type} />;
    case ColumnType.streetNumber:
      return <TextFilter fieldName={type} />;
    case ColumnType.wasteType:
      return <WasteTypeFilter fieldName={type} />;
    case ColumnType.containerType:
      return <ContainerTypeFilter fieldName={type} />;
    case ColumnType.rfid:
      return <RfidFilter fieldName={type} />;
    case ColumnType.barcode:
      return <TextFilter fieldName={type} />;
    case ColumnType.pointId:
      return <NumberFilter fieldName={type} />;
    case ColumnType.householdMembersCount:
      return <NumberFilter fieldName={type} />;
    default:
      <div />;
  }
};

export const InventorySheetTableHeaderCell: React.FC<IHeaderCellProps> = ({ params }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordering = useSelector((state: RootState) => getInventorySheetOrderingValue(state, params.field));
  const isFilterActive = useSelector((state: RootState) => getInventorySheetFilterIsActive(state, params.field));

  const changeSort = () => {
    const newOrdering = {
      orderBy: params.field,
      descending: ordering ? ordering !== "desc" : false,
    };

    dispatch(setInventorySheetListOrdering(newOrdering));
  };

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <p className={styles.title}>{t(`inventoryList.${params.field}`)}</p>
      <IconButton aria-describedby={id} onClick={openFilters}>
        {isFilterActive ? (
          <Icon name={IconNameEnum.filter} type={IconTypeEnum.grey} />
        ) : (
          <Icon name={IconNameEnum.filter} />
        )}
      </IconButton>
      <IconButton onClick={changeSort}>
        {ordering === "asc" && <Icon name={IconNameEnum.sortAsc} />}
        {ordering === "desc" && <Icon name={IconNameEnum.sortDesc} />}
        {!ordering && <Icon name={IconNameEnum.sortNone} />}
      </IconButton>
      <Popover
        className={styles.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <h1>{t(`inventoryList.${params.field}`)}</h1>
        <div className={styles.filters}>{selectFilters(params.field as ColumnType)}</div>
        <CustomButton
          variant={IButtonVariant.textImportant}
          className={styles.textSize}
          onClick={() => {
            dispatch(removeInventorySheetFilter(params.field));
          }}
          text={t("inventoryList.filters.cancel")}
        />
      </Popover>
    </>
  );
};
