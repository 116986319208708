import * as xlsx from "xlsx";

export const MAX_WCH = 50;

export const createXlsxFile = (data: any[]): File => {
  const workBook = xlsx.utils.book_new();
  const workSheet = xlsx.utils.json_to_sheet(data);

  const columnWidths = Object.keys(data[0]).map((key) => {
    const maxLength = data.reduce((max, row) => {
      const value = row[key] ? row[key].toString() : "";
      return Math.max(max, value.length);
    }, key.length);
    return { wch: Math.min(maxLength + 2, MAX_WCH) };
  });
  workSheet["!cols"] = columnWidths;

  xlsx.utils.book_append_sheet(workBook, workSheet, "WAMA export");

  return xlsx.write(workBook, { bookType: "xlsx", type: "buffer" });
};
