import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

import { removeInventorySheetFilter, setInventorySheetFilter } from "../../../../../reducers/inventorySheetReducer";
import { getInventorySheetFilterValue } from "../../../../../selectors/inventorySheet";
import { RootState } from "../../../../../store";
import { ColumnType } from "../../InventorySheetTableColumnTypes";

interface Props {
  fieldName: ColumnType;
}

export const RfidFilter: React.FC<Props> = ({ fieldName }) => {
  const value = useSelector((state: RootState) => getInventorySheetFilterValue(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Checkbox
        checked={!!value}
        onChange={() => {
          if (value) dispatch(removeInventorySheetFilter(fieldName));
          else dispatch(setInventorySheetFilter({ id: fieldName, name: fieldName, operator: "isEmpty" }));
        }}
      />
      {t("wagonsReport.filters.noRfid")}
    </div>
  );
};
