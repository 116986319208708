import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getApplicationEnums } from "../selectors/application";
import { IServerEnumObject, IServerEnumObjectValue } from "../types/enums";
import { defaultResourceKeys } from "../utils/constants";
import { EnumName } from "../utils/enums";
import { capitalizeString } from "../utils/formatters/capitalizeString";

export const useGetTranslatedEnumResourceCodeGetter = () => {
  const { t } = useTranslation();
  const enums = useSelector(getApplicationEnums);

  const getTranslatedEnumResourceCode = useCallback(
    (enumName: EnumName, enumCompareValue?: number, defaultTranslationCode?: string) => {
      const resourceCode =
        enums
          ?.find((appEnum: IServerEnumObject) => appEnum?.name === enumName)
          ?.values.find((type: IServerEnumObjectValue) => Number(type.value) === enumCompareValue)?.resourceCode ?? "";

      const translation = t(resourceCode);
      if (!resourceCode || translation === resourceCode) {
        if (defaultTranslationCode !== undefined) {
          return capitalizeString(t(defaultTranslationCode));
        }
        return capitalizeString(t(defaultResourceKeys[enumName]));
      }

      return capitalizeString(t(resourceCode));
    },
    [enums]
  );

  return { getTranslatedEnumResourceCode };
};
