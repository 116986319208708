import { Children } from "react";

import { ContentSegment } from "./ContentSegment";
import { HeaderSegment } from "./HeaderSegment";

interface InnerCardComponentProps {
  children: React.ReactNode;
  child: React.ReactNode;
  i: number;
  outerPaddingLeft?: number;
  outerPaddingRight?: number;
  contentPaddingTop?: number;
  contentPaddingBottom?: number;
  headerHeight?: number;
}

export const InnerCardComponent: React.FC<InnerCardComponentProps> = ({
  children,
  child,
  i,
  outerPaddingLeft,
  outerPaddingRight,
  contentPaddingTop,
  contentPaddingBottom,
  headerHeight,
}) => {
  const isHeaderSegment: boolean = i === 0;
  const hasOnlyOneChild: boolean = Children.toArray(children).length === 1;
  const headerSegmentProps = { headerHeight, outerPaddingLeft, outerPaddingRight };
  const contentSegmentProps = { outerPaddingLeft, outerPaddingRight, contentPaddingTop, contentPaddingBottom };

  if (hasOnlyOneChild) {
    return <ContentSegment child={children} {...contentSegmentProps} />;
  }

  if (isHeaderSegment) {
    return <HeaderSegment child={child} {...headerSegmentProps} />;
  }

  return <ContentSegment child={child} {...contentSegmentProps} />;
};
