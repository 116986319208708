import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import styles from "./CollectionPointTable.module.scss";

interface WeightHistoryCellProps {
  params: GridRenderCellParams;
}

export const WeightHistoryCell: React.FC<WeightHistoryCellProps> = ({ params }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const containerId: number = params.row.containerId;
  const handleClick = () =>
    history.push(history.location.pathname + `/history?containerId=${containerId}`, {
      from: history.location.pathname,
      id: history.location.search,
    });

  return (
    <p key={containerId} className={styles.weightHistoryCell} onClick={handleClick}>
      {t("weightHistory").toLowerCase()}
    </p>
  );
};
