import { RootState } from "../store";
import { ICollectionPointsState, Status } from "../types/state";

export const getCollectionPoints = (state: RootState): ICollectionPointsState => state.collectionPoints;
export const getCollectionPointsListStatus = (state: RootState): ICollectionPointsState["status"] =>
  state.collectionPoints.status;
export const getIsCollectionPointsListStatusLoading = (state: RootState): boolean =>
  state.collectionPoints.status !== Status.success;

export const getCollectionPointsListErrorMsg = (state: RootState): ICollectionPointsState["errorMsg"] =>
  state.collectionPoints.errorMsg;

export const getCollectionPointsLocations = (state: RootState): ICollectionPointsState["locations"] =>
  state.collectionPoints.locations;

export const getCollectionPointsNextPageState = (state: RootState): ICollectionPointsState["isNextPageEnable"] =>
  state.collectionPoints.isNextPageEnable;

export const getCollectionPointsPagination = (state: RootState): ICollectionPointsState["pagination"] =>
  state.collectionPoints.pagination;
export const getCollectionPointsOrdering = (state: RootState): ICollectionPointsState["ordering"] =>
  state.collectionPoints.ordering;
export const getCollectionPointsFilter = (state: RootState): ICollectionPointsState["filter"] =>
  state.collectionPoints.filter;

export const getCollectionPointsSearch = (state: RootState): ICollectionPointsState["search"] =>
  state.collectionPoints.search;

export const getCollectionPointsScrollTop = (state: RootState): ICollectionPointsState["scrollTop"] =>
  state.collectionPoints.scrollTop;
