import { GridFilterItem, GridLinkOperator } from "@mui/x-data-grid-pro";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { wagonReportTableColumnType } from "../containers/WagonsPage/WagonsReport/WagonsReportTable/WagonsReportTableUtils";
import { GET_WAGONS_REPORT } from "../middleware/routes";
import { RootState } from "../store";
import { IWagonsReportState, IWagonTableItemLookup, Status } from "../types/state";
import { IReportItem, IWagonsJumpTo, IWagonsReport } from "../types/wagons";
import { axiosRequestApi } from "../utils/axiosRequest";
import { wagonsWasteTypeToNumber } from "../utils/enumMapping";
import { MapFilterType } from "../utils/enums";
import { initWagonsMapFilterState } from "../utils/mapFilter";
import { mapWagonsReportToMap } from "../utils/mapWagonsReportToMap";
import { dummyData } from "../utils/WagonsPageDummyData";
import { shouldRemoveLocalFilter } from "./utils/localFilters";
import { createSummary } from "./utils/wagonsSummary";

const summaryDummyData = { found: 0, containers: [], uniqueContainers: [], noRfid: 0, totalWeight: 0 };

export const initWagonsReportState: IWagonsReportState = {
  status: Status.idle,
  errorMsg: null,
  report: {
    list: [],
  },
  map: [],
  filters: {
    filter: { items: [], linkOperator: GridLinkOperator.And },
    sort: [],
  },
  mapView: false,
  mapFilters: {
    normal: { value: true, disabled: false },
    warning: { value: true, disabled: false },
    noRfid: { value: true, disabled: false },
  },
  scrollTop: 0,
  summaryDefault: summaryDummyData,
  summary: summaryDummyData,
};

export const wagonsReportSlice = createSlice({
  name: "wagonsReport",
  initialState: initWagonsReportState,
  reducers: {
    resetWagonsReportState: () => {
      return initWagonsReportState;
    },
    setFilteredItemsLookup: (state, action: PayloadAction<IWagonTableItemLookup[]>) => {
      const filteredList: IReportItem[] = [];

      state.report.list.forEach((item) => {
        if (action.payload[item.vehicleEventId]) {
          filteredList.push(item);
        }
      });
      state.summary = createSummary(filteredList);
    },
    setFilter: (state, action: PayloadAction<GridFilterItem>) => {
      const findIdx = state.filters.filter.items.findIndex((item) => item.id === action.payload.id);
      if (findIdx !== -1) {
        if (shouldRemoveLocalFilter(action.payload)) {
          state.filters.filter.items.splice(findIdx, 1);
        } else {
          state.filters.filter.items[findIdx] = action.payload;
        }
      } else state.filters.filter.items.push(action.payload);
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      state.filters.filter.items = state.filters.filter.items.filter(
        (item) => !item.id?.toString().startsWith(action.payload)
      );
    },
    setSort: (state, action: PayloadAction<wagonReportTableColumnType>) => {
      if (state.filters.sort[0]?.field !== action.payload) {
        state.filters.sort = [{ field: action.payload, sort: "asc" }];
        return;
      }
      if (state.filters.sort[0].sort === "asc") {
        state.filters.sort[0].sort = "desc";
        return;
      }
      state.filters.sort = [];
    },
    setDummyData: (state) => {
      state.status = Status.success;
      state.report = dummyData;
      state.map = mapWagonsReportToMap(dummyData.list);
      state.jumpTo = {
        vehicleEventId: 0,
        lat: state.map[0].geometry.coordinates[1],
        long: state.map[0].geometry.coordinates[0],
        zoom: 17,
      };
    },
    switchView: (state) => {
      state.mapView = !state.mapView;
    },
    setJumpTo: (state, action: PayloadAction<IWagonsJumpTo>) => {
      state.jumpTo = action.payload;
    },
    resetJumpTo: (state) => {
      state.jumpTo = undefined;
    },
    switchMapFilter: (state, action: PayloadAction<MapFilterType>) => {
      if (state.mapFilters[action.payload].disabled) return;
      let activeCount = 0;
      for (const filter of Object.values(state.mapFilters)) if (filter.value === true) activeCount += 1;
      if (activeCount === 1 && state.mapFilters[action.payload].value) return;
      state.mapFilters[action.payload].value = !state.mapFilters[action.payload].value;
    },
    setScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWagonsReport.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(fetchWagonsReport.fulfilled, (state, action: PayloadAction<IWagonsReport>) => {
        state.status = Status.success;
        state.report = action.payload;
        state.summary = createSummary(state.report.list);
        state.summaryDefault = createSummary(state.report.list);
        state.map = mapWagonsReportToMap(action.payload.list);
        state.mapFilters = initWagonsMapFilterState(state.map);
        state.jumpTo = {
          vehicleEventId: 0,
          lat: state.map[0].geometry.coordinates[1],
          long: state.map[0].geometry.coordinates[0],
          zoom: 13,
        };
        state.errorMsg = null;
      })
      .addCase(fetchWagonsReport.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "error loading report";
      });
  },
});

export const fetchWagonsReport = createAsyncThunk<IWagonsReport, undefined, { state: RootState }>(
  "wagons/getWagonsReport",
  async (_, { signal, getState }) => {
    const state: RootState = getState();
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const body = {
      date: {
        ...state.wagonsDatePicker.selectedDate,
      },
      wasteType: wagonsWasteTypeToNumber.get(state.wagonsDatePicker.selectedWasteType),
    };

    const response = await axiosRequestApi.post(GET_WAGONS_REPORT, body, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const {
  setFilteredItemsLookup,
  setFilter,
  removeFilter,
  setSort,
  setDummyData,
  switchView,
  setJumpTo,
  resetJumpTo,
  switchMapFilter,
  setScrollTop,
  resetWagonsReportState,
} = wagonsReportSlice.actions;

export const wagonsReportReducer = wagonsReportSlice.reducer;
