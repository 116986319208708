import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";

import { getJwtFromStore } from "../../selectors/auth";

interface IPrivateRouteProps extends RouteProps {
  component: React.FC<{ [key: string]: unknown }>;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component: Component, ...rest }) => {
  const jwt = useSelector(getJwtFromStore);

  const handleRender = (props: RouteComponentProps) => {
    if (jwt) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...rest} render={handleRender} />;
};
