import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";

export const MunicipalityStatePage: React.FC = () => {
  //return (
  //<>
  //<NotYetImplemented />
  //<Image src="img/bg_STAV_OVZDUSI.jpeg" alt="stav ovzdusi" width="100%" height="100%" />
  //</>

  //);

  return <PageContainer module={MayorModulesType.CommunityStateModule}>NOT IMPLEMENTED</PageContainer>;
};
