import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ComplexPageHeaderTitle.module.scss";

interface ComplexPageHeaderTitleProps {
  title: string | ReactNode;
  boldText?: string;
  text?: string;
}

export const ComplexPageHeaderTitle: React.FC<ComplexPageHeaderTitleProps> = ({ title, boldText = "", text = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2>{typeof title === "string" ? t(title) : title}</h2>
      <div className={styles.secondaryColor}>
        <span className={styles.bold}>{t(boldText)}</span>
        {text && (
          <>
            {" "}
            <span>{t(text)}</span>
          </>
        )}
      </div>
    </div>
  );
};
