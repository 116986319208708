import { GridFilterItem } from "@mui/x-data-grid-pro";

export const shouldRemoveLocalFilter = (filter: GridFilterItem): boolean => {
  const operatorIsAnyOf = filter.operatorValue === "isAnyOf";
  const valueLength = Array.isArray(filter.value) ? filter.value.length : 0;
  const operatorIsWarning = filter.operatorValue === "hasWarning";
  const redundantOperatorIsAny = operatorIsAnyOf && valueLength === 0;
  const redundantOperatorIsWarning = operatorIsWarning && !filter.value;

  return redundantOperatorIsAny || redundantOperatorIsWarning || filter.value === "";
};
