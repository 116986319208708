import { RootState } from "../../store";
import { IWasteSummary, IWasteTypeRow } from "../../types/wasteTypeQuantity";
import { AllWasteTypes } from "../enums";

export const getWasteTypesSummaryRows = (wasteSummaryDetailData: IWasteSummary): IWasteTypeRow[] =>
  wasteSummaryDetailData.wasteTypeSummaries
    .map((wasteTypeSummary) => ({ ...wasteTypeSummary, header: String(wasteTypeSummary.wasteType) }))
    .sort((a, b) => String(a.wasteType).localeCompare(String(b.wasteType)));

export const getSummariesWithoutTotals = (state: RootState) => {
  const wasteTypeSummariesWithoutTotals = state.wasteTypesQuantity.wasteSummaryDetail.wasteTypeSummaries.filter(
    (wasteTypeSummary) =>
      wasteTypeSummary.wasteType !== AllWasteTypes.totalWaste &&
      wasteTypeSummary.wasteType !== AllWasteTypes.recycled &&
      wasteTypeSummary.wasteType !== AllWasteTypes.other
  );

  return wasteTypeSummariesWithoutTotals;
};
