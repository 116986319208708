import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import { setWeightHistoryPagination, setWeightHistoryScrollTop } from "../../../reducers/weightHistoryReducer";
import {
  getIsWeightHistoryStatusLoading,
  getWeightHistory,
  getWeightHistoryList,
} from "../../../selectors/weightHistory";
import { useGetWeightHistoryTableColumns } from "./useGetWeightHistoryTableColumns";

export const WeightHistoryTable: React.FC = () => {
  const weightHistoryList = useSelector(getWeightHistoryList);
  const columns = useGetWeightHistoryTableColumns();
  const isLoading = useSelector(getIsWeightHistoryStatusLoading);
  const weightHistoryState = useSelector(getWeightHistory);

  const data = weightHistoryList.map((row, i) => {
    const date = new Date(row.date);
    return {
      ...row,
      date: `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`,
      id: i + 1,
    };
  });

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={0}
      setScrollTop={setWeightHistoryScrollTop}
      setPagination={setWeightHistoryPagination}
      isLoading={isLoading}
      sliceState={weightHistoryState}
    />
  );
};
