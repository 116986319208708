import { useSelector } from "react-redux";

import { getMayorModules } from "../selectors/mayorModules";
import { MayorModuleStatus } from "../types/mayorModules";
import { FeatureName } from "../utils/enums";

export const useIsFeatureEnabled = (featureName: FeatureName): boolean => {
  const modules = useSelector(getMayorModules);

  if (featureName === FeatureName.mobile) {
    return modules.MobileAppModule === MayorModuleStatus.active;
  }

  return false;
};
