import { useDispatch } from "react-redux";

import { IPagination } from "../../types/pagination";
import { Status } from "../../types/state";

interface HandleDataTableNextPageProperties {
  handleNextPage: () => void;
}

export interface SliceStatePartial {
  pagination: IPagination;
  isNextPageEnable: boolean;
  status: Status;
}

export type SetPaginationHandler = (pagination: IPagination) => void;

export const useHandleDataTableNextPage = (
  sliceState: SliceStatePartial,
  setPagination: SetPaginationHandler
): HandleDataTableNextPageProperties => {
  const dispatch = useDispatch();

  const { pagination, isNextPageEnable, status } = sliceState;

  const isLoading = status !== Status.success;

  const handleNextPage = () => {
    if (!isNextPageEnable || isLoading) return;

    dispatch(setPagination({ ...pagination, page: pagination.page + 1 }));
  };

  return { handleNextPage };
};
