import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { getDoesWeigh } from "../../../selectors/mayorModules";
import { IColumnAlignObject } from "../../../types/table";
import { getWeighValue } from "../../../utils/collectionPoints/getWeighValue";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";

export enum WeightHistoryTableColumnType {
  date = "date",
  weight = "weight",
}
const columnLabels = Object.values(WeightHistoryTableColumnType);

const columnAlignObject: IColumnAlignObject<WeightHistoryTableColumnType> = {
  left: [WeightHistoryTableColumnType.date],
  right: [WeightHistoryTableColumnType.weight],
};

export const useGetWeightHistoryTableColumns = () => {
  const { t } = useTranslation();
  const { getAlign } = getTableColumnUtils();
  const doesWeigh = useSelector(getDoesWeigh);

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      flex: 1,
      headerAlign: getAlign(label, columnAlignObject),
      align: getAlign(label, columnAlignObject),
    };

    columnObject.renderCell = (params: GridRenderCellParams<boolean>) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case WeightHistoryTableColumnType.weight:
          return <div>{getWeighValue(doesWeigh, params.value, "kg")}</div>;

        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
