import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setApplicationMode } from "../reducers/applicationReducer";
import { getSettings } from "../selectors/settings";
import { ApplicationMode } from "../types/application";
import { setBodyBackground } from "../utils/themeHandler";

export const useHandleApplicationSettings = (): void => {
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);

  useEffect(() => {
    dispatch(setApplicationMode(settings?.darkMode ? ApplicationMode.dark : ApplicationMode.light));
    setBodyBackground(settings?.darkMode ? ApplicationMode.dark : ApplicationMode.light);
  }, [settings, dispatch]);
};
