import { useTranslation } from "react-i18next";

import { IconNameEnum, IconTypeEnum } from "../Icon";
import { TextInput, TextInputIconPosition, TextInputSize, TextInputVariant } from "../TextInput";

import styles from "./SearchBar.module.scss";

export interface ITextInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onSearch?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SearchBar: React.FC<ITextInputProps> = ({ value, onChange, onKeyDown, onSearch }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <TextInput
        error={false}
        helperText={""}
        required={false}
        placeHolder={t("search")}
        name="search"
        type="text"
        autoComplete="off"
        icon={IconNameEnum.search}
        iconType={IconTypeEnum.black}
        iconPosition={TextInputIconPosition.end}
        size={TextInputSize.sm}
        variant={TextInputVariant.secondary}
        {...{ value, onChange, onKeyDown, onSearch }}
      />
    </div>
  );
};
