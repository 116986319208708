import { wasteTypeToBgColor } from "../../../utils/enumMapping";
import { WasteType } from "../../../utils/enums";

import styles from "./Pin.module.scss";

interface PinProps {
  type: WasteType;
}

export const Pin: React.FC<PinProps> = ({ type }) => {
  return <div className={styles.pin} style={{ backgroundColor: wasteTypeToBgColor.get(type) }}></div>;
};
