import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INotificationInput, NotificationSeverity } from "../types/notification";
import { INotificationState } from "../types/state";

const initNotificationState: INotificationState = {
  notification: { open: false, message: "", severity: NotificationSeverity.info },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initNotificationState,
  reducers: {
    showNotification: (state: INotificationState, action: PayloadAction<INotificationInput>) => {
      const { message, severity, hideAfter } = action.payload;
      state.notification = { message, severity, hideAfter, open: true };
    },
    updateNotification: (
      state: INotificationState,
      action: PayloadAction<INotificationState["notification"]["message"]>
    ) => {
      state.notification.message = action.payload;
    },
    hideNotification: (state: INotificationState) => {
      state.notification = { ...state.notification, message: "", hideAfter: undefined, open: false };
    },
    resetNotificationState: () => {
      return initNotificationState;
    },
  },
});

export const { showNotification, updateNotification, hideNotification, resetNotificationState } =
  notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
