import { useTranslation } from "react-i18next";

import { Card } from "../../components/Card";
import { PageContainer } from "../../components/PageContainer";
import { SummaryTable } from "./SummaryTable";
import { WasteCollectionEditPageHeaderComponent } from "./WasteCollectionEditPageHeaderComponent";

import styles from "./WasteCollectionEditPage.module.scss";

export const WasteCollectionEditPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContainer headerText={t("summary")} HeaderComponent={WasteCollectionEditPageHeaderComponent}>
        <Card headerHeight={74} gap={22}>
          <h5 className={styles.contentHeader}>{t("wasteTransportPerPeriod")}</h5>
          <SummaryTable />
        </Card>
      </PageContainer>
    </>
  );
};
