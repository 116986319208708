import { useState } from "react";

import { IModal } from "../types/modal";

export const useModal = (): IModal => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModalVisibility = () => {
    setIsModalVisible((prev) => !prev);
  };

  return {
    isModalVisible,
    toggleModalVisibility,
  };
};
