import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { DateTimePicker } from "../../../../../../components/DateTimePicker";
import { setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsSelectedDate } from "../../../../../../selectors/wagonsDatePicker";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const DateFilter: React.FC<Props> = ({ fieldName }) => {
  const minValueId = `${fieldName}Min`;
  const maxValueId = `${fieldName}Max`;
  const minValue = useSelector((state: RootState) => getWagonsReportFilterValue(state, minValueId));
  const maxValue = useSelector((state: RootState) => getWagonsReportFilterValue(state, maxValueId));
  const allowedRange = useSelector(getWagonsSelectedDate);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <DateTimePicker
        value={minValue?.value}
        placeHolder={t("wagonsReport.filters.dateFrom")}
        withIcon={true}
        minDate={dayjs(allowedRange.from)}
        maxDate={dayjs(allowedRange.to)}
        onChange={(value) => {
          dispatch(
            setFilter({ id: minValueId, columnField: fieldName, operatorValue: ">=", value: value.toISOString() })
          );
        }}
      />
      <DateTimePicker
        value={maxValue?.value}
        placeHolder={t("wagonsReport.filters.dateTo")}
        withIcon={true}
        minDate={dayjs(allowedRange.from)}
        maxDate={dayjs(allowedRange.to)}
        onChange={(value) => {
          dispatch(
            setFilter({ id: maxValueId, columnField: fieldName, operatorValue: "<=", value: value.toISOString() })
          );
        }}
      />
    </>
  );
};
