import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import { setWagonsCalendarPagination, setWagonsCalendarScrollTop } from "../../../reducers/wagonsCalendarReducer";
import {
  getWagonsCalendar,
  getWagonsCalendarEvents,
  getWagonsCalendarIsLoading,
  getWagonsCalendarScrollTop,
} from "../../../selectors/wagonsCalendar";
import { useGetWagonsCalendarTableColumns } from "./useGetWagonsCalendarTableColumns";
import { useGetWagonsCalendarTableHandlers } from "./useGetWagonsCalendarTableHandlers";

export const WagonsCalendarTable = () => {
  const events = useSelector(getWagonsCalendarEvents);
  const { columns } = useGetWagonsCalendarTableColumns();
  const { handleAdditionalRowClick } = useGetWagonsCalendarTableHandlers();
  const wagonsCalendarState = useSelector(getWagonsCalendar);
  const scrollTop = useSelector(getWagonsCalendarScrollTop);
  const isLoading = useSelector(getWagonsCalendarIsLoading);
  return (
    <DataTable
      dataRows={events}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setWagonsCalendarScrollTop}
      setPagination={setWagonsCalendarPagination}
      isLoading={isLoading}
      sliceState={wagonsCalendarState}
      handleAdditionalRowClick={handleAdditionalRowClick}
    />
  );
};
