import { isAnyOf } from "@reduxjs/toolkit";

import {
  defaultWeightHistoryListItem,
  getWeightHistoryThunk,
  setWeightHistoryContainer,
  setWeightHistoryList,
  setWeightHistoryPagination,
} from "../../reducers/weightHistoryReducer";
import { getSkeletonMockedArray } from "../../utils/tables/getSkeletonMockedArray";
import { startAppListening } from "./listenerMiddleware";

export const weightHistoryListener = () => {
  startAppListening({
    matcher: isAnyOf(getWeightHistoryThunk.pending),
    effect: async (_, { getState, dispatch }) => {
      const state = getState();
      const containerFromState = state.weightHistory.weightHistory.container;
      const weightHistoryList = state.weightHistory.weightHistory.dumpings;
      const page = state.weightHistory.pagination.page;
      const containers = state.collectionPoint.collectionPoint.containers;
      const collectionPointContainer = containers.find(
        (container) => container.containerId === containerFromState.containerId
      );

      if (collectionPointContainer && page === 1) {
        const mockedWeightHistoryList = getSkeletonMockedArray(
          defaultWeightHistoryListItem,
          collectionPointContainer.totalDumpings,
          weightHistoryList.length
        );

        dispatch(setWeightHistoryList(mockedWeightHistoryList));
      }

      if (page > 1) {
        const mockedWeightHistoryList = getSkeletonMockedArray(
          defaultWeightHistoryListItem,
          3,
          weightHistoryList.length
        );
        const newWeightHistoryList = [...weightHistoryList, ...mockedWeightHistoryList];
        dispatch(setWeightHistoryList(newWeightHistoryList));
      }
    },
  });

  startAppListening({
    actionCreator: setWeightHistoryContainer,
    effect: async (action, { dispatch }) => {
      const containerId = action.payload.containerId;
      dispatch(getWeightHistoryThunk(containerId));
    },
  });

  startAppListening({
    actionCreator: setWeightHistoryPagination,
    effect: async (_, { dispatch, getState }) => {
      const state = getState();
      const containerId = state.weightHistory.weightHistory.container.containerId;

      dispatch(getWeightHistoryThunk(containerId));
    },
  });
};
