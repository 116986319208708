export enum ColumnType {
  collectionPointName = "collectionPointName",
  street = "street",
  streetNumber = "streetNumber",
  wasteType = "wasteType",
  containerType = "containerType",
  rfid = "rfid",
  barcode = "barcode",
  pointId = "pointId",
  householdMembersCount = "householdMembersCount",
}

export const columnLabels = Object.values(ColumnType);
