import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";

import { Container } from "../../components/Container";
import { CustomButton } from "../../components/CustomButton";
import { PageSettingsContainer } from "../../components/PageSettingsContainer";
import { SelectComponent } from "../../components/SelectComponent";
import { updateLanguageThunk } from "../../reducers/authReducer";
import { getLanguage } from "../../selectors/auth";
import { ILanguageValueEnum } from "../../types/localization";
import { ISelectOption } from "../../types/select";
import { languagesOptionsArray } from "../../utils/selectOptions";

import styles from "./LanguageSettingsPage.module.scss";

export const LanguageSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();

  const getInitialOption = (): ISelectOption => {
    let initialOption = languagesOptionsArray[0];

    const thisItem = languagesOptionsArray.find((item) => item.value === language);
    if (thisItem) {
      initialOption = thisItem;
    }

    return initialOption;
  };
  const initialOption = getInitialOption();
  const [selectedOption, setSelectedOption] = useState(initialOption);

  const handleChange = (event: SelectChangeEvent<ILanguageValueEnum>): void => {
    const optValue = event.target.value as ILanguageValueEnum;
    const newSelectedOption = languagesOptionsArray.find(({ value }) => optValue === value) as ISelectOption;
    setSelectedOption(newSelectedOption);
  };

  const handleSave = () => {
    const selectedOptionValue = selectedOption.value as ILanguageValueEnum;
    dispatch(updateLanguageThunk(selectedOptionValue));
  };

  return (
    <PageSettingsContainer headerText={t("languageSettings")} fullHeight={false}>
      <Container>
        <div className={styles.content}>
          <div className={styles.top}>
            <p>{t("selectedLanguage")}</p>
            <SelectComponent
              customCssClass="minWidth"
              handleChange={handleChange}
              value={selectedOption.value}
              optionsArray={languagesOptionsArray}
            />
          </div>
        </div>
      </Container>
      <div className={styles.buttonContainer}>
        <CustomButton text={t("save")} onClick={handleSave} className={styles.saveBtn} />
      </div>
    </PageSettingsContainer>
  );
};
