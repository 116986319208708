import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";
import { BannersComponent } from "./BannersComponent";
import { CollectionPointPageHeaderComponent } from "./CollectionPointPageHeaderComponent";
import { CollectionPointTable } from "./CollectionPointTable";

import styles from "./CollectionPointPage.module.scss";

export const CollectionPointPage: React.FC = () => {
  return (
    <PageContainer HeaderComponent={CollectionPointPageHeaderComponent} module={MayorModulesType.CollectionPointModule}>
      <div className={styles.content}>
        <BannersComponent />
        <CollectionPointTable />
      </div>
    </PageContainer>
  );
};
