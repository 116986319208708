import { useSelector } from "react-redux";

import { getApplicationBreakpointState } from "../selectors/application";
import { ApplicationBreakpoint } from "../types/application";

interface ApplicationBreakpointProperties {
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isExtraLargeDesktop: boolean;
}
const breakpointsArr = Object.values(ApplicationBreakpoint);

const getIsAtLeastGivenBreakPoint = (appBreakpoint: ApplicationBreakpoint, limitBreakpoint: ApplicationBreakpoint) => {
  const enteredValueIndex = breakpointsArr.indexOf(limitBreakpoint);
  const filteredBreakpointsArr = breakpointsArr.filter((_, i) => i >= enteredValueIndex);
  const isAppBreakpointInFilteredArray = filteredBreakpointsArr.includes(appBreakpoint);

  return isAppBreakpointInFilteredArray;
};

export const useGetApplicationBreakpointProperties = (): ApplicationBreakpointProperties => {
  const appBreakpoint = useSelector(getApplicationBreakpointState);

  return {
    isTablet: getIsAtLeastGivenBreakPoint(appBreakpoint, ApplicationBreakpoint.tablet),
    isDesktop: getIsAtLeastGivenBreakPoint(appBreakpoint, ApplicationBreakpoint.desktop),
    isLargeDesktop: getIsAtLeastGivenBreakPoint(appBreakpoint, ApplicationBreakpoint.largeDesktop),
    isExtraLargeDesktop: getIsAtLeastGivenBreakPoint(appBreakpoint, ApplicationBreakpoint.extraLargeDesktop),
  };
};
