import { getWeighValue } from "../../../utils/collectionPoints/getWeighValue";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { getWasteCollectionSuccessRatesProperties } from "../../../utils/getWasteCollectionSuccessRatesProperties";

import styles from "./NumberBanner.module.scss";

export interface INumberBannerProps {
  value: number;
  unit?: string;
  label: string;
  adaptiveColor?: boolean;
  isSeparating?: boolean;
  doesWeigh?: boolean;
}

export const NumberBanner: React.FC<INumberBannerProps> = ({
  label,
  isSeparating = true,
  value,
  unit,
  adaptiveColor = false,
  doesWeigh = true,
}) => {
  const appliedClassNamesArray = [styles.numberBanner];
  switch (adaptiveColor) {
    case true: {
      const { appliedTextResourceCode } = getWasteCollectionSuccessRatesProperties(value, isSeparating);
      appliedClassNamesArray.push(styles[appliedTextResourceCode]);
      break;
    }
    case false:
      appliedClassNamesArray.push(styles.neutral);
      break;
  }

  return (
    <div className={getModuleClassNames(appliedClassNamesArray)}>
      <span className={styles.value}>
        {getWeighValue(doesWeigh, value)}
        {unit && ` ${unit}`}
      </span>
      <span className={styles.label}>{label}</span>
    </div>
  );
};
