import { forwardRef, ReactElement } from "react";
import { Button, useForkRef } from "@mui/material";
import { SingleInputDateRangeFieldProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";

import { getStringFromISOString } from "../../../utils/dateHandler";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { Icon, IconNameEnum } from "../../Icon";

import styles from "./CustomInput.module.scss";

interface CustomInputProps extends SingleInputDateRangeFieldProps<Dayjs> {
  disabled: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

type DateRangeButtonFieldComponent = ((
  props: CustomInputProps & React.RefAttributes<HTMLDivElement>
) => ReactElement) & { fieldType?: string };

export const CustomInput = forwardRef(
  (
    {
      disabled,
      open,
      setOpen,
      id,
      InputProps: { ref: containerRef } = {},
      inputProps: { "aria-label": ariaLabel } = {},
      value,
    }: CustomInputProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const handleRef = useForkRef(ref, containerRef);

    const placeholder = getStringFromISOString(Date());
    const appliedClassNames = [styles.inputButton];
    if (open) appliedClassNames.push(styles.inputActive);

    const getIsDateSelected = (index: number) => Boolean(value && value[index]?.isValid());

    const startSelected = getIsDateSelected(0);
    const endSelected = getIsDateSelected(1);

    const getDateText = (index: number) => {
      const isSelected = getIsDateSelected(index);
      const dateValue = value?.[index];
      if (!isSelected || !dateValue) {
        return placeholder;
      }
      return getStringFromISOString(dateValue.toISOString());
    };

    return (
      <Button
        className={getModuleClassNames(appliedClassNames)}
        id={id}
        disableRipple={true}
        disabled={disabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => setOpen?.((prev) => !prev)}
        endIcon={<Icon name={IconNameEnum.chevron} />}
      >
        <div>
          <span className={startSelected ? "" : styles.placeholder}>{getDateText(0)}</span>
          {" - "}
          <span className={endSelected ? "" : styles.placeholder}>{getDateText(1)}</span>
        </div>
      </Button>
    );
  }
) as DateRangeButtonFieldComponent;

CustomInput.fieldType = "single-input";
