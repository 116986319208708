import { isAnyOf } from "@reduxjs/toolkit";

import { selectYear } from "../../reducers/applicationReducer";
import { postWagonEventThunk } from "../../reducers/wagonEventCreateReducer";
import { deleteWagonEventInstanceThunk, updateWagonEventDetailThunk } from "../../reducers/wagonEventDetailReducer";
import {
  getWagonsCalendarThunk,
  setWagonsCalendarFilter,
  setWagonsCalendarPagination,
} from "../../reducers/wagonsCalendarReducer";
import { WagonsFilter } from "../../types/wagonEvent";
import { WAGONS_CALENDAR_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { getWagonsCalendarThunkRequestObject } from "./utils/getWagonsCalendarThunkRequestObject";
import { resetWagonsCalendar } from "./utils/resetWagonsCalendar";

export const wagonsCalendarListener = (): void => {
  startAppListening({
    matcher: isAnyOf(
      setWagonsCalendarFilter,
      postWagonEventThunk.fulfilled,
      updateWagonEventDetailThunk.fulfilled,
      deleteWagonEventInstanceThunk.fulfilled
    ),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;
      switch (pathname) {
        case WAGONS_CALENDAR_PAGE_PATH:
          resetWagonsCalendar(dispatch);
          break;

        default:
          break;
      }
    },
  });

  startAppListening({
    actionCreator: setWagonsCalendarPagination,
    effect: async (_, { dispatch, getState }) => {
      const requestObject = getWagonsCalendarThunkRequestObject(getState);

      dispatch(getWagonsCalendarThunk(requestObject));
    },
  });

  startAppListening({
    actionCreator: setWagonsCalendarFilter,
    effect: async (_, { dispatch, getState }) => {
      const state = getState();
      if (state.wagonsCalendar.filter === WagonsFilter.actual) {
        const currentYear = new Date().getFullYear();
        dispatch(selectYear({ label: currentYear.toString(), value: currentYear }));
      }
    },
  });
};
