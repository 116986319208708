import { RootState } from "../store";
import { IAuthState } from "../types/state";

export const getUserStatus = (state: RootState): IAuthState["status"] => state.auth.status;

export const getUserErrorMsg = (state: RootState): IAuthState["errorMsg"] => state.auth.errorMsg;

export const getUser = (state: RootState): IAuthState["user"] => state.auth.user;

export const getAdministrationName = (state: RootState): IAuthState["user"]["administrationName"] =>
  state.auth.user?.administrationName;

export const getJwtFromStore = (state: RootState): IAuthState["jwt"] => state.auth.jwt;

export const getLanguage = (state: RootState): IAuthState["user"]["language"] => state.auth.user.language;
