import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import { FixedTextInputSize, TextInput } from "../../components/TextInput";
import { Toggle } from "../../components/Toggle";
import { collectionYardDetailSettingsUpdateWasteType } from "../../reducers/collectionYardDetailSettingsReducer";
import { useAppDispatch } from "../../store";
import { ICollectionYardWasteType } from "../../types/collectionYardDetailSettings";
import { capitalizeString } from "../../utils/formatters/capitalizeString";
import { formatWasteTypeId } from "../../utils/formatWasteTypeId";

import styles from "./CollectionYardDetailSettingsPage.module.scss";

export const CollectionYardDetailSettingsWasteType: React.FC<ICollectionYardWasteType> = ({
  wasteTypeId,
  rate,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.item}>
      <div className={styles.wrapperToggle}>
        <Toggle
          checked={!disabled}
          onChange={(e) => {
            dispatch(
              collectionYardDetailSettingsUpdateWasteType({
                wasteTypeId,
                rate,
                disabled: !e.target.checked,
              })
            );
          }}
        />
        <Tooltip title={capitalizeString(t(`enums.allWasteTypes.${wasteTypeId}.long`))}>
          <div className={styles.wrapperWasteType}>
            <p>{capitalizeString(t(`enums.allWasteTypes.${wasteTypeId}.short`))}</p>
            <p className={styles.wasteTypeId}>{formatWasteTypeId(wasteTypeId)}</p>
          </div>
        </Tooltip>
      </div>
      <TextInput
        type="number"
        value={rate}
        isDisabled={disabled}
        fixedSize={FixedTextInputSize.fixedSm}
        endItem={<div className={styles.unit}>€ / kg</div>}
        onChange={(e) => {
          dispatch(
            collectionYardDetailSettingsUpdateWasteType({
              wasteTypeId,
              rate: e.target.value,
              disabled,
            })
          );
        }}
      />
    </div>
  );
};
