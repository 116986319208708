import { isAnyOf } from "@reduxjs/toolkit";

import {
  getInventorySheetListThunk,
  getInventorySheetSummaryThunk,
  removeInventorySheetFilter,
  setInventorySheetFilter,
  setInventorySheetListOrdering,
  setInventorySheetListPagination,
  setInventorySheetListSearch,
} from "../../reducers/inventorySheetReducer";
import { INVENTORY_SHEET_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { getInventorySheetListThunkRequestObject } from "./utils/getInventorySheetListThunkRequestObject";
import { resetInventorySheet } from "./utils/resetInventorySheet";

export const inventorySheetListListener = (): void => {
  startAppListening({
    matcher: isAnyOf(
      setInventorySheetListOrdering,
      setInventorySheetListSearch,
      setInventorySheetFilter,
      removeInventorySheetFilter
    ),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;
      switch (pathname) {
        case INVENTORY_SHEET_PAGE_PATH:
          resetInventorySheet(dispatch);
          break;

        default:
          break;
      }
    },
  });

  startAppListening({
    actionCreator: setInventorySheetListPagination,
    effect: async (_, { dispatch, getState }) => {
      const requestObject = getInventorySheetListThunkRequestObject(getState);
      dispatch(getInventorySheetListThunk(requestObject));
      dispatch(getInventorySheetSummaryThunk(requestObject));
    },
  });
};
