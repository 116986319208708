import { isAnyOf } from "@reduxjs/toolkit";

import { setLoaderVisibility } from "../../reducers/applicationReducer";
import { getLocationDoughnutChartDataThunk, getLocationSummaryThunk } from "../../reducers/locationReducer";
import {
  createWasteTypesQuantityYearThunk,
  getWasteTypesQuantityYearThunk,
  updateWasteTypesQuantityYearThunk,
} from "../../reducers/wasteTypesQuantityReducer";
import { startAppListening } from "./listenerMiddleware";

export const wasteTypesQuantityYearListener = (): void => {
  startAppListening({
    matcher: isAnyOf(updateWasteTypesQuantityYearThunk.fulfilled, createWasteTypesQuantityYearThunk.fulfilled),
    effect: async (_, { dispatch }) => {
      dispatch(getWasteTypesQuantityYearThunk());
      dispatch(getLocationSummaryThunk());
      dispatch(getLocationDoughnutChartDataThunk());
      dispatch(setLoaderVisibility(false));
    },
  });
};
