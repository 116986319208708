import { Children, CSSProperties, ReactNode } from "react";

import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { InnerCardComponent } from "./InnerCardComponent";

import styles from "./Card.module.scss";

interface CardProps {
  children: ReactNode;
  backgroundColor?: string;
  className?: string;
  style?: CSSProperties;
  fullWidth?: boolean;
  noFlex?: boolean;
  outerPaddingLeft?: number;
  outerPaddingRight?: number;
  contentPaddingTop?: number;
  contentPaddingBottom?: number;
  gap?: number;
  headerHeight?: number;
}

export const Card: React.FC<CardProps> = ({
  children,
  backgroundColor,
  style,
  className,
  fullWidth,
  noFlex = false,
  outerPaddingLeft,
  outerPaddingRight,
  contentPaddingTop,
  contentPaddingBottom,
  headerHeight,
  gap,
}) => {
  const appliedCardClassesArray = [styles.card];
  if (className) {
    appliedCardClassesArray.push(className);
  }
  if (fullWidth) {
    appliedCardClassesArray.push(styles.fullWidth);
  }

  if (noFlex) {
    appliedCardClassesArray.push(styles.noFlex);
  }

  const innerCardComponentProps = {
    outerPaddingLeft,
    outerPaddingRight,
    headerHeight,
    contentPaddingTop,
    contentPaddingBottom,
  };

  return (
    <div className={getModuleClassNames(appliedCardClassesArray)} style={{ ...style, backgroundColor, gap }}>
      {Children.map(children, (child, i) => (
        <InnerCardComponent child={child} i={i} {...innerCardComponentProps}>
          {children}
        </InnerCardComponent>
      ))}
    </div>
  );
};
