export const getSkeletonMockedArray = (templateObject: any, arrayLength: number, firstIndex: number): any[] => {
  const mockedSkeletonArray: any[] = Array(arrayLength)
    .fill({})
    .map((_, i) => {
      const mockedSkeletonObject: any = {
        ...templateObject,
        isMocked: true,
        id: firstIndex + i + 1,
      };
      return mockedSkeletonObject;
    });

  return mockedSkeletonArray;
};
