import { DefaultResourceKeys } from "../types/enums";
import { EnumName } from "./enums";

export const degreeOfSeparationWorstMax = 25;
export const degreeOfSeparationNormalMax = 50;

export const weightSumTotalMax = 300;
export const weightSumWorstMax = 200;
export const weightSumNormalMax = 100;

export const defaultResourceKeys: DefaultResourceKeys = {
  [EnumName.WasteTypes]: "enums.wastetypes.mixed",
  [EnumName.ApprovalLevels]: "",
  [EnumName.ContainerTypes]: "enums.containertypes.p120",
  [EnumName.Countries]: "",
  [EnumName.EventTypes]: "",
  [EnumName.GpsStatuses]: "",
  [EnumName.OrderTypes]: "",
  [EnumName.VehicleStates]: "",
  [EnumName.WasteSummaryWasteTypes]: "",
  [EnumName.ContainerNoteTypes]: "",
};

export const noMobileFeatureAdministrations = ["FCC Slovensko", "Horné Saliby", "Jablonové", "Rohožník"];
export const onlyCollectionPointFeatureAdministrations = ["Rohožník", "Jablonové"];
