import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CustomButton } from "../../../components/CustomButton";
import { Icon } from "../../../components/Icon";
import { IconNameEnum } from "../../../components/Icon";
import { YearSelect } from "../../../components/SelectComponent";
import { WASTE_COLLECTION_EDIT_PAGE_PATH } from "../../../middleware/routes";

import styles from "./HomePageHeaderComponent.module.scss";

export const HomePageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.controls}>
      <YearSelect />
      <Link to={WASTE_COLLECTION_EDIT_PAGE_PATH}>
        <CustomButton startIcon={<Icon name={IconNameEnum.plus} />} text={t("modifyData")} />
      </Link>
    </div>
  );
};
