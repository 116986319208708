import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { capitalizeString } from "../../../../utils/formatters/capitalizeString";
import { getFormattedWasteTypeCodeString } from "../../../../utils/formatters/getFormattedWasteTypeCodeString";

import styles from "./RowHeaderCell.module.scss";

interface RowHeaderCellProps {
  params: GridRenderCellParams;
}

export const RowHeaderCell: React.FC<RowHeaderCellProps> = ({ params }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.rowHeaderCell}>
      <div className={styles.textContainer}>
        <Tooltip title={capitalizeString(t(`enums.allWasteTypes.${params.id}.long`))}>
          <h4 className={styles.title}>{capitalizeString(t(`enums.allWasteTypes.${params.id}.short`))} [kg]</h4>
        </Tooltip>
        <p className={styles.wasteTypeCode}>{getFormattedWasteTypeCodeString(String(params.id))}</p>
      </div>
    </div>
  );
};
