export enum Column {
  header = "header",
  january = "january",
  february = "february",
  march = "march",
  april = "april",
  may = "may",
  june = "june",
  july = "july",
  august = "august",
  september = "september",
  october = "october",
  november = "november",
  december = "december",
}

export enum Header {
  header = "header",
}

export enum Month {
  january = "january",
  february = "february",
  march = "march",
  april = "april",
  may = "may",
  june = "june",
  july = "july",
  august = "august",
  september = "september",
  october = "october",
  november = "november",
  december = "december",
}

export enum WasteType {
  bio = "bio", // 1
  paper = "paper", // 2,
  plastics = "plastics", // 3,
  glass = "glass", // 4,
  mixed = "mixed", // 6,
  vkm = "vkm", // 8,
  metal = "metal", // 10,
  collectionyard = "collectionyard", // 99,
}

export enum DayOfWeek {
  sunday = "sunday",
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
}

export enum WagonEventPeriodicity {
  none = "none",
  sevenDays = "sevenDays",
  fourteenDays = "fourteenDays",
  twentyOneDays = "twentyOneDays",
  twentyEightDays = "twentyEightDays",
}

export enum Answer {
  yes = "yes",
  no = "no",
}

export enum NotificationsType {
  canceled = "canceled",
  holidays = "holidays",
}

export enum AllWasteTypes {
  "paper" = 200101,
  "glass" = 200102,
  "20 01 03" = 200103,
  "20 01 04" = 200104,
  "20 01 08" = 200108,
  "20 01 10" = 200110,
  "20 01 11" = 200111,
  "20 01 21" = 200121,
  "20 01 23" = 200123,
  "20 01 25" = 200125,
  "20 01 26" = 200126,
  "20 01 33" = 200133,
  "20 01 34" = 200134,
  "20 01 35" = 200135,
  "20 01 36" = 200136,
  "20 01 38" = 200138,
  "plastic" = 200139,
  "metal" = 200140,
  "20 01 40 01" = 20014001,
  "20 01 40 02" = 20014002,
  "20 01 40 03" = 20014003,
  "20 01 40 04" = 20014004,
  "20 01 40 05" = 20014005,
  "20 01 40 06" = 20014006,
  "20 01 40 07" = 20014007,
  "20 02 01" = 200201,
  "20 03 01" = 200301,
  "20 03 02" = 200302,
  "20 03 03" = 200303,
  "20 03 04" = 200304,
  "20 03 06" = 200306,
  "20 03 07" = 200307,
  "20 03 08" = 200308,
  "20 03 99" = 200399,

  "communal" = 200301,
  "recycled" = 999999998,
  "totalWaste" = 999999999,
  "other" = 999999997,
}

export const comunalWasteTypes = [
  AllWasteTypes["20 03 01"],
  AllWasteTypes["20 03 02"],
  AllWasteTypes["20 03 03"],
  AllWasteTypes["20 03 04"],
  AllWasteTypes["20 03 06"],
  AllWasteTypes["20 03 07"],
  AllWasteTypes["20 03 08"],
  AllWasteTypes["20 03 99"],
];

export enum EnumName {
  ApprovalLevels = "ApprovalLevels",
  ContainerTypes = "ContainerTypes",
  Countries = "Countries",
  EventTypes = "EventTypes",
  GpsStatuses = "GpsStatuses",
  OrderTypes = "OrderTypes",
  VehicleStates = "VehicleStates",
  WasteSummaryWasteTypes = "WasteSummaryWasteTypes",
  WasteTypes = "WasteTypes",
  ContainerNoteTypes = "ContainerNoteTypes",
}

export enum FeatureName {
  mobile = "mobile",
}

export const monthsArray = Object.values(Month);

export const wasteTypesArray = Object.values(WasteType);

export const allWasteTypesArray = Object.keys(AllWasteTypes).filter((x) => !isNaN(Number(x)));

export const tkoI = wasteTypesArray.indexOf(WasteType.mixed);

export const dayOfWeekArray = Object.values(DayOfWeek);

export const notificationsTypeArray = Object.values(NotificationsType);

export const wagonEventPeriodicityArray = Object.values(WagonEventPeriodicity);

export enum Colors {
  Orange = "#FCAC0B",
  Green = "#46AF1A",
  Red = "#EA5230",
  TerciaryText = "#88A6D1",
  SecondaryText = "#5b7289",
  Border = "#CFD6E0",
}

export enum DataGridColumnType {
  string = "string",
  date = "date",
  number = "number",
  boolean = "boolean",
}

export enum MapFilterType {
  normal = "normal",
  warning = "warning",
  noRfid = "noRfid",
}
