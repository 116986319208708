import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import {
  setCollectionPointsListPagination,
  setCollectionPointsListScrollTop,
} from "../../../reducers/collectionPointsReducer";
import {
  getCollectionPoints,
  getCollectionPointsLocations,
  getCollectionPointsScrollTop,
  getIsCollectionPointsListStatusLoading,
} from "../../../selectors/collectionPoints";
import { useGetCollectionPointsTableColumns } from "./useGetCollectionPointsTableColumns";
import { useGetCollectionPointsTableHandlers } from "./useGetCollectionPointsTableHandlers";

export const CollectionPointsTable: React.FC = () => {
  const data = useSelector(getCollectionPointsLocations);
  const columns = useGetCollectionPointsTableColumns();
  const isLoading = useSelector(getIsCollectionPointsListStatusLoading);
  const { handleSortModelChange, handleAdditionalRowClick } = useGetCollectionPointsTableHandlers();
  const scrollTop = useSelector(getCollectionPointsScrollTop);
  const collectionPointsState = useSelector(getCollectionPoints);

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setCollectionPointsListScrollTop}
      setPagination={setCollectionPointsListPagination}
      isLoading={isLoading}
      handleSortModelChange={handleSortModelChange}
      sliceState={collectionPointsState}
      handleAdditionalRowClick={handleAdditionalRowClick}
    />
  );
};
