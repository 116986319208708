import { resetApplicationState } from "../../../reducers/applicationReducer";
import { resetCollectionPointState } from "../../../reducers/collectionPointReducer";
import { resetCollectionPointsState } from "../../../reducers/collectionPointsReducer";
import { resetCollectionYardsState } from "../../../reducers/collectionYardsReducer";
import { resetInventorySheetState } from "../../../reducers/inventorySheetReducer";
import { resetLocationState } from "../../../reducers/locationReducer";
import { resetNotificationState } from "../../../reducers/notificationReducer";
import { resetSettingsState } from "../../../reducers/settingsReducer";
import { resetWagonsDatePickerState } from "../../../reducers/wagonsDatePickerReducer";
import { resetWagonsReportState } from "../../../reducers/wagonsReportStateReducer";
import { resetWasteTypesQuantityState } from "../../../reducers/wasteTypesQuantityReducer";
import { resetWeightHistoryState } from "../../../reducers/weightHistoryReducer";
import { AppDispatch } from "../../../store";

export const logoutUser = (dispatch: AppDispatch): void => {
  dispatch(resetApplicationState());
  dispatch(resetCollectionPointState());
  dispatch(resetCollectionPointsState());
  dispatch(resetLocationState());
  dispatch(resetNotificationState());
  dispatch(resetSettingsState());
  dispatch(resetWasteTypesQuantityState());
  dispatch(resetWeightHistoryState());
  dispatch(resetWagonsReportState());
  dispatch(resetWagonsDatePickerState());
  dispatch(resetCollectionYardsState());
  dispatch(resetInventorySheetState());
};
