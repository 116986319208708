import { isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import {
  defaultContainer,
  getCollectionPointDetailThunk,
  inviteToMobileAppThunk,
  setCollectionPointContainers,
  setCollectionPointDetailPagination,
  setCollectionPointIsPaidStatus,
} from "../../reducers/collectionPointReducer";
import { replaceCollectionPoint } from "../../reducers/collectionPointsReducer";
import { InvitiationStatus, PaymentStatus } from "../../types/collectionPoint";
import { ICollectionPointLocation } from "../../types/location";
import { getSkeletonMockedArray } from "../../utils/tables/getSkeletonMockedArray";
import { startAppListening } from "./listenerMiddleware";

export const collectionPointDetailListener = (): void => {
  startAppListening({
    matcher: isAnyOf(getCollectionPointDetailThunk.pending),
    effect: async (_, { getState, dispatch }) => {
      const state = getState();
      const collectionPoint = state.collectionPoint.collectionPoint;
      const page = state.collectionPoint.pagination.page;
      const collectionPoints = state.collectionPoints.locations;
      const collectionPointLocation = collectionPoints.find(
        (location) => location.locationId === collectionPoint.locationId
      );
      const containers = collectionPoint.containers;

      if (collectionPointLocation && page === 1) {
        const mockedContainers = getSkeletonMockedArray(
          defaultContainer,
          collectionPointLocation.containerCount,
          containers.length
        );
        dispatch(setCollectionPointContainers(mockedContainers));
      }

      if (page > 1) {
        const mockedContainers = getSkeletonMockedArray(defaultContainer, 3, containers.length);
        const newContainers = [...containers, ...mockedContainers];
        dispatch(setCollectionPointContainers(newContainers));
      }
    },
  });
  startAppListening({
    matcher: isAnyOf(inviteToMobileAppThunk.pending),
    effect: async (_, { getState, dispatch }) => {
      const state = getState();
      const collectionPointId = state.collectionPoint.collectionPoint.locationId;
      const collectionPoints = state.collectionPoints.locations;
      const thisCollectionPoint = collectionPoints.find((point) => point.locationId === collectionPointId);

      if (thisCollectionPoint) {
        const newCollectionPoint: ICollectionPointLocation = {
          ...thisCollectionPoint,
          invitationStatus: InvitiationStatus.Invited,
        };
        dispatch(replaceCollectionPoint(newCollectionPoint));
      }
    },
  });

  startAppListening({
    matcher: isAnyOf(setCollectionPointIsPaidStatus),
    effect: async (action: PayloadAction<PaymentStatus>, { getState, dispatch }) => {
      const state = getState();
      const isPaid = action.payload;
      const collectionPointId = state.collectionPoint.collectionPoint.locationId;
      const collectionPoints = state.collectionPoints.locations;
      const thisCollectionPoint = collectionPoints.find((point) => point.locationId === collectionPointId);

      if (thisCollectionPoint) {
        const newCollectionPoint: ICollectionPointLocation = {
          ...thisCollectionPoint,
          isPaid,
        };
        dispatch(replaceCollectionPoint(newCollectionPoint));
      }
    },
  });

  startAppListening({
    actionCreator: setCollectionPointDetailPagination,
    effect: async (_, { dispatch, getState }) => {
      const state = getState();
      const collectionPointId = state.collectionPoint.collectionPoint.locationId;

      dispatch(getCollectionPointDetailThunk(collectionPointId));
    },
  });
};
