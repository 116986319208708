import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

import { MenuButton } from "../../../components/MenuButton";
import { SearchBar } from "../../../components/SearchBar";
import { YearSelect } from "../../../components/SelectComponent";
import {
  defaultSearch,
  exportCollectionYardsEventsToXlsxThunk,
  exportCollectionYardsVisitsToXlsxThunk,
  setCollectionYardsListSearch,
} from "../../../reducers/collectionYardsReducer";
import { getCollectionYardsSearch } from "../../../selectors/collectionYards";

import styles from "./CollectionYardsPageHeaderComponent.module.scss";

export const CollectionYardsPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentSearch = useSelector(getCollectionYardsSearch);
  const [search, setSearch] = useState(currentSearch.fulltext);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const value = event.target.value;

    if (!value && currentSearch.fulltext) {
      dispatch(setCollectionYardsListSearch(defaultSearch));
    }
    setSearch(value);
  };

  const handleSearch = (): void => {
    dispatch(setCollectionYardsListSearch({ fulltext: search }));
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={styles.controls}>
      <YearSelect />
      <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} onKeyDown={handleKeyPress} />
      <MenuButton>
        <MenuItem onClick={() => dispatch(exportCollectionYardsVisitsToXlsxThunk())}>{t("exportVisitsXLS")}</MenuItem>
        <MenuItem onClick={() => dispatch(exportCollectionYardsEventsToXlsxThunk())}>{t("exportWeightsXLS")}</MenuItem>
      </MenuButton>
    </div>
  );
};
