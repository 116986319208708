import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { IModal } from "../types/modal";
import { useModal } from "./useModal";

interface HandleLeavePromptProperties extends IModal {
  handleContinue: () => void;
}

export const useHandleLeavePrompt = (
  when: boolean,
  ignoredRefs?: React.MutableRefObject<any>[]
): HandleLeavePromptProperties => {
  const [targetPathname, setTargetPathname] = useState<string>("");
  const { isModalVisible, toggleModalVisibility: toggle } = useModal();
  const history = useHistory();
  const unblockRef = useRef<any>(null);

  const handleRouteChangeClick = (e: MouseEvent) => {
    const isIgnored = ignoredRefs && ignoredRefs.find((ref) => ref.current.contains(e.target));

    if (isIgnored) {
      unblockRef.current = history.block(() => {
        return;
      });
      return;
    }

    unblockRef.current = history.block((location) => {
      if (when) {
        setTargetPathname(location.pathname);
        toggle();
        return false;
      }
      return;
    });
  };

  useEffect(() => {
    document.addEventListener("click", handleRouteChangeClick, true);
    return () => {
      document.removeEventListener("click", handleRouteChangeClick, true);
    };
  }, [unblockRef, history, ignoredRefs]);

  const handleContinue = (): void => {
    if (unblockRef && unblockRef.current) {
      unblockRef.current();
    }
    toggle();
    history.push(targetPathname);
  };

  const toggleModalVisibility = () => {
    unblockRef.current = history.block(() => {
      return;
    });
    toggle();
  };

  return {
    isModalVisible,
    toggleModalVisibility,
    handleContinue,
  };
};
