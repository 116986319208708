import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
/* eslint-disable no-console */
const setJwtToLocalStorageFromCookie = (appKey: string): void => {
  console.log(`replace ${getJwtFromLocalStorage()} by ${appKey}`);
  setJwtToLocalStorage(appKey);
  setLatestAppKeyFromLocalStorage(appKey);
};

const checkJwtAndCookie = (): void => {
  const newAppKey = Cookies.get("app_key");
  const oldAppKey = getLatestAppKeyFromLocalStorage();

  if (!newAppKey) {
    console.log("no app_key cookie defined");
    return;
  }

  if (!oldAppKey) {
    console.log("no latest app key defined");
    return setJwtToLocalStorageFromCookie(newAppKey);
  }

  type TokenBody = {
    id: number;
    iat: number;
    exp: number;
  };

  try {
    const newIssuedAt = jwt_decode<TokenBody>(newAppKey).iat;
    const oldIssuedAt = jwt_decode<TokenBody>(oldAppKey).iat;

    if (newIssuedAt > oldIssuedAt) {
      console.log("app_key cookie is younger than latest app key");
      return setJwtToLocalStorageFromCookie(newAppKey);
    }
    console.log("app_key cookie is older than latest app key");
  } catch (e) {
    console.log("token is invalid");
  }
};

export const getJwtFromLocalStorage = (checkCookie?: boolean): string => {
  if (checkCookie) {
    checkJwtAndCookie();
  }

  const jwtFromLocalStorage = window.localStorage.getItem("jwt") ?? "";

  return jwtFromLocalStorage;
};

export const setJwtToLocalStorage = (jwt: string): void => {
  window.localStorage.setItem("jwt", jwt);
};

export const getLatestAppKeyFromLocalStorage = (): string | null => {
  return window.localStorage.getItem("latest_app_key");
};

export const setLatestAppKeyFromLocalStorage = (latestAppKey: string): void => {
  window.localStorage.setItem("latest_app_key", latestAppKey);
};

export const removeJwtFromLocalStorage = (): void => {
  window.localStorage.removeItem("jwt");
};

export const setVolumeToLocalStorage = (volume: number): void => {
  window.localStorage.setItem("volume", volume.toString());
};

export const getVolumeFromLocalStorage = (): number => {
  const volume = window.localStorage.getItem("volume");
  if (volume === null) {
    return 50;
  }
  return Number(volume);
};
