import { useSelector } from "react-redux";

import { getMayorModules } from "../selectors/mayorModules";
import { BottomMayorModulesType, MayorModuleStatus, TopMayorModulesType } from "../types/mayorModules";

export const useGetActiveModules = (): {
  topActiveModules: TopMayorModulesType[];
  bottomActiveModules: BottomMayorModulesType[];
} => {
  const modules = useSelector(getMayorModules);
  const topActiveModules: TopMayorModulesType[] = [];
  const bottomActiveModules: BottomMayorModulesType[] = [];
  for (const module in TopMayorModulesType) {
    const typedModule = module as TopMayorModulesType;
    if (modules[typedModule] !== MayorModuleStatus.hidden) topActiveModules.push(typedModule);
  }
  for (const module in BottomMayorModulesType) {
    const typedModule = module as BottomMayorModulesType;
    if (modules[typedModule] !== MayorModuleStatus.hidden) bottomActiveModules.push(typedModule);
  }
  return { topActiveModules, bottomActiveModules };
};
