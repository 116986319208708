import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_APPLICATION_ENUMS_URL } from "../middleware/routes";
import { ApplicationBreakpoint, ApplicationMode, IApplicationState } from "../types/application";
import { IServerEnumObject } from "../types/enums";
import { ISelectOption } from "../types/select";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { lastYears } from "../utils/yearsMocker";

const initApplicationState: IApplicationState = {
  applicationMode: ApplicationMode.light,
  applicationBreakpoint: ApplicationBreakpoint.desktop,
  sidePanelVisibility: false,
  loaderVisibility: true,
  uploadFileStatus: Status.idle,
  applicationEnums: [] as IServerEnumObject[],
  years: {
    lastYears,
    selectedYear: lastYears[0],
  },
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initApplicationState,
  reducers: {
    setApplicationMode: (state: IApplicationState, action: PayloadAction<ApplicationMode>) => {
      state.applicationMode = action.payload;
    },
    setApplicationBreakpoint: (state: IApplicationState, action: PayloadAction<ApplicationBreakpoint>) => {
      state.applicationBreakpoint = action.payload;
    },
    setSidePanelVisibility: (state: IApplicationState, action: PayloadAction<boolean>) => {
      state.sidePanelVisibility = action.payload;
    },
    selectYear: (state: IApplicationState, action: PayloadAction<ISelectOption>) => {
      state.years.selectedYear = action.payload;
    },
    setLoaderVisibility: (state: IApplicationState, action: PayloadAction<boolean>) => {
      state.loaderVisibility = action.payload;
    },
    resetApplicationState: () => {
      return initApplicationState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationEnumsThunk.fulfilled, (state, action) => {
        state.applicationEnums = action.payload.enums;
      })
      .addCase(getApplicationEnumsThunk.rejected, (state) => {
        state.applicationEnums = [];
      });
  },
});

export const getApplicationEnumsThunk = createAsyncThunk("application/getApplicationEnumsThunk", async () => {
  const response = await axiosRequestApi.get(GET_APPLICATION_ENUMS_URL);
  return response.data;
});

export const {
  setApplicationMode,
  setApplicationBreakpoint,
  setSidePanelVisibility,
  selectYear,
  setLoaderVisibility,
  resetApplicationState,
} = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;
