import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CustomButton } from "../../../components/CustomButton";
import { YearSelect } from "../../../components/SelectComponent";
import { CREATE_WAGON_EVENT_PAGE_PATH } from "../../../middleware/routes";
import { WagonsFilter } from "../../../types/wagonEvent";
import { WagonsFilterButton } from "../WagonsFilterButton";

import styles from "./WagonsCalendarPageHeaderComponent.module.scss";

const filterButtonsArray = [
  {
    type: WagonsFilter.past,
  },
  {
    type: WagonsFilter.actual,
  },
];

export const WagonsCalendarPageHeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerContainer}>
      <h2>{t("wagonsCalendar")}</h2>
      <div className={styles.filters}>
        <span>{t("show")}: </span>
        {filterButtonsArray.map((filter) => (
          <WagonsFilterButton key={filter.type} type={filter.type} />
        ))}
      </div>
      <div className={styles.inputs}>
        <YearSelect since={2023} numberOfYears={2} />
        <Link to={CREATE_WAGON_EVENT_PAGE_PATH}>
          <CustomButton text={t("wagonEventCreate")} />
        </Link>
      </div>
    </div>
  );
};
