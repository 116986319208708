import { useTranslation } from "react-i18next";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { TableCellWithRightArrow } from "../../../components/TableCellWithRightArrow";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { IColumnAlignObject, IColumnValuePair } from "../../../types/table";
import { numberToWasteType } from "../../../utils/enumMapping";
import { EnumName, WasteType } from "../../../utils/enums";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { WasteTypeCell } from "../../WagonsCalendarPage/WagonsCalendarTable/WasteTypeCell";
import { columnLabels, ColumnType } from "./InventorySheetTableColumnTypes";
import { InventorySheetTableHeaderCell } from "./InventorySheetTableHeaderCell";

const columnAlignObject: IColumnAlignObject<ColumnType> = {
  center: [],
};

const columnFlexValuePairs: IColumnValuePair<ColumnType>[] = [
  {
    name: ColumnType.collectionPointName,
    value: 2,
  },
  {
    name: ColumnType.street,
    value: 2,
  },
  {
    name: ColumnType.wasteType,
    value: 2,
  },
];

const columnMinWidthValuePairs: IColumnValuePair<ColumnType>[] = [];

export const useGetInventorySheetTableColumns = () => {
  const { t } = useTranslation();
  const { getAlign, getFlex, getMinWidth } = getTableColumnUtils();
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <InventorySheetTableHeaderCell params={params} />;
      },
      flex: getFlex(label, columnFlexValuePairs),
      align: getAlign(label, columnAlignObject),
      headerAlign: getAlign(label, columnAlignObject),
      minWidth: getMinWidth(label, columnMinWidthValuePairs),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case ColumnType.wasteType: {
          const wasteType = numberToWasteType.get(params.value) ?? WasteType.mixed;

          return <WasteTypeCell wasteType={wasteType} />;
        }
        case ColumnType.containerType: {
          return getTranslatedEnumResourceCode(EnumName.ContainerTypes, params.value);
        }
        case ColumnType.householdMembersCount: {
          return <TableCellWithRightArrow mainComponent={<p style={{ textAlign: "center" }}>{params.value}</p>} />;
        }

        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
