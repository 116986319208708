import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PageContainer } from "../../components/PageContainer";
import { setDummyData } from "../../reducers/wagonsReportStateReducer";
import { isWagonsModuleInactive } from "../../selectors/mayorModules";
import { getWagonsReportIsIdle, getWagonsReportIsLoading, isMapViewActive } from "../../selectors/wagonsReport";
import { MayorModulesType } from "../../types/mayorModules";
import { WagonsInitOverlay } from "./WagonsInitOverlay";
import { WagonsMap } from "./WagonsMap";
import { WagonsPageHeaderComponent } from "./WagonsPageHeaderComponent";
import { WagonsReport } from "./WagonsReport";

import styles from "./WagonsPage.module.scss";

export const WagonsPage: React.FC = () => {
  const { t } = useTranslation();
  const isIdle = useSelector(getWagonsReportIsIdle);
  const isLoading = useSelector(getWagonsReportIsLoading);
  const isModuleInactive = useSelector(isWagonsModuleInactive);
  const showMap = useSelector(isMapViewActive);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isModuleInactive) dispatch(setDummyData());
  }, []);

  return (
    <PageContainer
      headerText={t("wagons")}
      module={MayorModulesType.WagonModule}
      HeaderComponent={WagonsPageHeaderComponent}
      hasDemo={true}
      fullScreen={showMap}
      displayPremium={isModuleInactive}
    >
      {showMap ? (
        <WagonsMap />
      ) : (
        <div className={styles.content}>{isIdle || isLoading ? <WagonsInitOverlay /> : <WagonsReport />}</div>
      )}
    </PageContainer>
  );
};
