import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { COLLECTION_POINT_PAGE_PATH } from "../../../../middleware/routes";
import {
  cleanUpCollectionPointDetail,
  getCollectionPointDetailThunk,
} from "../../../../reducers/collectionPointReducer";

export const useGetWagonsReportTableHandlers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const openLocationDetail = (locationId: number) => {
    dispatch(cleanUpCollectionPointDetail());
    dispatch(getCollectionPointDetailThunk(locationId));

    history.push(`${COLLECTION_POINT_PAGE_PATH}?id=${locationId}`);
  };

  return {
    openLocationDetail,
  };
};
