import { useSelector } from "react-redux";

import { Footer } from "../../components/Footer";
import { getApplicationModeState } from "../../selectors/application";
import { classHandler } from "../../utils/classHandler";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { LeftSegment } from "./LeftSegment";
import { RightSegment } from "./RightSegment";

import styles from "./LoginPage.module.scss";

export const LoginPage: React.FC = () => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div
      className={getModuleClassNames(classHandler(styles, "userAuthLayout", applicationMode))}
      style={{
        backgroundImage: `linear-gradient(to top,rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 20%), url(${process.env.PUBLIC_URL}/img/meadow.jpg)`,
      }}
    >
      <LeftSegment />
      <RightSegment />
      <Footer />
    </div>
  );
};
