import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_COLLECTION_YARDS_EVENTS_LIST_URL, POST_COLLECTION_YARD_VISITS_LIST_URL } from "../middleware/routes";
import { RootState } from "../store";
import { ICollectionYardsState, ICollectionYardsVisit } from "../types/collectionYards";
import { IFilterItem, IFilters } from "../types/filters";
import { IOrdering, IPagination } from "../types/pagination";
import { ISearch } from "../types/search";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { downloadFile } from "../utils/download";
import { formatCollectionYardsEventsForXlsx, formatCollectionYardsVisitsForXlsx } from "../utils/formatData";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { createXlsxFile } from "../utils/xlsx";
import { adaptRequestBodyForBackend, adaptResponseForFronted } from "./utils/collectionYardsAdapt";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "created",
  descending: true,
};

export const defaultSearch = {
  fulltext: "",
};

const defaultVisit: ICollectionYardsVisit = {
  id: 0,
  locationId: 0,
  wasteTypes: [],
  wasteSum: 0,
  priceSum: 0,
  paid: false,
  created: "",
  locationName: "",
  collectionYardName: "",
};

const defaultVisits: ICollectionYardsVisit[] = getSkeletonMockedArray(defaultVisit, 50, 0);

const defaultFilters: IFilters = {
  items: [],
  linkOperator: "and",
};

const initCollectionYardsState: ICollectionYardsState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  visits: defaultVisits,
  isNextPageEnable: false,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  filters: defaultFilters,
  search: defaultSearch,
  scrollTop: 0,
};

export const collectionYardsSlice = createSlice({
  name: "collectionYards",
  initialState: initCollectionYardsState,
  reducers: {
    cleanUpCollectionYardsList: (state: ICollectionYardsState) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.visits = defaultVisits;
      state.isNextPageEnable = false;
    },
    replaceCollectionYards: (state: ICollectionYardsState, action: PayloadAction<ICollectionYardsVisit>) => {
      const visitsPoints = [...state.visits];
      const newVisits = visitsPoints.map((visit) => {
        if (visit.id === action.payload.id) {
          return action.payload;
        }
        return visit;
      });

      state.visits = newVisits;
    },
    setCollectionYardsListPagination: (state: ICollectionYardsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setCollectionYardsListOrdering: (state: ICollectionYardsState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setCollectionYardsFilter: (state, action: PayloadAction<IFilterItem>) => {
      const findIdx = state.filters.items.findIndex((item) => item.id === action.payload.id);
      if (findIdx !== -1) {
        state.filters.items[findIdx] = action.payload;
      } else state.filters.items.push(action.payload);
    },
    removeCollectionYardsFilter: (state, action: PayloadAction<string>) => {
      state.filters.items = state.filters.items.filter((item) => !item.id?.toString().startsWith(action.payload));
    },
    setCollectionYardsListSearch: (state: ICollectionYardsState, action: PayloadAction<ISearch>) => {
      state.search = action.payload;
    },
    setCollectionYardsListScrollTop: (state: ICollectionYardsState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetCollectionYardsState: () => {
      return initCollectionYardsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionYardsVisitsThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedContainers = getSkeletonMockedArray(defaultVisits, 3, state.visits.length);
          state.visits = [...state.visits, ...mockedContainers];
        }
      })
      .addCase(getCollectionYardsVisitsThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        const containersFromState: ICollectionYardsVisit[] = state.visits;
        const containersFromResponse: ICollectionYardsVisit[] = action.payload;
        const newVisits = getSkeletonSuccessArray(containersFromState, containersFromResponse);

        state.visits = newVisits;

        let isNextPageEnable = true;
        if (action.payload.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getCollectionYardsVisitsThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export const getCollectionYardsVisitsThunk = createAsyncThunk<any, void, { state: RootState }>(
  "collectionYards/getCollectionYardsVisitsThunk",
  async (_, { getState }) => {
    const state = getState();

    const requestObject = adaptRequestBodyForBackend(state);
    const response = await axiosRequestApi.post(POST_COLLECTION_YARD_VISITS_LIST_URL, requestObject);

    return adaptResponseForFronted(response.data);
  }
);

export const exportCollectionYardsVisitsToXlsxThunk = createAsyncThunk<any, void, { state: RootState }>(
  "collectionPoints/exportCollectionYardsVisitsToXlsxThunk",
  async (_, { getState }) => {
    const state = getState();

    const requestObject = adaptRequestBodyForBackend(state);
    requestObject.pagination = {
      limit: 100_000_000,
      page: 1,
    };

    const data = await axiosRequestApi.post(POST_COLLECTION_YARD_VISITS_LIST_URL, requestObject);
    const adaptedData = adaptResponseForFronted(data.data);

    const formattedDataXlsx = formatCollectionYardsVisitsForXlsx(adaptedData);
    const file = createXlsxFile(formattedDataXlsx);
    downloadFile(file, "collection-yards-visits.xlsx");
  }
);

export const exportCollectionYardsEventsToXlsxThunk = createAsyncThunk<any, void, { state: RootState }>(
  "collectionPoints/exportCollectionYardsEventsToXlsxThunk",
  async (_, { getState }) => {
    const state = getState();
    const year = state.application.years.selectedYear.value;

    const data = await axiosRequestApi.get(`${GET_COLLECTION_YARDS_EVENTS_LIST_URL}/${year}`);

    const formattedDataXlsx = formatCollectionYardsEventsForXlsx(data.data);
    const file = createXlsxFile(formattedDataXlsx);
    downloadFile(file, "collection-yards-events.xlsx");
  }
);

export const {
  cleanUpCollectionYardsList,
  setCollectionYardsListPagination,
  setCollectionYardsListOrdering,
  setCollectionYardsListSearch,
  setCollectionYardsListScrollTop,
  replaceCollectionYards,
  resetCollectionYardsState,
  setCollectionYardsFilter,
  removeCollectionYardsFilter,
} = collectionYardsSlice.actions;

export const collectionYardsReducer = collectionYardsSlice.reducer;
