import { useSelector } from "react-redux";

import { ContainerNoteBanner } from "../../components/ContainerNoteBanner";
import { PageContainer } from "../../components/PageContainer";
import { getWeightHistoryContainer } from "../../selectors/weightHistory";
import { MayorModulesType } from "../../types/mayorModules";
import { WeightHistoryPageHeaderComponent } from "./WeightHistoryPageHeaderComponent";
import { WeightHistoryTable } from "./WeightHistoryTable";

import styles from "./WeightHistoryPage.module.scss";

export const WeightHistoryPage: React.FC = () => {
  const { noteType } = useSelector(getWeightHistoryContainer);

  return (
    <PageContainer HeaderComponent={WeightHistoryPageHeaderComponent} module={MayorModulesType.CollectionPointModule}>
      <div className={styles.contentWrapper}>
        <ContainerNoteBanner noteType={noteType} />
        <div className={styles.content}>
          <WeightHistoryTable />
        </div>
      </div>
    </PageContainer>
  );
};
