import { useCallback } from "react";
import { useSelector } from "react-redux";

import { getLanguage } from "../selectors/auth";
import { LanguageDateLocaleEnum } from "../types/localization";

interface FormatNumberToThousandsStringProperties {
  getFormattedThousandsString: (value: number) => string;
}

export const useFormatNumberToShousandsString = (): FormatNumberToThousandsStringProperties => {
  const language = useSelector(getLanguage);
  const languageDateLocale = LanguageDateLocaleEnum[language];
  const getFormattedThousandsString = useCallback(
    (value: number): string => value.toLocaleString(languageDateLocale),
    [languageDateLocale]
  );

  return { getFormattedThousandsString };
};
