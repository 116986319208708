import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { formatCurrency } from "../../utils/currency";

interface ITablePriceCellProps {
  params: GridRenderCellParams<number>;
}

export const TablePriceCell: React.FC<ITablePriceCellProps> = ({ params }) => {
  return <p>{formatCurrency(!params.value ? 0 : params.value)}</p>;
};
