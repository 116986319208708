import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Icon, IconNameEnum } from "../../../../components/Icon";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { isWagonsModuleInactive } from "../../../../selectors/mayorModules";
import { IColumnValuePair } from "../../../../types/table";
import { getDateTimeStrings } from "../../../../utils/dateHandler";
import { DataGridColumnType, EnumName } from "../../../../utils/enums";
import { getTableColumnUtils } from "../../../../utils/tables/getTableColumnUtils";
import { useGetWagonsReportTableHandlers } from "./useGetWagonsReportTableHandlers";
import { WagonsReportTableHeader } from "./WagonsReportTableHeader";
import {
  wagonReportTableColumnOperatorMap,
  wagonReportTableColumnType,
  wagonReportTableColumnTypeMap,
} from "./WagonsReportTableUtils";

import styles from "./WagonsReportTable.module.scss";

const columnFlexValuePairs: IColumnValuePair<wagonReportTableColumnType>[] = [
  {
    name: wagonReportTableColumnType.date,
    value: 2,
  },
  {
    name: wagonReportTableColumnType.location,
    value: 3,
  },
  {
    name: wagonReportTableColumnType.rfid,
    value: 2,
  },
];

const columnLabels = Object.values(wagonReportTableColumnType);

export const useGetWagonsReportTalbeColumns = () => {
  const { getFlex } = getTableColumnUtils();
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  const { openLocationDetail } = useGetWagonsReportTableHandlers();
  const isModuleInactive = useSelector(isWagonsModuleInactive);

  const columns: GridColDef[] = columnLabels.map((label) => {
    const type = wagonReportTableColumnTypeMap.get(label) ?? DataGridColumnType.string;
    const columnObject: GridColDef = {
      field: label,
      type,
      flex: getFlex(label, columnFlexValuePairs),
      align: "left",
      headerAlign: "left",
      filterOperators: wagonReportTableColumnOperatorMap.get(type),
    };

    columnObject.renderHeader = (params: GridColumnHeaderParams) => {
      return <WagonsReportTableHeader params={params} />;
    };

    columnObject.renderCell = (params: GridRenderCellParams<any>) => {
      switch (label) {
        case wagonReportTableColumnType.date:
          if (!params.value) return;
          {
            const formatedDate = getDateTimeStrings(new Date(params.value));
            return (
              <div>
                <span style={{ marginRight: "18px" }}>{formatedDate.date}</span>
                <span>{formatedDate.time}</span>
              </div>
            );
          }
        case wagonReportTableColumnType.location:
          return (
            <div className={styles.locationCell}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (isModuleInactive) return;
                  openLocationDetail(params.row.locationId);
                }}
                style={{ marginRight: "6px" }}
              >
                {params.value && <Icon name={IconNameEnum.location} />}
              </IconButton>
              <span>{params.value}</span>
            </div>
          );
        case wagonReportTableColumnType.containerType:
          if (!params.value) return;
          return getTranslatedEnumResourceCode(EnumName.ContainerTypes, params.value);
        case wagonReportTableColumnType.weight:
          return (
            <div className={styles.weightCell}>
              <span>{params.value} kg</span> {params.row.warning && <Icon name={IconNameEnum.warning} />}
            </div>
          );
        case wagonReportTableColumnType.rfid:
          return (
            <div className={styles.rfidCell}>
              <p>{params.value}</p>
              <Icon name={IconNameEnum.chevron} />
            </div>
          );
        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
