import { IReportSummary, ISummaryContainer, IWagonsReport } from "../../types/wagons";

export const createSummary = (list: IWagonsReport["list"]): IReportSummary => {
  const distinctContainerCountByType = list.reduce((acc: { [key: number]: Set<number> }, item) => {
    if (item.containerType === null || item.containerType === 0) return acc;

    if (!acc[item.containerType]) {
      acc[item.containerType] = new Set();
    }

    if (item.containerId !== null) acc[item.containerType].add(item.containerId);

    return acc;
  }, {});

  const uniqueContainers = Object.keys(distinctContainerCountByType).map((containerType) => ({
    containerType: parseInt(containerType),
    count: distinctContainerCountByType[parseInt(containerType)].size,
  }));

  const containers = list.reduce((acc: ISummaryContainer[], item) => {
    if (item.containerType === null || item.containerType === 0) return acc;
    const findIdx = acc.findIndex((container) => container.containerType === item.containerType);

    if (findIdx !== -1) {
      acc[findIdx].count += 1;
    } else {
      acc.push({ containerType: item.containerType, count: 1 });
    }

    return acc;
  }, []);

  const change = list.length - containers.reduce((acc, item) => acc + item.count, 0);
  if (change !== 0) containers.push({ containerType: 0, count: change });

  return {
    found: list.length,
    containers,
    uniqueContainers,
    noRfid: list.filter((item) => item.rfid === null).length,
    totalWeight: list.reduce((acc, item) => acc + item.weight, 0),
  };
};
