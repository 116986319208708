import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CREATE_LOCATION_WASTE_TYPES_QUANTITY_URL,
  GET_LOCATION_WASTE_TYPES_QUANTITY_URL,
  UPDATE_LOCATION_WASTE_TYPES_QUANTITY_URL,
} from "../middleware/routes";
import { RootState } from "../store";
import { ISelectOption } from "../types/select";
import { IWasteTypesQuantityState, Status } from "../types/state";
import {
  IWasteSummary,
  IWasteSummaryCreateResponse,
  IWasteSummaryGetResponse,
  IWasteTypeSummary,
} from "../types/wasteTypeQuantity";
import { axiosRequestApi } from "../utils/axiosRequest";
import { allWasteTypesArray, monthsArray } from "../utils/enums";
import { getSummariesWithoutTotals } from "../utils/wasteTypeSummary/dataTransform";
import { getUpdatedWasteTypeObject } from "../utils/wasteTypeSummary/getUpdatedWasteTypeObject";
import { getWasteSummaryProperties } from "../utils/wasteTypeSummary/getWasteSummaryProperties";
import { lastYears } from "../utils/yearsMocker";

const initWasteTypeSummaries: IWasteTypeSummary[] = allWasteTypesArray.map((wasteType) => {
  const monthObj: IWasteTypeSummary = {
    wasteType: Number(wasteType),
  } as IWasteTypeSummary;
  monthsArray.forEach((month) => {
    monthObj[month] = 0;
  });
  return monthObj;
});

const initWasteSummaryDetail: IWasteSummary = {
  wasteSummaryId: null,
  year: Number(lastYears[0].value),
  wasteTypeSummaries: initWasteTypeSummaries,
  staticWasteTypeSummaries: initWasteTypeSummaries,
  isTouched: false,
};

const initWasteTypesQuantityState: IWasteTypesQuantityState = {
  status: Status.idle,
  errorMsg: null,
  updatedWasteSummaryId: null,
  wasteSummaryDetail: initWasteSummaryDetail,
  wasteTypesSummaryRows: [],
};

export const wasteTypesQuantitySlice = createSlice({
  name: "wasteTypeQuantity",
  initialState: initWasteTypesQuantityState,
  reducers: {
    updateWasteTypeObject: (state: IWasteTypesQuantityState, action: PayloadAction<IWasteTypeSummary>) => {
      const wasteTypeSummaries = getUpdatedWasteTypeObject(state, action);

      state.wasteSummaryDetail.isTouched = true;
      state.wasteSummaryDetail.wasteTypeSummaries = wasteTypeSummaries;
    },
    restoreWasteTypeSummaries: (state: IWasteTypesQuantityState) => {
      state.wasteSummaryDetail.wasteTypeSummaries = state.wasteSummaryDetail.staticWasteTypeSummaries;
      state.wasteSummaryDetail.isTouched = false;
    },
    resetWasteTypesQuantityState: () => {
      return initWasteTypesQuantityState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWasteTypesQuantityYearThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(
        getWasteTypesQuantityYearThunk.fulfilled,
        (state: IWasteTypesQuantityState, action: PayloadAction<IWasteSummaryGetResponse>) => {
          const wasteSummaryDetailData: IWasteSummary = action.payload ?? initWasteSummaryDetail;
          const { allWasteSummaryDetailData, wasteTypesSummaryRows } =
            getWasteSummaryProperties(wasteSummaryDetailData);

          state.wasteSummaryDetail = allWasteSummaryDetailData;
          state.wasteSummaryDetail.staticWasteTypeSummaries = allWasteSummaryDetailData.wasteTypeSummaries;
          state.wasteSummaryDetail.isTouched = false;
          state.status = Status.success;
          state.errorMsg = null;
          state.updatedWasteSummaryId = null;
          state.wasteTypesSummaryRows = wasteTypesSummaryRows;
        }
      )
      .addCase(getWasteTypesQuantityYearThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })

      .addCase(createWasteTypesQuantityYearThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(
        createWasteTypesQuantityYearThunk.fulfilled,
        (state: IWasteTypesQuantityState, action: PayloadAction<IWasteSummaryCreateResponse>) => {
          state.status = Status.success;
          state.errorMsg = null;
          const wasteSummaryDetailData: IWasteSummary = action.payload.createdWasteSummary ?? initWasteSummaryDetail;

          const { allWasteSummaryDetailData, wasteTypesSummaryRows } =
            getWasteSummaryProperties(wasteSummaryDetailData);

          state.wasteSummaryDetail = allWasteSummaryDetailData;
          state.wasteSummaryDetail.staticWasteTypeSummaries = allWasteSummaryDetailData.wasteTypeSummaries;
          state.wasteSummaryDetail.isTouched = false;
          state.wasteTypesSummaryRows = wasteTypesSummaryRows;
          state.updatedWasteSummaryId = allWasteSummaryDetailData.wasteSummaryId;
        }
      )
      .addCase(createWasteTypesQuantityYearThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })
      .addCase(updateWasteTypesQuantityYearThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(updateWasteTypesQuantityYearThunk.fulfilled, (state) => {
        state.status = Status.error;
        state.errorMsg = null;
      })
      .addCase(updateWasteTypesQuantityYearThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export const getWasteTypesQuantityYearThunk = createAsyncThunk<IWasteSummaryGetResponse, void, { state: RootState }>(
  "wasteTypeQuantity/getWasteTypesQuantityYearThunk",
  async (_, { getState }) => {
    const state = getState();

    const response = await axiosRequestApi.get(
      `${GET_LOCATION_WASTE_TYPES_QUANTITY_URL}/${state.application.years.selectedYear.value}`
    );

    return response.data;
  }
);

export const createWasteTypesQuantityYearThunk = createAsyncThunk<
  IWasteSummaryCreateResponse,
  void,
  { state: RootState }
>("wasteTypeQuantity/createWasteTypesQuantityYearThunk", async (_, { getState }) => {
  const state = getState();
  const selectedYear: ISelectOption = state.application.years.selectedYear;
  const wasteTypeSummariesWithoutTotals = getSummariesWithoutTotals(state);
  const requestBody = { wasteTypeSummaries: wasteTypeSummariesWithoutTotals, year: selectedYear.value };

  const response = await axiosRequestApi.post(CREATE_LOCATION_WASTE_TYPES_QUANTITY_URL, requestBody);
  return response.data;
});

export const updateWasteTypesQuantityYearThunk = createAsyncThunk<void, void, { state: RootState }>(
  "wasteTypeQuantity/updateWasteTypesQuantityYearThunk",
  async (_, { getState }) => {
    const state = getState();
    const wasteSummaryDetail = state.wasteTypesQuantity.wasteSummaryDetail;
    const wasteTypeSummariesWithoutTotals = getSummariesWithoutTotals(state);
    const requestBody = { ...wasteSummaryDetail, wasteTypeSummaries: wasteTypeSummariesWithoutTotals };

    const response = await axiosRequestApi.post(UPDATE_LOCATION_WASTE_TYPES_QUANTITY_URL, requestBody);
    return response.data;
  }
);

export const wasteTypesQuantityReducer = wasteTypesQuantitySlice.reducer;

export const { updateWasteTypeObject, restoreWasteTypeSummaries, resetWasteTypesQuantityState } =
  wasteTypesQuantitySlice.actions;
