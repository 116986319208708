import { isAnyOf } from "@reduxjs/toolkit";

import {
  getCollectionPointsListThunk,
  setCollectionPointsListFilter,
  setCollectionPointsListOrdering,
  setCollectionPointsListPagination,
  setCollectionPointsListSearch,
} from "../../reducers/collectionPointsReducer";
import { COLLECTION_POINTS_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { getCollectionPointsListThunkRequestObject } from "./utils/getCollectionPointsListThunkRequestObject";
import { resetCollectionPoints } from "./utils/resetCollectionPoints";

export const collectionPointsListListener = (): void => {
  startAppListening({
    matcher: isAnyOf(setCollectionPointsListOrdering, setCollectionPointsListSearch, setCollectionPointsListFilter),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;
      switch (pathname) {
        case COLLECTION_POINTS_PAGE_PATH:
          resetCollectionPoints(dispatch);
          break;

        default:
          break;
      }
    },
  });

  startAppListening({
    actionCreator: setCollectionPointsListPagination,
    effect: async (_, { dispatch, getState }) => {
      const requestObject = getCollectionPointsListThunkRequestObject(getState);
      dispatch(getCollectionPointsListThunk(requestObject));
    },
  });
};
