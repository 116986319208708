import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Icon, IconNameEnum } from "../Icon";

import styles from "./AnimatedWasteLoader.module.scss";

interface AnimatedWasteLoaderProps {
  fullPage?: boolean;
}

export const AnimatedWasteLoader: React.FC<AnimatedWasteLoaderProps> = ({ fullPage }) => {
  const appliedLoaderContainerClassNamesArray = [styles.loaderContainer];
  if (fullPage) {
    appliedLoaderContainerClassNamesArray.push(styles.fullPage);
  }

  return (
    <div className={getModuleClassNames(appliedLoaderContainerClassNamesArray)}>
      <div className={styles.iconContainer}>
        <Icon name={IconNameEnum.loader} size="75px" height="140px" />
      </div>
    </div>
  );
};
