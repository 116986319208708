import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

import { Container } from "../../../components/Container";
import { ContentWithDividerLines } from "../../../components/ContentWithDividerLines";
import { CustomButton, IButtonVariant } from "../../../components/CustomButton";
import { DatePicker } from "../../../components/DatePicker";
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import { SelectComponent } from "../../../components/SelectComponent";
import { WasteTypeSelect } from "../../../components/SelectComponent/WasteTypeSelect";
import { FixedTextInputSize, TextInput, TextInputSize } from "../../../components/TextInput";
import { WAGONS_CALENDAR_PAGE_PATH } from "../../../middleware/routes";
import {} from "../../../reducers/wagonEventCreateReducer";
import {
  deleteWagonEventInstanceThunk,
  setWagonEventDetailBeginningDate,
  setWagonEventDetailNotificationsOn,
  setWagonEventDetailNotificationsText,
  setWagonEventDetailNotificationsType,
  setWagonEventDetailWasteType,
  updateWagonEventDetailThunk,
} from "../../../reducers/wagonEventDetailReducer";
import { getWagonEventDetail } from "../../../selectors/wagonEventDetail";
import { Answer, NotificationsType, WasteType } from "../../../utils/enums";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { notificationsTypeOptionsArray, periodicalOptionsArray } from "../../../utils/selectOptions";

import styles from "./WagonEventDetailForm.module.scss";

export const WagonEventDetailForm = () => {
  const dispatch = useDispatch();
  const eventDetail = useSelector(getWagonEventDetail);
  const { t } = useTranslation();
  const history = useHistory();

  const handleEventUpdate = () => {
    dispatch(updateWagonEventDetailThunk(eventDetail.wasteCollectionCalendarId));
    history.push(WAGONS_CALENDAR_PAGE_PATH);
  };

  const handleDeleteEvent = () => {
    dispatch(deleteWagonEventInstanceThunk(eventDetail.wasteCollectionCalendarId));
    history.push(WAGONS_CALENDAR_PAGE_PATH);
  };

  const generalFormFields = [
    {
      id: 1,
      element: (
        <div className={styles.top}>
          <p>{t("wasteType")}</p>
          <WasteTypeSelect
            customCssClass="minWidth"
            handleChange={(event: SelectChangeEvent<WasteType>) =>
              dispatch(setWagonEventDetailWasteType(event.target.value as WasteType))
            }
            value={eventDetail.wasteType}
          />
        </div>
      ),
    },
    {
      id: 2,
      element: (
        <div className={styles.top}>
          <p>{t("wagonEventBeginningDate")}</p>
          <DatePicker
            size={TextInputSize.md}
            onChange={(newValue) => {
              dispatch(setWagonEventDetailBeginningDate(newValue.toISOString()));
            }}
            value={new Date(eventDetail.date)}
          />
        </div>
      ),
    },
  ];

  const notificationFormFields = [
    {
      id: 1,
      element: (
        <div className={styles.top}>
          <p>{t("notifications")}</p>
          <SelectComponent
            customCssClass="minWidth"
            handleChange={(event: SelectChangeEvent<boolean>) =>
              dispatch(setWagonEventDetailNotificationsOn(event.target.value === Answer.yes))
            }
            value={eventDetail.notificationsOn ? Answer.yes : Answer.no}
            optionsArray={periodicalOptionsArray}
          />
        </div>
      ),
    },
  ];

  if (eventDetail.notificationsOn) {
    notificationFormFields.push(
      {
        id: 2,
        element: (
          <div className={styles.top}>
            <p>{t("notificationsType")}</p>
            <SelectComponent
              customCssClass="minWidth"
              handleChange={(event: SelectChangeEvent<NotificationsType>) =>
                dispatch(setWagonEventDetailNotificationsType(event.target.value as NotificationsType))
              }
              value={eventDetail.notificationsType}
              optionsArray={notificationsTypeOptionsArray}
            />
          </div>
        ),
      },
      {
        id: 3,
        element: (
          <div className={getModuleClassNames([styles.top, styles.minHeight])}>
            <p>{t("notificationsText")}</p>
            <TextInput
              fixedSize={FixedTextInputSize.fixedLg}
              name="notification text"
              type="text"
              minRows={4}
              maxLength={160}
              maxRows={4}
              onChange={(event) => dispatch(setWagonEventDetailNotificationsText(event.target.value))}
              value={eventDetail.notificationText}
              multiline={true}
            />
          </div>
        ),
      }
    );
  }

  return (
    <div className={styles.form}>
      <Container>
        <ContentWithDividerLines items={generalFormFields} />
      </Container>
      <Container>
        <ContentWithDividerLines items={notificationFormFields} />
      </Container>
      <div className={styles.buttonContainer}>
        <DeleteDialog
          openComponent={
            <CustomButton className={styles.button} variant={IButtonVariant.neutral} text={t("deleteWagonEvent")} />
          }
          title="deleteWagonDialogTitle"
          text="deleteWagonDialogText"
          confirmText={t("confirmDeleteWagonEvent")}
          cancelText={t("cancelDeleteWagonEvent")}
          onDelete={handleDeleteEvent}
        />
        <CustomButton className={styles.button} text={t("save")} onClick={handleEventUpdate} />
      </div>
    </div>
  );
};
