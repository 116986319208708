import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { CustomButton, IButtonVariant } from "../../../../components/CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import {
  removeCollectionYardsFilter,
  setCollectionYardsListOrdering,
} from "../../../../reducers/collectionYardsReducer";
import {
  getCollectionYardsFilterIsActiveCached,
  getCollectionYardsOrderingValue,
} from "../../../../selectors/collectionYards";
import { RootState } from "../../../../store";
import { ColumnType } from "../CollectionYardsTableColumnTypes";
import { AllWasteTypesFilter } from "./filters/allWasteTypes";
import { BooleanFilter } from "./filters/boolean";
import { DateFilter } from "./filters/date";
import { NumberFilter } from "./filters/number";
import { TextFilter } from "./filters/text";

import styles from "./CollectionYardsTableHeaderCell.module.scss";

interface IHeaderCellProps {
  params: GridColumnHeaderParams;
}

const selectFilters = (type: ColumnType) => {
  switch (type) {
    case ColumnType.created:
      return <DateFilter fieldName={type} />;
    case ColumnType.locationName:
      return <TextFilter fieldName={type} />;
    case ColumnType.collectionYardName:
      return <TextFilter fieldName={type} />;
    case ColumnType.wasteTypes:
      return <AllWasteTypesFilter fieldName={type} />;
    case ColumnType.wasteSum:
      return <NumberFilter fieldName={type} />;
    case ColumnType.priceSum:
      return <NumberFilter fieldName={type} />;
    case ColumnType.paid:
      return <BooleanFilter fieldName={type} />;
    default:
      <div />;
  }
};

export const CollectionYardsTableHeaderCell: React.FC<IHeaderCellProps> = ({ params }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ordering = useSelector((state: RootState) => getCollectionYardsOrderingValue(state, params.field));
  const isFilterActive = useSelector((state: RootState) => getCollectionYardsFilterIsActiveCached(state, params.field));

  const changeSort = () => {
    const newOrdering = {
      orderBy: params.field,
      descending: ordering ? ordering !== "desc" : false,
    };

    dispatch(setCollectionYardsListOrdering(newOrdering));
  };

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t(`collectionYardsVisits.${params.field}`)}</p>
      <div className={styles.filtersContainer}>
        <button className={styles.wrapperFilterIcon} id={id} onClick={openFilters}>
          {isFilterActive ? (
            <Icon name={IconNameEnum.filter} type={IconTypeEnum.grey} />
          ) : (
            <Icon name={IconNameEnum.filter} />
          )}
        </button>
        <button className={styles.wrapperFilterIcon} onClick={changeSort}>
          {ordering === "asc" && <Icon name={IconNameEnum.sortAsc} />}
          {ordering === "desc" && <Icon name={IconNameEnum.sortDesc} />}
          {!ordering && <Icon name={IconNameEnum.sortNone} />}
        </button>
      </div>
      <Popover
        className={styles.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <h1>{t(`collectionYardsVisits.${params.field}`)}</h1>
        <div className={styles.filters}>{selectFilters(params.field as ColumnType)}</div>
        <CustomButton
          variant={IButtonVariant.textImportant}
          className={styles.textSize}
          onClick={() => {
            dispatch(removeCollectionYardsFilter(params.field));
          }}
          text={t("collectionYardsVisits.filters.cancel")}
        />
      </Popover>
    </div>
  );
};
