import { useGetActiveModules } from "../../../hooks/useGetActiveModules";
import { getMainMenuItems } from "../../../utils/sidePanel/getMainMenuItems";
import { MenuItem } from "../MenuItem";

import styles from "./MainMenu.module.scss";

export const MainMenu: React.FC = () => {
  const { topActiveModules, bottomActiveModules } = useGetActiveModules();
  const { topItems, bottomItems } = getMainMenuItems(topActiveModules, bottomActiveModules);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {topItems.map((item) => (
          <MenuItem {...item} key={item.label} />
        ))}
      </div>
      <div className={styles.bottom}>
        {bottomItems.map((item) => (
          <MenuItem {...item} key={item.label} />
        ))}
      </div>
    </div>
  );
};
