import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { useGetTranslatedEnumResourceCodeGetter } from "../../../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { isWagonsModuleInactive } from "../../../../selectors/mayorModules";
import { IWagonsMapFeature } from "../../../../types/wagons";
import { getShortDateFromISOString } from "../../../../utils/dateHandler";
import { EnumName } from "../../../../utils/enums";
import { hideRFID } from "../../../../utils/rfid";
import { useGetWagonsReportTableHandlers } from "../../WagonsReport/WagonsReportTable/useGetWagonsReportTableHandlers";

import styles from "./WagonDetail.module.scss";

interface Props {
  pointDetail: IWagonsMapFeature["properties"];
}

export const WagonDetail: FC<Props> = ({ pointDetail }) => {
  const { openLocationDetail } = useGetWagonsReportTableHandlers();
  const isModuleInactive = useSelector(isWagonsModuleInactive);
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  return (
    <div className={styles.container}>
      {pointDetail.hasRfid && pointDetail.location && (
        <div className={styles.header}>
          <h1
            onClick={() => {
              if (isModuleInactive) return;
              openLocationDetail(pointDetail.locationId);
            }}
          >
            {pointDetail.location.split("-")[0]}
            <Icon name={IconNameEnum.chevronRight} />
          </h1>
          <span>{pointDetail.location.split("-")[1]}</span>
        </div>
      )}
      {pointDetail.hasRfid && pointDetail.containers.length > 1 && (
        <div className={styles.tabs}>
          {pointDetail.containers.map(({ rfid }, idx) => (
            <div className={idx === tab ? styles.active : ""} onClick={() => setTab(idx)}>
              <div>
                <Icon
                  name={IconNameEnum.delete}
                  type={idx === tab ? IconTypeEnum.white : IconTypeEnum.default}
                  size="24px"
                />
              </div>
              {hideRFID(rfid)}
            </div>
          ))}
        </div>
      )}
      {pointDetail.hasRfid && (
        <div className={styles.row}>
          <span>
            <Icon name={IconNameEnum.delete} />
            {t("wagonsReport.containerType")}
          </span>
          <span>
            {getTranslatedEnumResourceCode(EnumName.ContainerTypes, pointDetail.containers[tab].containerType)}
          </span>
        </div>
      )}
      {pointDetail.hasRfid && (
        <div className={styles.row}>
          <span>
            <Icon name={IconNameEnum.rfid} />
            {t("wagonsReport.rfid")}
          </span>
          <span>{pointDetail.containers[tab].rfid}</span>
        </div>
      )}
      <div className={styles.row}>
        <span>
          <Icon name={IconNameEnum.weight} />
          {t("wagonsReport.wasteWeight")}
        </span>
      </div>
      {pointDetail.containers[tab].dumpings.map(({ vehicleEventId, date, weight, warning }) => (
        <div className={`${styles.row} ${styles.rowIndented}`} key={vehicleEventId}>
          <span>{getShortDateFromISOString(date)}</span>
          <span>
            {warning && <Icon name={IconNameEnum.warning} />} {weight} kg
          </span>
        </div>
      ))}
    </div>
  );
};
