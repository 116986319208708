import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Slide, SlideProps, Snackbar, SnackbarCloseReason } from "@mui/material";

import { hideNotification } from "../../reducers/notificationReducer";
import { getApplicationModeState } from "../../selectors/application";
import { getNotification } from "../../selectors/notification";
import { NotificationSeverity } from "../../types/notification";
import { classHandler } from "../../utils/classHandler";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Alert } from "./Alert";

import styles from "./Notification.module.scss";

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);
  const notification = useSelector(getNotification);

  const handleClose = (_: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === "timeout") {
      closeNotification();
    }
  };

  const closeNotification = () => {
    dispatch(hideNotification());
  };

  const SlideTransitionComponent = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={notification.open}
      onClose={(e, r) => handleClose(e, r)}
      autoHideDuration={notification.hideAfter}
      TransitionComponent={SlideTransitionComponent}
      className={styles.snackbar}
    >
      <Alert
        severity={notification.severity}
        icon={false}
        className={
          notification.severity === NotificationSeverity.info
            ? getModuleClassNames(classHandler(styles, "alertInfo", applicationMode))
            : undefined
        }
      >
        {t(notification.message)}
      </Alert>
    </Snackbar>
  );
};
