import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { getSelectedYear } from "../../selectors/application";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { isActiveYear } from "../../utils/time";
import { Tooltip } from "../Tooltip";

import styles from "./ActiveIndicator.module.scss";

interface ActiveIndicatorProps {
  enableTime: string | null;
  disableTime: string | null;
}

export const ActiveIndicator: FC<ActiveIndicatorProps> = ({ enableTime, disableTime }) => {
  const { t } = useTranslation();
  const selectedYear = useSelector(getSelectedYear);

  const isActive = isActiveYear(enableTime, disableTime, +selectedYear.value);

  let TooltipContent;

  if (enableTime || disableTime) {
    TooltipContent = (
      <div className={styles.tooltipContentWrapper}>
        {enableTime && (
          <div>
            {t("activeFrom")} {dayjs(enableTime).format("DD.MM.YYYY")}
          </div>
        )}
        {disableTime && (
          <div>
            {t("disabledFrom")} {dayjs(disableTime).format("DD.MM.YYYY")}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <Tooltip bgColor="none" content={TooltipContent}>
        <div className={getModuleClassNames([styles.indicator, isActive ? styles.active : ""])}></div>
      </Tooltip>
    </div>
  );
};
