import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IconNameEnum, IconTypeEnum } from "../../../../../../components/Icon";
import { TextInput, TextInputIconPosition } from "../../../../../../components/TextInput";
import { setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const LocationFilter: React.FC<Props> = ({ fieldName }) => {
  const value = useSelector((state: RootState) => getWagonsReportFilterValue(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        placeHolder={t(`wagonsReport.${fieldName}`)}
        value={String(value?.value ?? "")}
        icon={IconNameEnum.location}
        iconType={IconTypeEnum.default}
        iconSize={28}
        iconPosition={TextInputIconPosition.end}
        onChange={(e) => {
          dispatch(
            setFilter({ id: fieldName, columnField: fieldName, operatorValue: "contains", value: e.target.value })
          );
        }}
      />
    </>
  );
};
