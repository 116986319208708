import { IconNameEnum } from "../../components/Icon";
import { IMenuItemProps } from "../../components/SidePanel/MenuItem";
import { HOME_PAGE_PATH, LANGUAGE_SETTINGS_PAGE, LOGIN_PAGE_PATH } from "../../middleware/routes";
import { setSidePanelVisibility } from "../../reducers/applicationReducer";
import { resetAuthState } from "../../reducers/authReducer";
import { store } from "../../store";
import { BottomMayorModulesType, TopMayorModulesType } from "../../types/mayorModules";
import { modules } from "./additionalMenuItems";
import { MenuItemLabelsEnum } from "./menuItemLabelsEnum";

const handleLogout = () => {
  store.dispatch(resetAuthState());
  store.dispatch(setSidePanelVisibility(false));
};

const topItemsBase: IMenuItemProps[] = [
  { label: MenuItemLabelsEnum.home, iconName: IconNameEnum.home, to: HOME_PAGE_PATH },
];

const bottomItemsBase: IMenuItemProps[] = [
  { label: MenuItemLabelsEnum.settings, iconName: IconNameEnum.globe, to: LANGUAGE_SETTINGS_PAGE },
  { label: MenuItemLabelsEnum.logOut, iconName: IconNameEnum.logout, to: LOGIN_PAGE_PATH, onClick: handleLogout },
];

export const getMainMenuItems = (
  topActiveModules: TopMayorModulesType[],
  bottomActiveModules: BottomMayorModulesType[]
): { topItems: IMenuItemProps[]; bottomItems: IMenuItemProps[] } => {
  const topItems: IMenuItemProps[] = [];
  const bottomItems: IMenuItemProps[] = [];
  topActiveModules.forEach((module) => topItems.push(modules[module]));
  bottomActiveModules.forEach((module) => bottomItems.push(modules[module]));

  return { topItems: [...topItemsBase, ...topItems], bottomItems: [...bottomItems, ...bottomItemsBase] };
};
