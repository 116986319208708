import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GET_COLLECTION_YARDS_LIST_URL } from "../middleware/routes";
import { RootState } from "../store";
import {
  ICollectionYardsList,
  ICollectionYardsListApi,
  ICollectionYardsSettingsState,
} from "../types/collectionYardsSettings";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";

const initCollectionYardsSettingsState: ICollectionYardsSettingsState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  collectionYards: [],
};

export const collectionYardsSettingsSlice = createSlice({
  name: "collectionYardsSettings",
  initialState: initCollectionYardsSettingsState,
  reducers: {
    cleanUpCollectionYardsSettingsState: (state) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.collectionYards = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionYardsListThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;
      })
      .addCase(getCollectionYardsListThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        state.collectionYards = action.payload;

        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getCollectionYardsListThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export const getCollectionYardsListThunk = createAsyncThunk<ICollectionYardsList, void, { state: RootState }>(
  "collectionYardsSettings/getCollectionYardsListThunk",
  async () => {
    const response = await axiosRequestApi.get(GET_COLLECTION_YARDS_LIST_URL);

    const apiCollectionYardsList = response.data as ICollectionYardsListApi;

    return apiCollectionYardsList.map((collectionYard) => ({
      collectionYardId: collectionYard.collection_yard_id,
      name: collectionYard.name,
    }));
  }
);

export const { cleanUpCollectionYardsSettingsState } = collectionYardsSettingsSlice.actions;

export const collectionYardsSettingsReducer = collectionYardsSettingsSlice.reducer;
