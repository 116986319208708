import styles from "./Card.module.scss";

interface HeaderSegmentProps {
  child: React.ReactNode;
  headerHeight?: number;
  outerPaddingLeft?: number;
  outerPaddingRight?: number;
}

export const HeaderSegment: React.FC<HeaderSegmentProps> = ({
  child,
  headerHeight,
  outerPaddingLeft,
  outerPaddingRight,
}) => {
  const appliedHeaderSegmentStyles = {
    height: headerHeight,
    minHeight: headerHeight,
    paddingLeft: outerPaddingLeft,
    paddingRight: outerPaddingRight,
  };

  return (
    <div className={styles.headerSegment} style={appliedHeaderSegmentStyles}>
      <div className={styles.headerSegmentContent}>{child}</div>
    </div>
  );
};
