import { selectYear } from "../../reducers/applicationReducer";
import { getCollectionYardsVisitsThunk } from "../../reducers/collectionYardsReducer";
import { getLocationDoughnutChartDataThunk, getLocationSummaryThunk } from "../../reducers/locationReducer";
import { setWagonsCalendarFilter } from "../../reducers/wagonsCalendarReducer";
import { getWasteTypesQuantityYearThunk } from "../../reducers/wasteTypesQuantityReducer";
import { WagonsFilter } from "../../types/wagonEvent";
import { COLLECTION_POINT_PAGE_PATH, WEIGHT_HISTORY_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { resetCollectionPointContainers } from "./utils/resetCollectionPointContainers";
import { resetCollectionPoints } from "./utils/resetCollectionPoints";
import { resetCollectionYards } from "./utils/resetCollectionYards";
import { resetWagonsCalendar } from "./utils/resetWagonsCalendar";
import { resetWeightHistoryList } from "./utils/resetWeightHistoryList";

export const selectYearListener = (): void => {
  startAppListening({
    actionCreator: selectYear,
    effect: async (_, { getState, dispatch }) => {
      const state = getState();
      const selectedYear = Number(state.application.years.selectedYear.value);
      const pathname = location.pathname;

      dispatch(getLocationSummaryThunk(selectedYear));
      dispatch(getWasteTypesQuantityYearThunk());
      dispatch(getLocationDoughnutChartDataThunk());
      resetCollectionPoints(dispatch);
      resetWagonsCalendar(dispatch);
      resetCollectionYards(dispatch);
      dispatch(getCollectionYardsVisitsThunk());

      switch (pathname) {
        case COLLECTION_POINT_PAGE_PATH:
          {
            resetCollectionPointContainers(dispatch);
          }
          break;

        case WEIGHT_HISTORY_PAGE_PATH:
          {
            resetCollectionPointContainers(dispatch);
            resetWeightHistoryList(dispatch);
          }
          break;

        default:
          break;
      }

      if (selectedYear === new Date().getFullYear() && state.wagonsCalendar.filter !== WagonsFilter.actual) {
        dispatch(setWagonsCalendarFilter(WagonsFilter.actual));
      } else if (selectedYear < new Date().getFullYear()) {
        dispatch(setWagonsCalendarFilter(WagonsFilter.past));
      }
    },
  });
};
