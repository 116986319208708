import { WasteSummaryWasteTypesAggregated } from "../types/wasteTypeQuantity";
import { WagonEventPeriodicity, WasteType } from "./enums";

const plasticColor = "#F8C312";
const paperColor = "#0682FE";
const metalColor = "#EA5230";
const glassColor = "#46AF1A";
const mixedColor = "#293439";
const notSeparatedColor = "#293439";
const otherWasteColor = "#88A6D1";
const bioColor = "#A07B4B";
const vkmColor = "#ffa500";

export const wasteTypeToBgColor = new Map<WasteType, string>([
  [WasteType.bio, bioColor],
  [WasteType.plastics, plasticColor],
  [WasteType.paper, paperColor],
  [WasteType.metal, metalColor],
  [WasteType.glass, glassColor],
  [WasteType.mixed, mixedColor],
  [WasteType.vkm, vkmColor],
]);

export const wasteTypeAggregatedToBgColor = new Map<WasteSummaryWasteTypesAggregated, string>([
  [WasteSummaryWasteTypesAggregated.Plastics, plasticColor],
  [WasteSummaryWasteTypesAggregated.Metal, metalColor],
  [WasteSummaryWasteTypesAggregated.Paper, paperColor],
  [WasteSummaryWasteTypesAggregated.Glass, glassColor],
  [WasteSummaryWasteTypesAggregated.Mixed, notSeparatedColor],
  [WasteSummaryWasteTypesAggregated.RecycledOther, otherWasteColor],
]);

export const wagonEventPeriodicityToString = new Map<WagonEventPeriodicity, string>([
  [WagonEventPeriodicity.sevenDays, "sevenDays"],
  [WagonEventPeriodicity.fourteenDays, "fourteenDays"],
  [WagonEventPeriodicity.twentyOneDays, "twentyOneDays"],
  [WagonEventPeriodicity.twentyEightDays, "twentyEightDays"],
]);

export const wasteTypeToNumber = new Map<WasteType, number>([
  [WasteType.bio, 1],
  [WasteType.paper, 2],
  [WasteType.plastics, 3],
  [WasteType.glass, 4],
  [WasteType.mixed, 6],
  [WasteType.vkm, 8],
  [WasteType.metal, 10],
]);

export const wagonsWasteTypeToNumber = new Map<WasteType, number>([
  [WasteType.bio, 1],
  [WasteType.paper, 2],
  [WasteType.plastics, 3],
  [WasteType.glass, 4],
  [WasteType.mixed, 6],
  [WasteType.vkm, 8],
  [WasteType.metal, 10],
]);

export const numberToWasteType = new Map<number, WasteType>([
  [1, WasteType.bio],
  [2, WasteType.paper],
  [3, WasteType.plastics],
  [4, WasteType.glass],
  [6, WasteType.mixed],
  [8, WasteType.vkm],
  [10, WasteType.metal],
]);
