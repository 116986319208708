import { defaultPagination } from "../../../reducers/collectionPointReducer";
import {
  cleanUpCollectionPointsList,
  setCollectionPointsListPagination,
} from "../../../reducers/collectionPointsReducer";
import { AppDispatch } from "../../../store";

export const resetCollectionPoints = (dispatch: AppDispatch): void => {
  dispatch(cleanUpCollectionPointsList());
  dispatch(setCollectionPointsListPagination(defaultPagination));
};
