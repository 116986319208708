import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { getDateTimeStrings } from "../../utils/dateHandler";

import styles from "./TableDateCell.module.scss";

interface ITableDateCellProps {
  params: GridRenderCellParams<string>;
  showDate?: boolean;
  showTime?: boolean;
}

export const TableDateCell: React.FC<ITableDateCellProps> = ({ params, showDate = true, showTime = true }) => {
  if (!params.value) return null;
  const formattedDate = getDateTimeStrings(new Date(params.value));

  return (
    <p className={styles.container}>
      {showDate && <span>{formattedDate.date}</span>}
      {showTime && <span>{formattedDate.time}</span>}
    </p>
  );
};
