import { useTranslation } from "react-i18next";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { TableCellWithRightArrow } from "../../../components/TableCellWithRightArrow";
import { IColumnAlignObject, IColumnValuePair } from "../../../types/table";
import { getLocalizedWeekday, getStringFromISOString } from "../../../utils/dateHandler";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { DateCell } from "./DateCell";
import { WasteTypeCell } from "./WasteTypeCell";

export enum WagonsCalendarColumn {
  date = "date",
  wasteType = "wasteType",
  dayOfWeek = "dayOfWeek",
}

const columnAlignObject: IColumnAlignObject<WagonsCalendarColumn> = {
  center: [],
};

const columnFlexValuePairs: IColumnValuePair<WagonsCalendarColumn>[] = [];

const columnMinWidthValuePairs: IColumnValuePair<WagonsCalendarColumn>[] = [];

export const useGetWagonsCalendarTableColumns = () => {
  const { t } = useTranslation();
  const { getAlign, getFlex, getMinWidth } = getTableColumnUtils();

  const columns: GridColDef[] = Object.keys(WagonsCalendarColumn).map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <p>{t(params.field)}</p>;
      },
      flex: getFlex(label, columnFlexValuePairs),
      align: getAlign(label, columnAlignObject),
      headerAlign: getAlign(label, columnAlignObject),
      minWidth: getMinWidth(label, columnMinWidthValuePairs),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case WagonsCalendarColumn.date: {
          return <DateCell date={getStringFromISOString(params.row.date)} isInHoliday={params.row.isInHoliday} />;
        }
        case WagonsCalendarColumn.wasteType: {
          return <WasteTypeCell wasteType={params.value} />;
        }
        case WagonsCalendarColumn.dayOfWeek: {
          return <TableCellWithRightArrow mainComponent={<p>{getLocalizedWeekday(params.row.date)}</p>} />;
        }
        default:
          break;
      }
    };

    return columnObject;
  });

  return { columns };
};
