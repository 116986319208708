import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IconNameEnum } from "../../../../components/Icon";
import { Tile } from "../../../../components/Tile";
import { getLanguage } from "../../../../selectors/auth";
import { getWagonsSummary } from "../../../../selectors/wagonsReport";
import { ISummaryContainer } from "../../../../types/wagons";
import { Colors } from "../../../../utils/enums";
import { WagonsReportSummaryTooltip } from "./WagonsReportSummaryTooltip";

import styles from "./WagonsReportSummary.module.scss";

const countContainers = (containers: ISummaryContainer[] | undefined) => {
  if (!containers) return 0;
  return containers.reduce((acc, item) => acc + item.count, 0);
};

export const WagonsReportSummary: React.FC = () => {
  const summary = useSelector(getWagonsSummary);
  const lang = useSelector(getLanguage);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Tile
        color={Colors.Orange}
        icon={IconNameEnum.truck}
        value={`${summary.found.toLocaleString(lang)}`}
        caption={t(`wagonsReport.summary.found`)}
        tooltip={<WagonsReportSummaryTooltip containers={summary.containers ?? []} />}
      />
      <Tile
        color={Colors.Green}
        icon={IconNameEnum.bin}
        value={`${countContainers(summary.uniqueContainers).toLocaleString(lang)}`}
        caption={t(`wagonsReport.summary.containerCount`)}
        tooltip={<WagonsReportSummaryTooltip containers={summary.uniqueContainers ?? []} />}
      />
      <Tile
        color={Colors.Red}
        icon={IconNameEnum.rfid}
        value={`${summary.noRfid.toLocaleString(lang)}`}
        caption={t(`wagonsReport.summary.noRfid`)}
      />
      <Tile
        color={Colors.TerciaryText}
        icon={IconNameEnum.weight}
        value={`${(summary.totalWeight ?? 0).toLocaleString(lang)} kg`}
        caption={t(`wagonsReport.summary.weight`)}
      />
    </div>
  );
};
