export enum NotificationSeverity {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

export interface INotification {
  open: boolean;
  message: string;
  severity: NotificationSeverity;
  hideAfter?: number;
}

export interface INotificationInput {
  message: string;
  severity: NotificationSeverity;
  hideAfter?: number;
}
