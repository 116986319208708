import { Line } from "react-chartjs-2";

import { SummaryLineChartTooltip } from "./SummaryLineChartTooltip";
import { useGetSummaryLineChartProperties } from "./useGetSummaryLineChartProperties";

import styles from "./SummaryLineChart.module.scss";

export const SummaryLineChart: React.FC = () => {
  const { data, plugins, options, chartRef, tooltipData } = useGetSummaryLineChartProperties();

  return (
    <div className={styles.container}>
      <div className={styles.lineChartContainer}>
        <Line className={styles.lineChart} data={data} options={options} plugins={plugins} ref={chartRef} />
        <SummaryLineChartTooltip tooltipData={tooltipData} />
      </div>
    </div>
  );
};
