import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { NoResultsOverlay } from "../../../components/NoResultsOverlay";
import { useResize } from "../../../hooks/useResize";
import { getGraphDataInPercentages, getLocationSummary } from "../../../selectors/location";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { useGetSummaryDoughnutChartProperties } from "./useGetSummaryDoughnutChartProperties";

import styles from "./SummaryDoughnutChart.module.scss";

export const SummaryDoughnutChart: React.FC = () => {
  const { t } = useTranslation();
  const { degreeOfSeparation, isDoughnutChartShown } = useSelector(getLocationSummary);
  const graphDataInPercentages = useSelector(getGraphDataInPercentages);
  const text = t("enums.wastetypes.recycled").toLowerCase();
  const bgColors = graphDataInPercentages.map((data) => data.bgColor);
  const matches = useResize(1200);
  const { data, options } = useGetSummaryDoughnutChartProperties();

  const labels: string[] = graphDataInPercentages.map((data) =>
    t(`enums.wasteTypesAggregated.${data.wasteType}`).toLowerCase()
  );

  const appliedLabelsClassNamesArray = [styles.labels];
  if (matches) {
    appliedLabelsClassNamesArray.push(styles.big);
  }

  return (
    <div className={styles.container}>
      {!isDoughnutChartShown ? (
        <NoResultsOverlay suggestion="suggestionMissingData" errorText="errorPleaseEnterData" />
      ) : (
        <>
          <div className={styles.doughnutChartContainer}>
            <Doughnut className={styles.doughnutChart} data={data} options={options} />
            {degreeOfSeparation > 0 && (
              <div className={styles.inner}>
                <div className={styles.innerText}>
                  <h5>{degreeOfSeparation} %</h5>
                  <p>{text}</p>
                </div>
              </div>
            )}
          </div>
          <div className={getModuleClassNames(appliedLabelsClassNamesArray)}>
            {bgColors.map((bgColor, i) => {
              return (
                <div key={i} className={styles.labelContainer} style={{ backgroundColor: bgColor }}>
                  <p className={styles.label}>{labels[i]}</p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
