import { IInventorySheetListRequest } from "../../../reducers/inventorySheetReducer";
import { RootState } from "../../../store";

export const getInventorySheetListThunkRequestObject = (getState: () => RootState) => {
  const state = getState();
  const { pagination, search, ordering, filters } = state.inventorySheet;

  const requestObject: IInventorySheetListRequest = {
    search,
    pagination,
    ordering: ordering,
    filters: filters.items,
  };

  return requestObject;
};
