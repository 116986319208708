import { FC, ReactFragment } from "react";
import { alpha, darken } from "@material-ui/core";

import { Colors } from "../../utils/enums";
import { getModuleClassNames } from "../../utils/getModuleClassNames";

import styles from "./Pin.module.scss";

interface Props {
  color: Colors;
  mainValue?: ReactFragment;
  additinalValue?: ReactFragment;
  hoverOverlay?: boolean;
  hoverOverlayActive?: boolean;
}

export const Pin: FC<Props> = ({ color, hoverOverlay, hoverOverlayActive, mainValue, additinalValue }) => {
  const alphaColor = alpha(color, 0.3);
  const darkenColor = darken(color, 0.3);
  const pinStyle = [styles.Pin];

  if (hoverOverlayActive) pinStyle.push(styles.hoverOverlayActive);

  return (
    <div className={getModuleClassNames(pinStyle)}>
      <div className={styles.main} style={{ backgroundColor: color }}>
        {mainValue}
      </div>
      <div
        className={styles.hoverOverlay}
        style={{ backgroundColor: alphaColor, borderColor: color, display: hoverOverlay ? "block" : "none" }}
      />
      {additinalValue && (
        <div className={styles.additional} style={{ backgroundColor: darkenColor }}>
          {additinalValue}
        </div>
      )}
    </div>
  );
};
