import { useTranslation } from "react-i18next";

import { PageContainer } from "../../components/PageContainer";
import { MayorModulesType } from "../../types/mayorModules";
import { InventorySheetPageHeaderComponent } from "./InventorySheetPageHeaderComponent";
import { InventorySheetSummary } from "./InventorySheetSummary";
import { InventorySheetTable } from "./InventorySheetTable";

import styles from "./InventorySheetPage.module.scss";

export const InventorySheetPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer
      headerText={t("inventorySheet")}
      module={MayorModulesType.InventorySheetModule}
      HeaderComponent={InventorySheetPageHeaderComponent}
    >
      <div className={styles.content}>
        <InventorySheetSummary />
        <InventorySheetTable />
      </div>
    </PageContainer>
  );
};
