import { FilterType, IFilterObject } from "../../types/filter";

export const getAppliedFilterArray = (newFilter: IFilterObject) => {
  const appliedFilterArray: FilterType[] = [];
  let key: keyof IFilterObject;
  for (key in newFilter) {
    if (newFilter[key]) appliedFilterArray.push(FilterType[key]);
  }

  return appliedFilterArray;
};
