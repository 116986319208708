import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, SelectChangeEvent } from "@mui/material";

import { ISelectOption } from "../../types/select";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Icon, IconNameEnum } from "../Icon";

import styles from "./SelectComponent.module.scss";

interface ISelectComponentProps {
  customCssClass?: string;
  value: ISelectOption["value"];
  handleChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  optionsArray: ISelectOption[];
  renderOption?: ((item: ISelectOption) => React.ReactElement) | undefined;
  disabled?: boolean;
}

export const SelectComponent: React.FC<ISelectComponentProps> = ({
  value,
  customCssClass = "",
  handleChange,
  optionsArray,
  renderOption,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const appliedFormControlClassNamesArray = [styles.formControl];
  if (customCssClass) {
    appliedFormControlClassNamesArray.push(styles[customCssClass]);
  }

  return (
    <FormControl className={getModuleClassNames(appliedFormControlClassNamesArray)}>
      <Select
        value={value}
        onChange={handleChange}
        className={styles.select}
        IconComponent={() => <Icon className={styles.icon} name={IconNameEnum.chevron} />}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: { boxShadow: "0px 8px 25px #afc4e266" },
          },
        }}
      >
        {optionsArray.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {renderOption ? renderOption(item) : t(item.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
