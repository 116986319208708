import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IconNameEnum } from "../../../components/Icon";
import { Tile } from "../../../components/Tile";
import { getLanguage } from "../../../selectors/auth";
import { getInventorySheetSummary } from "../../../selectors/inventorySheet";
import { ISummaryContainer } from "../../../types/inventorySheet";
import { Colors } from "../../../utils/enums";
import { InventorySheetSummaryTooltip } from "./InventorySheetSummaryTooltip";

import styles from "./InventorySheetSummary.module.scss";

const countContainers = (containers: ISummaryContainer[] | undefined) => {
  if (!containers) return 0;
  return containers.reduce((acc, item) => acc + item.count, 0);
};

export const InventorySheetSummary: React.FC = () => {
  const summary = useSelector(getInventorySheetSummary);
  const lang = useSelector(getLanguage);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Tile
        color={Colors.TerciaryText}
        icon={IconNameEnum.weight}
        value={`${(summary.collectionPoints ?? 0).toLocaleString(lang)}`}
        caption={t(`inventoryList.summary.numberOfCollectionPoints`)}
        tooltip={t(`inventoryList.summary.numberOfCollectionPointsDetail`)}
      />
      <Tile
        color={Colors.Green}
        icon={IconNameEnum.bin}
        value={`${countContainers(summary.containers).toLocaleString(lang)}`}
        caption={t(`inventoryList.summary.containerCount`)}
        tooltip={<InventorySheetSummaryTooltip containers={summary.containers ?? []} />}
      />
      <Tile
        color={Colors.Red}
        icon={IconNameEnum.rfid}
        value={`${summary.noRfid.toLocaleString(lang)}`}
        caption={t(`inventoryList.summary.rfidError`)}
      />
    </div>
  );
};
