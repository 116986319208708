import { useTranslation } from "react-i18next";

import { wasteTypeToBgColor } from "../../../../utils/enumMapping";
import { WasteType } from "../../../../utils/enums";

import styles from "./WasteTypeCell.module.scss";

type WasteTypeCellProps = {
  wasteType: WasteType;
};

export const WasteTypeCell: React.FC<WasteTypeCellProps> = ({ wasteType }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.flexWrapper}>
      <div className={styles.circle} style={{ backgroundColor: wasteTypeToBgColor.get(wasteType) }}></div>
      <p>{t(`enums.wastetypes.${wasteType}`)}</p>
    </div>
  );
};
