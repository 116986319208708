import { ApplicationMode } from "../types/application";

export const classHandler = (
  styles: {
    readonly [key: string]: string;
  },
  baseClass: string,
  applicationMode: ApplicationMode,
  isActive?: boolean,
  isVisible?: boolean
): string[] => {
  const resultClassesArray = [styles[baseClass]];

  if (applicationMode === ApplicationMode.dark) {
    resultClassesArray.push(styles[applicationMode]);
  }
  if (isActive) {
    resultClassesArray.push(styles.active);
  }
  if (isVisible) {
    resultClassesArray.push(styles.visible);
  }

  return resultClassesArray;
};
