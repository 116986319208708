import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { PaymentStatus } from "../../../types/collectionPoint";
import { capitalizeString } from "../../../utils/formatters/capitalizeString";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";

import styles from "./PaymentStatusButton.module.scss";

interface PaymentStatusButtonProps {
  type: PaymentStatus;
  setSelectedIsPaidType: React.Dispatch<React.SetStateAction<PaymentStatus>>;
  toggleModalVisibility: () => void;
}

export const PaymentStatusButton: React.FC<PaymentStatusButtonProps> = ({
  type,
  setSelectedIsPaidType,
  toggleModalVisibility,
}) => {
  const { t } = useTranslation();
  const { isPaid } = useSelector(getCollectionPointDetail);
  const isActive = type === isPaid;

  let text = "";
  switch (type) {
    case PaymentStatus.Paid:
      text = t("yes");
      break;

    case PaymentStatus.Unpaid:
      text = t("no");
      break;

    case PaymentStatus.Moved:
      text = t("livesElsewhere");
      break;
  }

  const appliedClassNamesArray = [styles.paymentStatusBtn, styles[PaymentStatus[type].toLowerCase()]];
  if (isActive) {
    appliedClassNamesArray.push(styles.active);
  }

  const handleButtonClick = () => {
    if (isActive) {
      return;
    }
    toggleModalVisibility();
    setSelectedIsPaidType(type);
  };

  return (
    <div className={getModuleClassNames(appliedClassNamesArray)} onClick={handleButtonClick}>
      <p>{capitalizeString(text)}</p>
    </div>
  );
};
