import { IWagonEvent, IWagonEventCreate, IWagonEventDetail } from "../../types/wagonEvent";
import { numberToWasteType, wasteTypeToNumber } from "../../utils/enumMapping";
import { dayOfWeekArray, notificationsTypeArray, wagonEventPeriodicityArray, WasteType } from "../../utils/enums";
import { IWagonEventUpdateRequest } from "../wagonEventDetailReducer";
import { IWagonsEventResponse } from "../wagonsCalendarReducer";

export const adaptPostRequestForBackend = (requestObject: IWagonEventCreate) => {
  return {
    ...requestObject,
    wasteType: wasteTypeToNumber.get(requestObject.wasteType),
    periodicity: wagonEventPeriodicityArray.indexOf(requestObject.periodicity),
  };
};

export const adaptResponseForFronted = (responseObject: IWagonsEventResponse): IWagonEvent => {
  return {
    ...responseObject,
    wasteType: numberToWasteType.get(responseObject.wasteType) ?? WasteType.mixed,
    periodicity: wagonEventPeriodicityArray[responseObject.periodicity],
    dayOfWeek: dayOfWeekArray[responseObject.dayOfWeek],
    date: responseObject.date,
  };
};

export const adaptPatchRequestForBackend = (requestObject: IWagonEventDetail): IWagonEventUpdateRequest => {
  let resultBody: IWagonEventUpdateRequest = {
    date: requestObject.date,
    wasteType: wasteTypeToNumber.get(requestObject.wasteType),
  };
  if (requestObject.notificationsOn) {
    resultBody = {
      ...resultBody,
      notification: {
        Type: notificationsTypeArray.indexOf(requestObject.notificationsType),
        Message: requestObject.notificationText,
      },
    };
  }

  return resultBody;
};
