import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";

import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";
import { IModal } from "../../types/modal";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { Image } from "../Image";
import { ImageNameEnum } from "../Image/ImageNameEnum";
import { ImageSuffixEnum } from "../Image/ImageSuffixEnum";

import styles from "./CustomModal.module.scss";

interface CustomModalProps extends IModal {
  children: React.ReactNode;
  imageName?: ImageNameEnum;
  imageSuffix?: ImageSuffixEnum;
  title: string;
  text?: string;
  contentGap?: CSSProperties["gap"];
  contentCenter?: boolean;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  isModalVisible,
  toggleModalVisibility,
  children,
  text,
  title,
  imageName,
  imageSuffix,
  contentGap,
  contentCenter,
}) => {
  const ref = useHandleClickOutside(toggleModalVisibility);
  const { t } = useTranslation();

  if (!isModalVisible) {
    return null;
  }

  const appliedModalClassesArray = [styles.modal];
  if (contentCenter) {
    appliedModalClassesArray.push(styles.center);
  }

  return (
    <div className={getModuleClassNames(appliedModalClassesArray)}>
      <Modal
        open={isModalVisible}
        onClose={toggleModalVisibility}
        BackdropProps={{
          transitionDuration: 2000,
        }}
        disablePortal
      >
        <div className={styles.modalContainer}>
          <div className={styles.container}>
            <div className={styles.content} ref={ref}>
              <div className={styles.modalContent} style={{ gap: contentGap }}>
                {imageName && <Image className={styles.image} name={imageName} suffix={imageSuffix} />}
                <div className={styles.textContainer}>
                  <h5 className={styles.modalHeader}>{t(title)}</h5>
                  {text && <p className={styles.modalText}>{t(text)}</p>}
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
