import {
  ICollectionYardDetail,
  ICollectionYardDetailApi,
  ICollectionYardDetailApiPostBody,
  ICollectionYardWasteType,
} from "../../types/collectionYardDetailSettings";

export const adaptResponseForFronted = (
  apiWasteType: ICollectionYardDetailApi,
  initWasteType: ICollectionYardWasteType[]
): ICollectionYardDetail => {
  return {
    address: apiWasteType.address ?? "",
    name: apiWasteType.name ?? "",
    login: apiWasteType.login,
    identification: apiWasteType.identification ?? "",
    taxIdentification: apiWasteType.tax_identification ?? "",
    collectionYardId: apiWasteType.collection_yard_id,
    collectionYardWasteTypes: initWasteType.map((wasteType) => {
      const wasteTypeResponse = apiWasteType.CollectionYardWasteTypes.find(
        (wasteTypeResponse: any) => wasteTypeResponse.waste_type_id === wasteType.wasteTypeId
      );
      return {
        ...wasteType,
        rate: wasteTypeResponse?.rate ?? 0,
        disabled: wasteTypeResponse?.disabled ?? true,
      };
    }),
  };
};

export const adaptPostForBacked = (collectionYardDetail: ICollectionYardDetail): ICollectionYardDetailApiPostBody => {
  return {
    address: collectionYardDetail.address,
    name: collectionYardDetail.name,
    identification: collectionYardDetail.identification,
    tax_identification: collectionYardDetail.taxIdentification,
    waste_types: collectionYardDetail.collectionYardWasteTypes.map((wasteType) => ({
      waste_type_id: wasteType.wasteTypeId,
      rate: Number(wasteType.rate),
      disabled: wasteType.disabled,
    })),
  };
};
