import { RootState } from "../store";
import { IApplicationState } from "../types/application";

/**
 * Application State Selector
 *
 * @params state State of application
 */
export const getApplicationState = (state: RootState): IApplicationState => state.application;

/**
 * Application Mode State Selector
 *
 * @params state State of application
 */
export const getApplicationModeState = (state: RootState): IApplicationState["applicationMode"] =>
  state.application.applicationMode;

/**
 * Application Breakpoint State Selector
 *
 * @params state State of application
 */
export const getApplicationBreakpointState = (state: RootState): IApplicationState["applicationBreakpoint"] =>
  state.application.applicationBreakpoint;

/**
 * Side panel visibility Selector
 *
 * @params state State of application
 */
export const getSidePanelVisibility = (state: RootState): IApplicationState["sidePanelVisibility"] =>
  state.application.sidePanelVisibility;

export const getLoaderVisibility = (state: RootState): IApplicationState["loaderVisibility"] =>
  state.application.loaderVisibility;

export const getUploadFileStatus = (state: RootState): IApplicationState["uploadFileStatus"] =>
  state.application.uploadFileStatus;

export const getApplicationEnums = (state: RootState): IApplicationState["applicationEnums"] =>
  state.application.applicationEnums;

export const getApplicationEnumValues = (state: RootState, enumName: string): number[] =>
  state.application.applicationEnums.find((appEnum) => appEnum.name === enumName)?.values?.map((item) => item.value) ??
  [];

export const getLastYears = (state: RootState): IApplicationState["years"]["lastYears"] =>
  state.application.years.lastYears;
export const getSelectedYear = (state: RootState): IApplicationState["years"]["selectedYear"] =>
  state.application.years.selectedYear;
