import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";

import { useGetTranslatedEnumResourceCodeGetter } from "../../hooks/useGetTranslatedEnumResourceCodeGetter";
import { getApplicationEnums } from "../../selectors/application";
import { ISelectOption } from "../../types/select";
import { wasteTypeToBgColor } from "../../utils/enumMapping";
import { EnumName, WasteType } from "../../utils/enums";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { SelectComponent } from "./SelectComponent";

import styles from "./SelectComponent.module.scss";

interface IWasteTypeProps {
  customCssClass?: string;
  value: ISelectOption["value"];
  handleChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  disabled?: boolean;
}

export const ContainerNoteSelect: React.FC<IWasteTypeProps> = ({
  value,
  customCssClass = "",
  handleChange,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { getTranslatedEnumResourceCode } = useGetTranslatedEnumResourceCodeGetter();
  const enums =
    useSelector(getApplicationEnums)?.find((appEnum) => appEnum.name === EnumName.ContainerNoteTypes)?.values ?? [];
  const optionsArray = [
    { value: 0, label: "---" },
    ...enums.map((item) => ({
      label: getTranslatedEnumResourceCode(EnumName.ContainerNoteTypes, item.value),
      value: item.value,
    })),
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectComponent
      customCssClass={customCssClass}
      handleChange={handleChange}
      value={value}
      disabled={disabled}
      optionsArray={optionsArray}
      renderOption={(item) => {
        return (
          <div className={styles.flexContainer}>
            <div
              className={getModuleClassNames([styles.circle, styles[item.value]])}
              style={{ backgroundColor: wasteTypeToBgColor.get(item.value as WasteType) }}
            ></div>
            {t(item.label)}
          </div>
        );
      }}
    />
  );
};
