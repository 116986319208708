import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";

import {
  COLLECTION_POINTS_PAGE_PATH,
  GET_COLLECTION_POINT_DETAIL_URL,
  POST_COLLECTION_POINT_URL,
  POST_INVITE_TO_MOBILE_APP_URL,
} from "../middleware/routes";
import { RootState } from "../store";
import {
  ICollectionPoint,
  IInviteToMobileAppResponse,
  InvitiationStatus,
  PaymentStatus,
} from "../types/collectionPoint";
import { IContainer } from "../types/container";
import { IOrdering, IPagination } from "../types/pagination";
import { ICollectionPointState, Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonNotMockedArray } from "../utils/tables/getSkeletonNotMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { isDisabled } from "../utils/time";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "wasteType",
  descending: false,
};

export const defaultContainer: IContainer = {
  barCode: "",
  rfid: "",
  wasteType: 0,
  containerId: 0,
  locationId: 0,
  totalWaste: 0,
  totalDumpings: 0,
  containerType: 0,
  disableTime: null,
  id: 0,
  isMocked: false,
  noteType: null,
};

const defaultContainers: IContainer[] = getSkeletonMockedArray(defaultContainer, 50, 0);

export const defaultCollectionPoint: ICollectionPoint = {
  containers: defaultContainers,
  degreeOfSeparation: 0,
  householdMembersCount: 0,
  name: "-",
  wastePerHouseholdMember: 0,
  locationId: 0,
  administrationId: 0,
  address: "-",
  isPO: false,
  invitationToken: null,
  invitationStatus: InvitiationStatus.NotInvited,
  isPaid: PaymentStatus.Unpaid,
  year: new Date().getFullYear(),
  disableTime: null,
  enableTime: null,
};

const initCollectionPointState: ICollectionPointState = {
  status: Status.idle,
  errorMsg: null,
  collectionPoint: defaultCollectionPoint,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  requestId: "",
  isNextPageEnable: false,
  scrollTop: 0,
};

export const collectionPointSlice = createSlice({
  name: "collectionPoint",
  initialState: initCollectionPointState,
  reducers: {
    cleanUpCollectionPointDetail: (state: ICollectionPointState) => {
      state.status = Status.idle;
      state.errorMsg = null;
      state.collectionPoint = defaultCollectionPoint;
      state.pagination = defaultPagination;
      state.ordering = defaultOrdering;
    },
    cleanUpCollectionPointDetailContainers: (state: ICollectionPointState) => {
      state.status = Status.idle;
      state.errorMsg = null;
      state.collectionPoint.containers = defaultContainers;
      state.pagination = defaultPagination;
      state.ordering = defaultOrdering;
    },
    setCollectionPoint: (state: ICollectionPointState, action: PayloadAction<Partial<ICollectionPoint>>) => {
      const newCollectionPoint = { ...state.collectionPoint, ...action.payload };

      state.collectionPoint = newCollectionPoint;
    },
    setCollectionPointDetailPagination: (state: ICollectionPointState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setCollectionPointDetailOrdering: (state: ICollectionPointState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setCollectionPointIsPaidStatus: (state: ICollectionPointState, action: PayloadAction<PaymentStatus>) => {
      state.collectionPoint.isPaid = action.payload;
    },
    setCollectionPointContainers: (state: ICollectionPointState, action: PayloadAction<IContainer[]>) => {
      const oldContainers = getSkeletonNotMockedArray([...state.collectionPoint.containers]);
      const newContainers = [...oldContainers, ...action.payload];

      state.collectionPoint.containers = newContainers;
    },
    setCollectionPointDetailScrollTop: (state: ICollectionPointState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetCollectionPointState: () => {
      return initCollectionPointState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionPointDetailThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;
      })
      .addCase(getCollectionPointDetailThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        state.status = Status.success;
        state.errorMsg = "";
        const containersFromState = state.collectionPoint.containers;
        const collectionPointFromResponse = action.payload;

        if (!collectionPointFromResponse) {
          window.location.href = COLLECTION_POINTS_PAGE_PATH;
        }

        const containersFromResponse = collectionPointFromResponse.containers.sort((a, b) => {
          const isDisabledA = isDisabled(a.disableTime, Number(action.payload.year));
          const isDisabledB = isDisabled(b.disableTime, Number(action.payload.year));

          if (isDisabledA && !isDisabledB) return 1;
          else if (!isDisabledA && isDisabledB) return -1;
          return 0;
        });
        const newContainers = getSkeletonSuccessArray(containersFromState, containersFromResponse);
        const newCollectionPoint = { ...collectionPointFromResponse, containers: newContainers };
        state.collectionPoint = { ...state.collectionPoint, ...newCollectionPoint };

        let isNextPageEnable = true;
        if (containersFromResponse.length < state.pagination.limit) {
          isNextPageEnable = false;
        }
        state.isNextPageEnable = isNextPageEnable;
      })
      .addCase(getCollectionPointDetailThunk.rejected, (state, action) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        if (action.payload === 404) {
          window.location.href = COLLECTION_POINTS_PAGE_PATH;
        }
      })

      .addCase(postCollectionPointThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(postCollectionPointThunk.fulfilled, (state) => {
        state.status = Status.success;
        state.errorMsg = "";
      })
      .addCase(postCollectionPointThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      })

      .addCase(inviteToMobileAppThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(inviteToMobileAppThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.collectionPoint.invitationToken = action.payload.token;
        state.collectionPoint.invitationStatus = InvitiationStatus.Invited;
        state.errorMsg = "";
      })
      .addCase(inviteToMobileAppThunk.rejected, (state, action) => {
        state.status = Status.error;
        if (action.payload) {
          state.errorMsg = action.payload;
        }
      });
  },
});

export const {
  cleanUpCollectionPointDetail,
  setCollectionPoint,
  setCollectionPointDetailPagination,
  setCollectionPointDetailOrdering,
  setCollectionPointIsPaidStatus,
  setCollectionPointContainers,
  resetCollectionPointState,
  setCollectionPointDetailScrollTop,
  cleanUpCollectionPointDetailContainers,
} = collectionPointSlice.actions;

export const collectionPointReducer = collectionPointSlice.reducer;

export const getCollectionPointDetailThunk = createAsyncThunk<ICollectionPoint, number, { state: RootState }>(
  "collectionPoint/getCollectionPointDetailThunk",
  async (collectionPointId, { getState, rejectWithValue }) => {
    const state: RootState = getState();
    const requestBody = {
      locationId: collectionPointId,
      year: state.application.years.selectedYear.value,
      pagination: state.collectionPoint.pagination,
      ordering: state.collectionPoint.ordering,
    };
    try {
      const response = await axiosRequestApi.post(GET_COLLECTION_POINT_DETAIL_URL, requestBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.statusCode);
    }
  }
);
export const inviteToMobileAppThunk = createAsyncThunk<
  IInviteToMobileAppResponse,
  string,
  { state: RootState; rejectValue: string }
>("collectionPoint/inviteToMobileAppThunk", async (email, { getState, rejectWithValue }) => {
  const state = getState();
  const collectionPointId = state.collectionPoint.collectionPoint.locationId;

  const requestBody = {
    locationId: collectionPointId,
    email,
  };
  try {
    const response = await axiosRequestApi.post(POST_INVITE_TO_MOBILE_APP_URL, requestBody);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(i18next.t("unexpectedError"));
  }
});

export const postCollectionPointThunk = createAsyncThunk<any, ICollectionPoint>(
  "collectionPoint/postCollectionPointThunk",
  async (collectionPoint: ICollectionPoint) => {
    const response = await axiosRequestApi.post(POST_COLLECTION_POINT_URL, collectionPoint);
    return response.data;
  }
);
