import styles from "./ContentWithDividerLines.module.scss";

export interface IIterableItem {
  id: number;
  element: React.ReactNode;
}

interface IContentWithDividerLinesProps {
  items: IIterableItem[];
}

export const ContentWithDividerLines: React.FC<IContentWithDividerLinesProps> = ({ items }) => {
  return (
    <div className={styles.content}>
      {items.map((item) => (
        <div className={styles.item} key={item.id}>
          {item.element}
        </div>
      ))}
    </div>
  );
};
