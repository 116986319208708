import { useSelector } from "react-redux";

import { getIsSeparating } from "../../selectors/mayorModules";
import { getWeighValue } from "../../utils/collectionPoints/getWeighValue";
import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { getWasteCollectionSuccessRatesProperties } from "../../utils/getWasteCollectionSuccessRatesProperties";

import styles from "./ProgressBar.module.scss";

export interface IProgressBarProps {
  value: number;
  doesWeigh: boolean;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ value, doesWeigh }) => {
  const isSeparating = useSelector(getIsSeparating);
  const { appliedTextResourceCode, percentageValue, appliedValueString } = getWasteCollectionSuccessRatesProperties(
    doesWeigh ? value : 0,
    isSeparating
  );

  const appliedFrontClassNamesArray = [styles.front, styles.minWidth];
  if (doesWeigh) appliedFrontClassNamesArray.push(styles[appliedTextResourceCode]);
  else appliedFrontClassNamesArray.push(styles.recyclingNone);

  return (
    <div className={styles.progressBar}>
      <div className={styles.back}>
        <span className={getModuleClassNames(appliedFrontClassNamesArray)} style={{ width: `${percentageValue}%` }} />
      </div>
      <span>{getWeighValue(doesWeigh, appliedValueString)}</span>
    </div>
  );
};
