import styles from "./SummaryCards.module.scss";

interface SummaryCardProps {
  title: string;
  value: string;
}

export const SummaryCard: React.FC<SummaryCardProps> = ({ title, value }) => {
  return (
    <div className={styles.summaryCard}>
      <h6 className={styles.title}>{title}</h6>
      <p className={styles.value}>{value}</p>
    </div>
  );
};
