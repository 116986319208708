import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

import { ActiveIndicator } from "../../../components/ActiveIndicator";
import { HeaderBackButton } from "../../../components/BackButton";
import { CustomButton } from "../../../components/CustomButton";
import { MenuButton } from "../../../components/MenuButton";
import { YearSelect } from "../../../components/SelectComponent";
import { useIsFeatureEnabled } from "../../../hooks/useIsFeatureEnabled";
import { useModal } from "../../../hooks/useModal";
import { COLLECTION_POINTS_PAGE_PATH } from "../../../middleware/routes";
import { getCollectionPointDetail } from "../../../selectors/collectionPoint";
import { InvitiationStatus, PaymentStatus } from "../../../types/collectionPoint";
import { FeatureName } from "../../../utils/enums";
import { InvitationSentComponent } from "../InvitationSentComponent";
import { InviteToMobileAppModal } from "../InviteToMobileAppModal";
import { PaymentStatusButton } from "../PaymentStatusButton";
import { PaymentStatusChangeConfirmationModal } from "../PaymentStatusChangeConfirmationModal";

import styles from "./CollectionPointPageHeaderComponent.module.scss";

export const CollectionPointPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const { name, address, invitationStatus, enableTime, disableTime } = useSelector(getCollectionPointDetail);
  const inviteToMobileAppModal = useModal();
  const paymentStatusChangeConfirmationModal = useModal();
  const [selectedIsPaidType, setSelectedIsPaidType] = useState<PaymentStatus>(PaymentStatus.Paid);
  const mobileEnabled = useIsFeatureEnabled(FeatureName.mobile);

  const buttonsArray = [
    {
      type: PaymentStatus.Paid,
    },
    {
      type: PaymentStatus.Unpaid,
    },
    // {
    //   type: PaymentStatus.Moved,
    // },
  ];

  const exportToPdf = () => {
    print();
  };

  return (
    <>
      <div className={styles.container}>
        <HeaderBackButton
          backTo={COLLECTION_POINTS_PAGE_PATH}
          title={
            <div className={styles.nameWrapper}>
              <p>{name}</p>
              <ActiveIndicator enableTime={enableTime} disableTime={disableTime} />
            </div>
          }
          subtitle={address}
        />
        <div className={styles.center}>
          <p>{t("isPaid")}:</p>
          <div className={styles.buttonsContainer}>
            {buttonsArray.map(({ type }, i) => (
              <PaymentStatusButton
                key={i}
                type={type}
                toggleModalVisibility={paymentStatusChangeConfirmationModal.toggleModalVisibility}
                setSelectedIsPaidType={setSelectedIsPaidType}
              />
            ))}
          </div>
        </div>
        <div className={styles.controls}>
          <YearSelect />
          {mobileEnabled && (
            <>
              {invitationStatus === InvitiationStatus.NotInvited ? (
                <CustomButton
                  className={styles.inviteToMobileAppBtn}
                  text={t("inviteToMobileApp")}
                  onClick={inviteToMobileAppModal.toggleModalVisibility}
                />
              ) : (
                <InvitationSentComponent />
              )}
            </>
          )}
          <MenuButton>
            <MenuItem onClick={exportToPdf}>{t("ExportToPdf")}</MenuItem>
          </MenuButton>
        </div>
      </div>
      <InviteToMobileAppModal
        isModalVisible={inviteToMobileAppModal.isModalVisible}
        toggleModalVisibility={inviteToMobileAppModal.toggleModalVisibility}
      />
      <PaymentStatusChangeConfirmationModal
        isModalVisible={paymentStatusChangeConfirmationModal.isModalVisible}
        toggleModalVisibility={paymentStatusChangeConfirmationModal.toggleModalVisibility}
        selectedIsPaidType={selectedIsPaidType}
      />
    </>
  );
};
