import { PayloadAction } from "@reduxjs/toolkit";

import { IWasteTypesQuantityState } from "../../types/state";
import { IWasteTypeSummary } from "../../types/wasteTypeQuantity";
import { AllWasteTypes, monthsArray } from "../enums";

export const getUpdatedWasteTypeObject = (
  state: IWasteTypesQuantityState,
  action: PayloadAction<IWasteTypeSummary>
): IWasteTypeSummary[] => {
  const updatedWasteTypeObject: IWasteTypeSummary = action.payload;
  const wasteTypeSummaries = [...state.wasteSummaryDetail.wasteTypeSummaries];

  const wasteTypeArr = wasteTypeSummaries.filter(({ wasteType }) => {
    return wasteType !== AllWasteTypes.totalWaste && wasteType !== AllWasteTypes.recycled;
  });

  const totalWasteO = wasteTypeSummaries.filter(({ wasteType }) => {
    return wasteType === AllWasteTypes.totalWaste;
  })[0];
  const recycledO = wasteTypeSummaries.filter(({ wasteType }) => {
    return wasteType === AllWasteTypes.recycled;
  })[0];
  const communalO = wasteTypeSummaries.filter(({ wasteType }) => {
    return wasteType === AllWasteTypes.communal;
  })[0];

  const findIndexInSummaries = (object: IWasteTypeSummary) =>
    wasteTypeSummaries.findIndex((wasteTypeSummary) => wasteTypeSummary.wasteType === object.wasteType);

  const index: number = findIndexInSummaries(updatedWasteTypeObject);
  const totalWasteIndex: number = findIndexInSummaries(totalWasteO);
  const recycledIndex: number = findIndexInSummaries(recycledO);

  monthsArray.forEach((month) => {
    const sum = (a: number, b: number) => a + b;

    const monthTotalWasteSum =
      wasteTypeArr
        .filter((obj) => obj.wasteType !== updatedWasteTypeObject.wasteType)
        .map((obj) => {
          return obj[month];
        })
        .reduce(sum) + updatedWasteTypeObject[month];

    const monthRecycledWasteSum =
      wasteTypeArr
        .filter((obj) => obj.wasteType !== updatedWasteTypeObject.wasteType)
        .map((obj) => {
          return obj[month];
        })
        .reduce(sum) -
      (updatedWasteTypeObject.wasteType === AllWasteTypes.communal ? updatedWasteTypeObject[month] : communalO[month]) +
      updatedWasteTypeObject[month];

    totalWasteO[month] = monthTotalWasteSum;
    recycledO[month] = monthRecycledWasteSum;
  });

  wasteTypeSummaries.splice(index, 1, updatedWasteTypeObject);
  wasteTypeSummaries.splice(totalWasteIndex, 1, totalWasteO);
  wasteTypeSummaries.splice(recycledIndex, 1, recycledO);

  return wasteTypeSummaries;
};
