import { useEffect, useRef, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";

interface HandleSaveDataTableScrollPositionProperties {
  apiRef: React.MutableRefObject<any>;
  scrollTopRef: React.MutableRefObject<number>;
}

export const useHandleSaveDataTableScrollPosition = (
  scrollTop: number
): HandleSaveDataTableScrollPositionProperties => {
  const apiRef = useGridApiRef();

  const [windowRef, setWindowRef] = useState<any>(null);
  const scrollTopRef = useRef(scrollTop);

  useEffect(() => {
    setWindowRef(apiRef.current.windowRef);

    apiRef.current.subscribeEvent("rowsScroll", (e) => {
      if (e.top > 0) scrollTopRef.current = e.top;
    });
  }, []);

  useEffect(() => {
    setWindowRef(apiRef.current.windowRef);

    if (windowRef) {
      apiRef.current.scroll({ top: scrollTop });
    }
  }, [windowRef, scrollTop]);

  return { scrollTopRef, apiRef };
};
