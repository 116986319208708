import { getSkeletonNotMockedArray } from "./getSkeletonNotMockedArray";

export const getSkeletonSuccessArray = (arrayFromState: any[], arrayFromPayload: any[]) => {
  const notMockedObjects = getSkeletonNotMockedArray(arrayFromState);
  const arrayFromPayloadWithWithIds = arrayFromPayload.map((obj, i) => {
    return { ...obj, id: notMockedObjects.length + i + 1 };
  });
  const newObjectsArray: any[] = [...notMockedObjects, ...arrayFromPayloadWithWithIds];

  return newObjectsArray;
};
