import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "../../../../../../components/Checkbox";
import { removeFilter, setFilter } from "../../../../../../reducers/wagonsReportStateReducer";
import { getWagonsReportFilterValue } from "../../../../../../selectors/wagonsReport";
import { RootState } from "../../../../../../store";
import { wagonReportTableColumnType } from "../../WagonsReportTableUtils";

interface Props {
  fieldName: wagonReportTableColumnType;
}

export const RfidFilter: React.FC<Props> = ({ fieldName }) => {
  const value = useSelector((state: RootState) => getWagonsReportFilterValue(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Checkbox
        checked={!!value}
        onChange={() => {
          if (value) dispatch(removeFilter(fieldName));
          else dispatch(setFilter({ id: fieldName, columnField: fieldName, operatorValue: "isEmpty" }));
        }}
      />
      {t("wagonsReport.filters.noRfid")}
    </div>
  );
};
