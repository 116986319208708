import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  DataGridPro,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowIdGetter,
} from "@mui/x-data-grid-pro";

import { TableInput } from "../../../components/TableInput";
import { getIsWasteTypesQuantityStatusLoading, getWasteTypesSummaryRows } from "../../../selectors/wasteTypesQuantity";
import { Column } from "../../../utils/enums";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";
import { RowHeaderCell } from "./RowHeaderCell";

import styles from "./SummaryTable.module.scss";

const renderCell = (params: GridRenderCellParams): GridCellValue => {
  if (params.field === Column.header) {
    return <RowHeaderCell params={params} />;
  }

  return <TableInput params={params} />;
};

const columnLabels = Object.values(Column);

export const SummaryTable: React.FC = () => {
  const { t } = useTranslation();
  const wasteTypesSummaryRows = useSelector(getWasteTypesSummaryRows);
  const isLoading = useSelector(getIsWasteTypesQuantityStatusLoading);

  const renderHeader = (params: GridColumnHeaderParams): React.ReactNode => {
    return <span className={styles.styledHeader}>{t(`enums.months.${params.field}`)}</span>;
  };

  const columns: GridColDef[] = columnLabels.map((key, i) => {
    const cellObject: GridColDef = {
      field: key,
      type: "string",
      renderCell,
      sortable: false,
      renderHeader,
    };

    cellObject.width = 100;

    if (key === Column.header) {
      cellObject.width = 190;
      return cellObject;
    }
    if (i >= 7) {
      cellObject.width = 115;
    }

    return cellObject;
  });

  const getRowId: GridRowIdGetter = (row) => row.header;

  const getCellClassName = (params: GridCellParams<string>) => {
    const cellClassNamesArray = [styles.rowCell];

    if (params.field === Column.header) {
      cellClassNamesArray.unshift(styles.rowHeader);
    }

    const cellClassNames = getModuleClassNames(cellClassNamesArray);

    return cellClassNames;
  };

  return (
    <div className={styles.container}>
      <DataGridPro
        getRowId={getRowId}
        rows={wasteTypesSummaryRows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getCellClassName={getCellClassName}
        disableColumnMenu
        loading={isLoading}
        hideFooter
      />
    </div>
  );
};
