import { Theme } from "@material-ui/core";
import { createTheme } from "@material-ui/core";

import { ApplicationMode } from "../types/application";

const lightTheme = createTheme({
  typography: {
    fontFamily: "Sora",
  },
  palette: {
    primary: {
      light: "#de1919",
      main: "#000",
      dark: "#de1919",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontWeight: 500,
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500,
      },
    },
    MuiButton: {
      root: {
        background: "#46AF1A",
        borderRadius: "4px",
        lineHeight: 1,
        paddingTop: "14px !important",
        paddingBottom: "15px !important",
        paddingRight: "26px !important",
        paddingLeft: "18px !important",
      },
      label: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: 1,
        fontFamily: "Sora",
        whiteSpace: "nowrap",
        textTransform: "none",
      },
      textPrimary: {
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#37A047",
        },
      },
      startIcon: {
        marginRight: "8px",
      },
    },
    MuiSelect: {
      root: {
        "&:focus .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E1FFC8",
        },
      },
    },
    MuiMenu: {
      list: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
      },
    },
    MuiListItem: {
      root: {
        fontSize: "14px !important",
        lineHeight: "1 !important",
        fontFamily: "Sora !important",
        height: "40px !important",
        minHeight: "40px !important",
        // @ts-ignore
        whiteSpace: "nowrap !important",
        // @ts-ignore
        textTransform: "none !important",
        "&$selected": {
          backgroundColor: "white !important",
        },
        "&:hover": {
          backgroundColor: "#F7F9FC !important",
        },
      },
    },
  },
});

// eslint-disable
const darkTheme = createTheme({
  // type is incorrectly derived from MUI, and disabling eslint does not work - commented for now
  // overrides: {
  //   MuiPickersDay: {
  //     daySelected: {
  //       backgroundColor: "grey",
  //     },
  //   },
  //   MuiPickersClockPointer: {
  //     thumb: {
  //       opacity: 0.6,
  //     },
  //   },
  //   MuiInput: {
  //     root: {
  //       fontWeight: 500,
  //     },
  //   },
  //   MuiMenuItem: {
  //     root: {
  //       fontWeight: 500,
  //     },
  //   },
  // },
  palette: {
    primary: {
      light: "#de1919",
      main: "#FFF",
      dark: "#de1919",
      contrastText: "#fff",
    },
    type: "dark",
    background: {
      paper: "#212121",
      default: "#191919",
    },
  },
});
// eslint-enable

export const themeHandler = (applicationMode: ApplicationMode): Theme =>
  applicationMode === ApplicationMode.light ? lightTheme : darkTheme;

export const setBodyBackground = (applicationMode: ApplicationMode) => {
  document.body.style.backgroundColor = applicationMode === ApplicationMode.light ? "#f9f9f9" : "#191919";
};
