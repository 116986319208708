import { RootState } from "../store";
import { IWasteTypesQuantityState, Status } from "../types/state";
import { AllWasteTypes } from "../utils/enums";

export const getWasteTypesQuantityStatus = (state: RootState): IWasteTypesQuantityState["status"] =>
  state.wasteTypesQuantity.status;
export const getIsWasteTypesQuantityStatusLoading = (state: RootState): boolean =>
  state.wasteTypesQuantity.status !== Status.success;

export const getWasteTypesQuantityErrorMsg = (state: RootState): IWasteTypesQuantityState["errorMsg"] =>
  state.wasteTypesQuantity.errorMsg;

export const getWasteTypesQuantityState = (state: RootState): IWasteTypesQuantityState => state.wasteTypesQuantity;
export const getWasteTypesQuantity = (state: RootState): IWasteTypesQuantityState["wasteSummaryDetail"] =>
  state.wasteTypesQuantity.wasteSummaryDetail;
export const getWasteTypesQuantitySummaries = (
  state: RootState
): IWasteTypesQuantityState["wasteSummaryDetail"]["wasteTypeSummaries"] =>
  state.wasteTypesQuantity.wasteSummaryDetail.wasteTypeSummaries;

export const getUpdatedWasteSummaryId = (state: RootState): IWasteTypesQuantityState["updatedWasteSummaryId"] =>
  state.wasteTypesQuantity.updatedWasteSummaryId;

export const getWasteTypesSummaryRows = (state: RootState): IWasteTypesQuantityState["wasteTypesSummaryRows"] =>
  state.wasteTypesQuantity.wasteTypesSummaryRows.filter(
    ({ wasteType }) => ![AllWasteTypes.recycled, AllWasteTypes.totalWaste, AllWasteTypes.other].includes(wasteType)
  );
