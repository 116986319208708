import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GridEventListener, GridEvents } from "@mui/x-data-grid-pro";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { useHandleSaveDataTableScrollPosition } from "./useHandleSaveDataTableScrollPosition";

interface DataTableHandlers {
  handleRowClick: GridEventListener<GridEvents.rowClick>;
  apiRef: React.MutableRefObject<any>;
}

export type HandleAdditionalRowClickHandler = GridEventListener<GridEvents.rowClick>;

export const useGetDataTableHandlers = (
  scrollTop: number,
  setScrollTop: ActionCreatorWithPayload<number>,
  additionalHandleRowClick?: HandleAdditionalRowClickHandler
): DataTableHandlers => {
  const dispatch = useDispatch();
  const { scrollTopRef, apiRef } = useHandleSaveDataTableScrollPosition(scrollTop);

  const handleRowClick: GridEventListener<GridEvents.rowClick> = (params, e, details) => {
    if (!additionalHandleRowClick) {
      return;
    }

    if (params.row.isMocked) {
      return;
    }

    additionalHandleRowClick(params, e, details);
  };

  useEffect(
    () => () => {
      dispatch(setScrollTop(scrollTopRef.current));
    },
    []
  );

  return {
    handleRowClick,
    apiRef,
  };
};
