import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getApplicationModeState } from "../../../selectors/application";
import { classHandler } from "../../../utils/classHandler";
import { getModuleClassNames } from "../../../utils/getModuleClassNames";

import styles from "./MenuItemWrapper.module.scss";

export interface IMenuItemWrapperProps {
  disabled: boolean;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
  children: React.ReactNode;
  wrapperStyles: { readonly [key: string]: string };
}

export const MenuItemWrapper: React.FC<IMenuItemWrapperProps> = ({
  children,
  disabled,
  to,
  onClick,
  wrapperStyles,
  isActive,
}) => {
  const applicationMode = useSelector(getApplicationModeState);
  const history = useHistory();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (to) {
      history.push(to);
    }
  };

  const wrapperClassNamesArray = classHandler(wrapperStyles, "menuItem", applicationMode, isActive);

  if (disabled) {
    wrapperClassNamesArray.push(wrapperStyles.disabled);

    return <div className={getModuleClassNames(wrapperClassNamesArray)}>{children}</div>;
  }

  return (
    <div
      className={getModuleClassNames(classHandler(styles, "menuItemWrapper", applicationMode, isActive))}
      onClick={handleClick}
    >
      <div className={getModuleClassNames(wrapperClassNamesArray)}>{children}</div>
    </div>
  );
};
