import i18n from "i18next";

import { updateLanguageThunk } from "../../reducers/authReducer";
import { startAppListening } from "./listenerMiddleware";

export const updateLanguageListener = (): void => {
  startAppListening({
    actionCreator: updateLanguageThunk.fulfilled,
    effect: async (action) => {
      const language = action.payload;
      i18n.changeLanguage(language);
    },
  });
};
