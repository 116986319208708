import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../../components/Icon";
import { getCollectionPointsOrdering } from "../../../../selectors/collectionPoints";

import styles from "./CollectionPointsTableHeaderCell.module.scss";

interface IHeaderCellProps {
  params: GridColumnHeaderParams;
}

export const CollectionPointsTableHeaderCell: React.FC<IHeaderCellProps> = ({ params }) => {
  const { t } = useTranslation();
  const ordering = useSelector(getCollectionPointsOrdering);
  const isSorted = ordering.orderBy.toLowerCase() === params.field.toLowerCase();
  const appliedIconName: IconNameEnum = ordering.descending ? IconNameEnum.arrowDown : IconNameEnum.arrowUp;

  const label = params.field === "containerCount" ? t("activeContainerCount") : t(params.field);

  return (
    <div className={styles.alignIcon}>
      <p className={styles.text}>{label}</p>
      {isSorted && <Icon name={appliedIconName} type={IconTypeEnum.grey} className={styles.icon} />}
    </div>
  );
};
