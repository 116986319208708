import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

import { Container } from "../../../components/Container";
import { ContentWithDividerLines } from "../../../components/ContentWithDividerLines";
import { CustomButton } from "../../../components/CustomButton";
import { DatePicker } from "../../../components/DatePicker";
import { SelectComponent } from "../../../components/SelectComponent";
import { WasteTypeSelect } from "../../../components/SelectComponent/WasteTypeSelect";
import { TextInputSize } from "../../../components/TextInput";
import { WAGONS_CALENDAR_PAGE_PATH } from "../../../middleware/routes";
import {
  postWagonEventThunk,
  setWagonEventCreateEndDate,
  setWagonEventCreatePeriodicity,
  setWagonEventCreateStartDate,
  setWagonEventCreateWasteType,
} from "../../../reducers/wagonEventCreateReducer";
import { getWagonEventCreate } from "../../../selectors/wagonEventCreate";
import { Answer, WagonEventPeriodicity, WasteType } from "../../../utils/enums";
import { periodicalOptionsArray, periodicityOptionsArray } from "../../../utils/selectOptions";

import styles from "./WagonEventForm.module.scss";

interface DateError {
  error: boolean;
  errorMessage: string;
}

const initialDateError: DateError = {
  error: false,
  errorMessage: "",
};

export const WagonEventForm = () => {
  const dispatch = useDispatch();
  const newEvent = useSelector(getWagonEventCreate);
  const { t } = useTranslation();
  const [dateError, setDateError] = useState<DateError>(initialDateError);
  const history = useHistory();

  useEffect(() => {
    if (new Date(newEvent.endDate ?? new Date()) < new Date(newEvent.startDate))
      setDateError({ error: true, errorMessage: t("validation.wagonEvent.wrongEndDate") });
    else setDateError(initialDateError);
  }, [newEvent.endDate, newEvent.startDate]);

  const handleCreateEvents = async () => {
    dispatch(postWagonEventThunk(newEvent));
    history.push(WAGONS_CALENDAR_PAGE_PATH);
  };

  const formFields = [
    {
      id: 1,
      element: (
        <div className={styles.top}>
          <p>{t("wasteType")}</p>
          <WasteTypeSelect
            customCssClass="minWidth"
            handleChange={(event: SelectChangeEvent<WasteType>) =>
              dispatch(setWagonEventCreateWasteType(event.target.value as WasteType))
            }
            value={newEvent.wasteType}
          />
        </div>
      ),
    },
    {
      id: 2,
      element: (
        <div className={styles.top}>
          <p>{t("wagonEventBeginningDate")}</p>
          <DatePicker
            size={TextInputSize.md}
            value={new Date(newEvent.startDate)}
            onChange={(newValue) => {
              dispatch(setWagonEventCreateStartDate(newValue.toISOString()));
            }}
          />
        </div>
      ),
    },
    {
      id: 3,
      element: (
        <div className={styles.top}>
          <p>{t("periodicalWagonEvent")}</p>
          <SelectComponent
            customCssClass="minWidth"
            handleChange={(event: SelectChangeEvent<boolean>) => {
              if (event.target.value === Answer.yes)
                dispatch(setWagonEventCreatePeriodicity(WagonEventPeriodicity.sevenDays));
              else dispatch(setWagonEventCreatePeriodicity(WagonEventPeriodicity.none));
            }}
            value={newEvent.periodicity !== WagonEventPeriodicity.none ? Answer.yes : Answer.no}
            optionsArray={periodicalOptionsArray}
          />
        </div>
      ),
    },
  ];

  if (newEvent.periodicity !== WagonEventPeriodicity.none) {
    formFields.push(
      {
        id: 4,
        element: (
          <div className={styles.top}>
            <p>{t("periodRepeat")}</p>
            <SelectComponent
              customCssClass="minWidth"
              handleChange={(event: SelectChangeEvent<WagonEventPeriodicity>) => {
                dispatch(setWagonEventCreatePeriodicity(event.target.value as WagonEventPeriodicity));
              }}
              value={newEvent.periodicity}
              optionsArray={periodicityOptionsArray}
            />
          </div>
        ),
      },
      {
        id: 5,
        element: (
          <div className={styles.top}>
            <p>{t("wagonEventEndingDate")}</p>
            <DatePicker
              size={TextInputSize.md}
              value={new Date(newEvent.endDate ?? new Date().toISOString())}
              onChange={(newValue) => {
                dispatch(setWagonEventCreateEndDate(newValue.toISOString()));
              }}
              error={dateError.error}
              helperText={dateError.errorMessage}
            />
          </div>
        ),
      }
    );
  }

  return (
    <div className={styles.form}>
      <Container>
        <ContentWithDividerLines items={formFields} />
      </Container>
      <div className={styles.buttonContainer}>
        <CustomButton className={styles.button} text={t("save")} onClick={handleCreateEvents} />
      </div>
    </div>
  );
};
