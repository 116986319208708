import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GridEventListener, GridEvents, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";

import { INVENTORY_SHEET_PAGE_PATH, WEIGHT_HISTORY_PAGE_PATH } from "../../../middleware/routes";
import { getCollectionPointDetailThunk } from "../../../reducers/collectionPointReducer";
import { defaultOrdering } from "../../../reducers/inventorySheetReducer";
import { setInventorySheetListOrdering } from "../../../reducers/inventorySheetReducer";
import { getWeightHistoryThunk } from "../../../reducers/weightHistoryReducer";
import { getInventorySheetOrdering, getIsInventorySheetListStatusLoading } from "../../../selectors/inventorySheet";
import { IInventorySheetContainer } from "../../../types/inventorySheet";

interface InventorySheetTableHandlers {
  handleAdditionalRowClick: GridEventListener<GridEvents.rowClick>;
  handleSortModelChange: (model: GridSortModel) => void;
}

export const useGetInventorySheetTableHandlers = (): InventorySheetTableHandlers => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ordering = useSelector(getInventorySheetOrdering);
  const isLoading = useSelector(getIsInventorySheetListStatusLoading);

  const handleAdditionalRowClick: GridEventListener<GridEvents.rowClick> = (
    params: GridRowParams<IInventorySheetContainer>
  ) => {
    const containerId = String(params.row.containerId);
    const locationId = String(params.row.locationId);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("id", locationId);
    urlParams.set("cid", containerId);
    urlParams.set("prev-page", INVENTORY_SHEET_PAGE_PATH.replace("/", ""));
    const newUrlSearch = `?${urlParams.toString()}`;

    dispatch(getCollectionPointDetailThunk(Number(locationId)));
    dispatch(getWeightHistoryThunk(Number(containerId)));

    history.push(`${WEIGHT_HISTORY_PAGE_PATH}${newUrlSearch}`);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (isLoading) {
      return;
    }

    const field = model[0].field;
    //sort case sensitivity
    const newOrderBy = () => {
      switch (field) {
        default:
          return field;
      }
    };

    const newOrdering = {
      orderBy: newOrderBy(),
      descending: ordering.orderBy === newOrderBy() ? !ordering.descending : defaultOrdering.descending,
    };
    dispatch(setInventorySheetListOrdering(newOrdering));
  };

  const inventorySheetTableHandlers: InventorySheetTableHandlers = {
    handleAdditionalRowClick,
    handleSortModelChange,
  };

  return inventorySheetTableHandlers;
};
