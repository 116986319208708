import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import {
  setCollectionYardsListPagination,
  setCollectionYardsListScrollTop,
} from "../../../reducers/collectionYardsReducer";
import {
  getCollectionYards,
  getCollectionYardsScrollTop,
  getCollectionYardsVisits,
  getIsCollectionYardsVisitsStatusLoading,
} from "../../../selectors/collectionYards";
import { useGetCollectionYardsTableColumns } from "./useGetCollectionYardsTableColumns";

export const CollectionYardsTable: React.FC = () => {
  const data = useSelector(getCollectionYardsVisits);
  const columns = useGetCollectionYardsTableColumns();
  const isLoading = useSelector(getIsCollectionYardsVisitsStatusLoading);
  const scrollTop = useSelector(getCollectionYardsScrollTop);
  const collectionYardsState = useSelector(getCollectionYards);

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setCollectionYardsListScrollTop}
      setPagination={setCollectionYardsListPagination}
      isLoading={isLoading}
      sliceState={collectionYardsState}
    />
  );
};
