import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";

import { GET_CURRENT_USER, POST_LOGIN_URL, UPDATE_LANGUAGE_URL } from "../middleware/routes";
import { ILanguageValueEnum } from "../types/localization";
import { IAuthState, Status } from "../types/state";
import { ICredentials, IUser } from "../types/user";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getJwtFromLocalStorage, removeJwtFromLocalStorage, setJwtToLocalStorage } from "../utils/localStorageHandlers";

const defaultUser: IUser = {
  isSeparating: false,
  userId: 0,
  administrationId: 0,
  administrationName: "",
  authToken: "",
  firstname: "",
  lastname: "",
  email: "",
  language: ILanguageValueEnum.sk,
};

export const initAuthState: IAuthState = {
  status: Status.idle,
  errorMsg: null,
  user: defaultUser,
  jwt: getJwtFromLocalStorage(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initAuthState,
  reducers: {
    resetAuthState: () => {
      removeJwtFromLocalStorage();
      return { ...initAuthState, jwt: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(loginUserThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.user = action.payload;
        state.jwt = action.payload.authToken;
        state.errorMsg = "";
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.status = Status.error;
        if (action.payload) {
          state.errorMsg = action.payload;
        }
      })
      .addCase(setUserWithJwtThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(setUserWithJwtThunk.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.status = Status.success;
        state.user = action.payload;
        state.jwt = action.payload.authToken;
        state.errorMsg = null;
      })
      .addCase(setUserWithJwtThunk.rejected, (state, action) => {
        state.status = Status.error;
        if (action.payload) {
          state.errorMsg = action.payload;
        }
      })

      .addCase(updateLanguageThunk.fulfilled, (state, action: PayloadAction<ILanguageValueEnum>) => {
        state.status = Status.success;
        state.user.language = action.payload;
        state.errorMsg = null;
      })
      .addCase(updateLanguageThunk.rejected, (state) => {
        state.status = Status.error;
      });
  },
});

export const loginUserThunk = createAsyncThunk<IUser, ICredentials, { rejectValue: string }>(
  "user/loginUserThunk",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosRequestApi.post(POST_LOGIN_URL, credentials);

      if (response.status === 400) {
        const errorMessage = i18next.t(response.data.errorResourceCodes[0]);
        return rejectWithValue(errorMessage);
      }
      if (response.status === 401) {
        const errorMessage = i18next.t(response.data.ErrorResourceCodes[0]);
        return rejectWithValue(errorMessage);
      }

      removeJwtFromLocalStorage();
      setJwtToLocalStorage(response.data.authToken);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const setUserWithJwtThunk = createAsyncThunk<IUser, void, { rejectValue: string }>(
  "user/setUserWithJwtThunk",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosRequestApi.get(GET_CURRENT_USER);
      removeJwtFromLocalStorage();
      setJwtToLocalStorage(response.data.authToken);
      return response.data;
    } catch (err: any) {
      dispatch(resetAuthState());
      return rejectWithValue(err.message);
    }
  }
);

export const updateLanguageThunk = createAsyncThunk(
  "user/updateLanguageThunk",
  async (language: ILanguageValueEnum) => {
    const data = {
      language,
    };

    await axiosRequestApi.put(UPDATE_LANGUAGE_URL, data);

    return language;
  }
);

export const { resetAuthState } = authSlice.actions;

export const authReducer = authSlice.reducer;
