export enum ColumnTypeIsSeparating {
  name = "name",
  address = "address",
  containerCount = "containerCount",
  totalTKO = "totalTKO",
  degreeOfSeparation = "degreeOfSeparation",
  totalDumpings = "totalDumpings",
  isPaid = "isPaid",
  isMobile = "isMobile",
  wcpid = "wcpid",
}
export enum ColumnTypeIsNotSeparating {
  name = "name",
  address = "address",
  containerCount = "containerCount",
  totalTKO = "totalTKO",
  wastePerHouseholdMember = "wastePerHouseholdMember",
  totalDumpings = "totalDumpings",
  isPaid = "isPaid",
  isMobile = "isMobile",
  wcpid = "wcpid",
}

export const columnLabelsIsSeparating = Object.values(ColumnTypeIsSeparating);
export const columnLabelsIsNotSeparating = Object.values(ColumnTypeIsNotSeparating);
