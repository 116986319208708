import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { SUPPORT_EMAIL } from "../../../middleware/routes";
import { getApplicationModeState } from "../../../selectors/application";
import { iconTypeHandler } from "../../../utils/iconTypeHandler";
import { LoginForm } from "../LoginForm";

import styles from "./LeftSegment.module.scss";

export const LeftSegment: React.FC = () => {
  const { t } = useTranslation();
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.top}>
          <Icon type={iconTypeHandler(applicationMode, IconTypeEnum.white)} name={IconNameEnum.logoText} size="140px" />
          <p className={styles.content}>{t("haveAnOverview")}</p>
        </div>
        <LoginForm />
      </div>
      <p className={styles.footer}>
        {t("areYouInterested")} <a href={`mailto:${SUPPORT_EMAIL}`}>{t("support")}</a>.
      </p>
    </div>
  );
};
