import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange, DateRangePicker as Picker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { IDatePickerPin } from "../../types/wagons";
import { CustomDay } from "./CustomDay";
import { CustomInput } from "./CustomInput";
import { Loader } from "./Loader";

import styles from "./DateRangePicker.module.scss";

interface Props {
  isLoading: boolean;
  onMonthChange: (date: Dayjs) => void;
  daysWithPins: IDatePickerPin[];
  defaultValue: DateRange<Dayjs>;
  onChange: (value: DateRange<Dayjs>) => void;
  disabled?: boolean;
}

export const DateRangePicker: React.FC<Props> = ({
  onMonthChange,
  isLoading,
  daysWithPins,
  defaultValue,
  onChange,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState<"start" | "end">("start");

  const minDate = value[1]?.subtract(30, "day");
  const maxDate = value[0]?.add(30, "day");

  const selectDirection = (day: Dayjs) => {
    const isFullRange = value[0] !== null && value[1] !== null;
    const firstPickedValue = value[0] === null ? value[1] : value[0];

    if (day.isAfter(firstPickedValue) || day.isSame(firstPickedValue)) {
      if (!isFullRange) setValue([firstPickedValue, null]);
      setDirection("end");
      return;
    }

    if (!isFullRange) setValue([null, firstPickedValue]);
    setDirection("start");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <Picker
        className={styles.formControl}
        slots={{ field: CustomInput, day: CustomDay }}
        calendars={1}
        rangePosition={direction}
        value={value}
        onMonthChange={onMonthChange}
        disableHighlightToday={true}
        showDaysOutsideCurrentMonth={true}
        loading={isLoading}
        renderLoading={() => <Loader days={42} />}
        onChange={(newValue) => {
          setValue(newValue);
          setDirection("end");
        }}
        onAccept={onChange}
        maxDate={maxDate ? (maxDate < dayjs() ? maxDate : dayjs()) : dayjs()}
        minDate={minDate}
        defaultValue={defaultValue}
        open={open}
        closeOnSelect={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        localeText={{ clearButtonLabel: t("cancelSelection"), okButtonLabel: t("confirmSelection") }}
        slotProps={{
          day: {
            daysWithPins,
            selectDirection,
          } as any,
          field: { setOpen, open, disabled } as any,
          actionBar: {
            actions: ["clear", "accept"],
          },
        }}
      />
    </LocalizationProvider>
  );
};
