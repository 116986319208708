import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider } from "@mui/material";

import { Container } from "../../components/Container";
import { CustomButton } from "../../components/CustomButton";
import { PageSettingsContainer } from "../../components/PageSettingsContainer";
import { FixedTextInputSize, TextInput } from "../../components/TextInput";
import { useHandleLeavePrompt } from "../../hooks/useHandleLeavePrompt";
import { COLLECTION_YARDS_SETTINGS_PAGE } from "../../middleware/routes";
import {
  addressMaxLength,
  collectionYardDetailSettingsUpdateField,
  identificationMaxLength,
  nameMaxLength,
  resetCollectionYardDetailSettingsState,
  taxIdentificationMaxLength,
  updateCollectionYardDetailThunk,
} from "../../reducers/collectionYardDetailSettingsReducer";
import { getCollectionYardsListThunk } from "../../reducers/collectionYardsSettingsReducer";
import {
  getCollectionYardDetailAddress,
  getCollectionYardDetailIdentification,
  getCollectionYardDetailInitName,
  getCollectionYardDetailIsTouched,
  getCollectionYardDetailName,
  getCollectionYardDetailTaxIdentification,
  getCollectionYardDetailWasteTypes,
} from "../../selectors/collectionYardDetailSettings";
import { useAppDispatch } from "../../store";
import { CollectionYardDetailSettingsFields } from "../../types/collectionYardDetailSettings";
import { LeaveConfirmationModal } from "../WasteCollectionEditPage/LeaveConfirmationModal/LeaveConfirmationModal";
import { CollectionYardDetailSettingsPageCredentialsContainer } from "./CollectionYardDetailSettingsPageCredentialContainer";
import { CollectionYardDetailSettingsWasteType } from "./CollectionYardDetailSettingsWasteType";

import styles from "./CollectionYardDetailSettingsPage.module.scss";

export const CollectionYardDetailSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const wasteTypes = useSelector(getCollectionYardDetailWasteTypes);
  const address = useSelector(getCollectionYardDetailAddress);
  const name = useSelector(getCollectionYardDetailName);
  const identification = useSelector(getCollectionYardDetailIdentification);
  const taxIdentification = useSelector(getCollectionYardDetailTaxIdentification);
  const initName = useSelector(getCollectionYardDetailInitName);
  const isTouched = useSelector(getCollectionYardDetailIsTouched);

  const saveButtonRef = useRef<React.RefObject<HTMLButtonElement>>();
  const { isModalVisible, toggleModalVisibility, handleContinue } = useHandleLeavePrompt(isTouched, [saveButtonRef]);

  const handleModalContinue = () => {
    dispatch(resetCollectionYardDetailSettingsState());
    handleContinue();
  };

  const handleSave = async () => {
    await dispatch(updateCollectionYardDetailThunk());
    dispatch(resetCollectionYardDetailSettingsState());
    dispatch(getCollectionYardsListThunk());
    history.push(COLLECTION_YARDS_SETTINGS_PAGE);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    filed: CollectionYardDetailSettingsFields
  ) => {
    dispatch(
      collectionYardDetailSettingsUpdateField({
        field: filed,
        value: e.target.value,
      })
    );
  };

  return (
    <PageSettingsContainer
      headerText={initName || t("collectionYard")}
      fullHeight={false}
      backOption
      backPath={COLLECTION_YARDS_SETTINGS_PAGE}
    >
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.wrapperItems}>
            <div className={styles.item}>
              <p>{t("name")}</p>
              <TextInput
                value={name}
                onChange={(e) => handleChange(e, CollectionYardDetailSettingsFields.Name)}
                fixedSize={FixedTextInputSize.fixedMd}
                maxLength={nameMaxLength}
              />
            </div>
            <Divider />
            <div className={styles.item}>
              <p>{t("address")}</p>
              <TextInput
                value={address}
                onChange={(e) => handleChange(e, CollectionYardDetailSettingsFields.Address)}
                fixedSize={FixedTextInputSize.fixedMd}
                maxLength={addressMaxLength}
              />
            </div>
            <Divider />
            <div className={styles.item}>
              <p>{t("ico")}</p>
              <TextInput
                value={identification}
                onChange={(e) => handleChange(e, CollectionYardDetailSettingsFields.Identification)}
                fixedSize={FixedTextInputSize.fixedMd}
                maxLength={identificationMaxLength}
              />
            </div>
            <Divider />
            <div className={styles.item}>
              <p>{t("dic")}</p>
              <TextInput
                value={taxIdentification}
                onChange={(e) => handleChange(e, CollectionYardDetailSettingsFields.TaxIdentification)}
                fixedSize={FixedTextInputSize.fixedMd}
                maxLength={taxIdentificationMaxLength}
              />
            </div>
          </div>
        </Container>
        <CollectionYardDetailSettingsPageCredentialsContainer />
        <Container>
          <div className={styles.wrapperItems}>
            {wasteTypes.map((wasteType, i) => (
              <Fragment key={wasteType.wasteTypeId}>
                <CollectionYardDetailSettingsWasteType {...wasteType} />
                {i !== wasteTypes.length - 1 && <Divider />}
              </Fragment>
            ))}
          </div>
        </Container>
        <div className={styles.buttonContainer}>
          <CustomButton onClick={handleSave} text={t("save")} className={styles.saveBtn} ref={saveButtonRef} />
        </div>
      </div>
      <LeaveConfirmationModal
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        handleContinue={handleModalContinue}
      />
    </PageSettingsContainer>
  );
};
