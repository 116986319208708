import { useSelector } from "react-redux";

import { getApplicationModeState } from "../../../selectors/application";
import { getAdministrationName } from "../../../selectors/auth";
import { iconTypeHandler } from "../../../utils/iconTypeHandler";
import { Icon, IconNameEnum, IconTypeEnum } from "../../Icon";

import styles from "./TopSegment.module.scss";

export const TopSegment: React.FC = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const administrationName = useSelector(getAdministrationName);

  return (
    <div className={styles.topSegment}>
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <Icon
            type={iconTypeHandler(applicationMode, IconTypeEnum.white)}
            name={IconNameEnum.logo}
            size="44px"
            height="44px"
          />
          <div className={styles.textContent}>
            <h2>{administrationName}</h2>
            <div className={styles.textWrapper}>
              <span>by WAMA</span>
              <p className={styles.beta}>beta</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
