import { RootState } from "../store";
import { Status } from "../types/state";

export const getWagonsDatePickerPins = (state: RootState) => state.wagonsDatePicker.datePickerPins;
export const getWagonsDatePickerIsLoading = (state: RootState): boolean =>
  state.wagonsDatePicker.status !== Status.success;
export const getWagonsSelectedDate = (state: RootState) => state.wagonsDatePicker.selectedDate;
export const getWagonsSelectedWasteType = (state: RootState) => state.wagonsDatePicker.selectedWasteType;
export const getWagonsCanGenerate = (state: RootState) =>
  state.wagonsDatePicker.selectedDate.to !== null && state.wagonsDatePicker.selectedDate.from !== null;
