import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setApplicationBreakpoint } from "../reducers/applicationReducer";
import { getApplicationBreakpointState } from "../selectors/application";
import { ApplicationBreakpoint } from "../types/application";

export const useLoadApplicationBreakpoint = (): void => {
  const currentApplicationBreakpoint = useSelector(getApplicationBreakpointState);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      let resultAppBreakPoint = ApplicationBreakpoint.mobile;

      if (window.innerWidth > 480) {
        resultAppBreakPoint = ApplicationBreakpoint.tablet;
      }
      if (window.innerWidth > 1280) {
        resultAppBreakPoint = ApplicationBreakpoint.desktop;
      }
      if (window.innerWidth > 1700) {
        resultAppBreakPoint = ApplicationBreakpoint.largeDesktop;
      }
      if (window.innerWidth > 2000) {
        resultAppBreakPoint = ApplicationBreakpoint.extraLargeDesktop;
      }

      if (currentApplicationBreakpoint !== resultAppBreakPoint) {
        dispatch(setApplicationBreakpoint(resultAppBreakPoint));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch, currentApplicationBreakpoint]);
};
