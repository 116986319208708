import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";

import { LoginPage } from "../../containers/LoginPage";
import { useHandleApplicationSettings } from "../../hooks/useHandleApplicationSettings";
import { useLoadApplicationBreakpoint } from "../../hooks/useLoadApplicationBreakpoint";
import { useLoadUser } from "../../hooks/useLoadUser";
import { LOGIN_PAGE_PATH } from "../../middleware/routes";
import { getApplicationModeState, getLoaderVisibility } from "../../selectors/application";
import { getJwtFromStore } from "../../selectors/auth";
import { themeHandler } from "../../utils/themeHandler";
import { AnimatedWasteLoader } from "../AnimatedWasteLoader";
import { Layout } from "../Layout";
import { Notification } from "../Notification";
import { AuthenticatedApp } from "./AuthenticatedApp";

export const App: React.FC = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const isAppLoading = useSelector(getLoaderVisibility);
  const jwt = useSelector(getJwtFromStore);

  useLoadApplicationBreakpoint();
  useHandleApplicationSettings();
  useLoadUser();

  return (
    <ThemeProvider theme={themeHandler(applicationMode)}>
      <Notification />
      {isAppLoading && jwt ? (
        <AnimatedWasteLoader fullPage />
      ) : (
        <Layout>
          {!jwt ? (
            <Switch>
              <Route exact path={LOGIN_PAGE_PATH} component={LoginPage} />
              <Redirect to={LOGIN_PAGE_PATH} />
            </Switch>
          ) : (
            <AuthenticatedApp />
          )}
        </Layout>
      )}
    </ThemeProvider>
  );
};
